 

const img = {
   gold: { src: './assets/images/icons/gold.png', alt: 'gold', description: 'gold' },
   silver: { src: './assets/images/icons/silver.png', alt: 'silver', description: 'silver' },
   bronze: { src: './assets/images/icons/bronze.png', alt: 'bronze', description: 'bronze' },
   help: { src: './assets/images/icons/help.png', alt: 'help', description: 'help' },
   home_banner: { src: './assets/images/home-1.png', alt: 'home first banner image', description: 'home first banner image' },
   phone: { src: './assets/images/icons/phone.png', alt: 'phone', description: 'phone' },
   phone_2: { src: './assets/images/icons/call-with-c.png', alt: 'phone', description: 'phone' },
   calendar: { src: './assets/images/icons/calendar.svg', alt: 'calendar', description: 'calendar' },
   whatsapp: { src: './assets/images/icons/whatsapp.png', alt: 'whatsapp', description: 'whatsapp' },
   whatsapp_fill_green_c: { src: './assets/images/icons/whatsapp-fill-green_c.png', alt: 'whatsapp', description: 'whatsapp-fill-green_c.png' },
   whatsapp_green: { src: './assets/images/icons/whatsapp-fill-green.png', alt: 'whatsapp-fill-green', description: 'whatsapp-fill-green' },
   file: { src: './assets/images/icons/file.png', alt: 'file', description: 'file' },
   facebook: { src: './assets/images/icons/facebook.png', alt: 'facebook', description: 'facebook' },
   family_monochromatic_2: { src: './assets/images/icons/Family_Monochromatic-2.png', alt: 'facebook', description: 'facebook' },
   rupee: { src: './assets/images/icons/rupee.png', alt: 'rupee', description: 'rupee' },
   dollar: { src: './assets/images/icons/dollar.png', alt: 'dollar', description: 'dollar' },
   stethoscope: { src: './assets/images/icons/stethoscope.png', alt: 'stethoscope', description: 'stethoscope' },
   person: { src: './assets/images/icons/person.png', alt: 'person', description: 'person' },
   person2: { src: './assets/images/icons/person-2.png', alt: 'person', description: 'person' },
   edit: { src: './assets/images/icons/edit.png', alt: 'edit', description: 'edit' },
   edit_2: { src: './assets/images/icons/edit-note.svg', alt: 'edit', description: 'edit' },
   notebook: { src: './assets/images/icons/notebook.png', alt: 'notebook', description: 'notebook' },
   show: { src: './assets/images/icons/show.png', alt: 'show', description: 'show' },
   eye: { src: './assets/images/icons/show.png', alt: 'eye', description: 'eye icon' },
   
   compare: { src: './assets/images/icons/compare.png', alt: 'compare', description: 'compare' },
   users: { src: './assets/images/icons/users.png', alt: 'users', description: 'users' },
   checked_fill: { src: './assets/images/icons/checked-fill.png', alt: 'check', description: 'checked-fill' },
   check: { src: './assets/images/icons/check.png', alt: 'check', description: 'check' },
   cancel: { src: './assets/images/icons/cancel.png', alt: 'cancel', description: 'cancel' }, 
   question_green: { src: './assets/images/icons/question-green.png', alt: 'question-green', description: 'question-green' }, 
   info_green: { src: './assets/images/icons/Info-green.png', alt: 'Info-green', description: 'Info-green' }, 
   cil_badge: { src: './assets/images/icons/cil_badge.png', alt: 'cil_badge', description: 'cil_badge' },
   download: { src: './assets/images/icons/download.png', alt: 'download', description: 'download' }, 

   search: {
      src: './assets/images/icons/search.png',
      alt: 'search',
      description: 'search image'
   },

   web: {
      src: './assets/images/icons/web.png',
      alt: 'web',
      description: 'web image'
   },
   web_sm: {
      src: './assets/images/icons/web-sm.png',
      alt: 'web',
      description: 'web-sm image'
   },

   light: {
      src: './assets/images/icons/light.png',
      alt: 'Light',
      description: 'Light image'
   },

   man_step: {
      src: './assets/images/icons/man-step.png',
      alt: 'Man',
      description: 'Man Step'
   },

   card: {
      src: './assets/images/icons/card.png',
      alt: 'Card',
      description: 'Card image'
   },
   steps: {
      src: './assets/images/icons/steps.png',
      alt: 'steps',
      description: 'steps image'
   },

   medical_box: {
      src: './assets/images/icons/medical_box.png',
      alt: 'medical_box',
      description: 'medical_box image'
   },

   warning_gray: {
      src: './assets/images/icons/warning-gray.png',
      alt: '',
      description: 'warning gray icon'
   },

   warning_yellow: {
      src: './assets/images/icons/warning-yellow.png',
      alt: '',
      description: 'warning yellow icon'
   },

   warning_red: {
      src: './assets/images/icons/warning-red.png',
      alt: '',
      description: 'warning red icon'
   },
   wavy_warning: {
      src: './assets/images/icons/wavy_warning.png',
      alt: '',
      description: 'wavy_warning icon'
   },

   correct_gray: {
      src: './assets/images/icons/correct_gray.png',
      alt: '',
      description: 'correct_gray icon'
   },

   correct_green: {
      src: './assets/images/icons/correct-green.png',
      alt: '',
      description: 'correct_green icon'
   },

   correct_red: {
      src: './assets/images/icons/correct_red.png',
      alt: '',
      description: 'correct_red icon'
   },

   correct_yellow: {
      src: './assets/images/icons/correct_yellow.png',
      alt: '',
      description: 'correct_yellow icon'
   },


   easy_claim_process: {
      src: './assets/images/icons/EasyClaimProcess.png',
      alt: 'Easy Claim Process',
      description: 'EasyClaimProcess icon'
   },

   mdi_comma_with_c: {
      src: './assets/images/icons/mdi_comma_with_c.png',
      alt: '',
      description: 'mdi_comma_with_c icon'
   },

   mdi_comma: {
      src: './assets/images/icons/mdi_comma.png',
      alt: '',
      description: 'mdi_comma icon'
   },

   mdi_comma_right: {
      src: './assets/images/icons/mdi_comma_right.png',
      alt: '',
      description: 'mdi_comma_right icon'
   },


   mahima_gupta: {
      src: './assets/images/persons/mahima-gupta.png',
      alt: 'Mahima Gupta',
      description: 'mahima_gupta image'
   },

   naveen_bajaj: {
      src: './assets/images/persons/naveen-bajaj.png',
      alt: 'Naveen Bajaj',
      description: 'naveen-bajaj image'
   },

   /* schedule-a-call-icon */
   video_call_monochromatic: {
      src: './assets/images/schedule-a-call-icon/video-call-monochromatic.png',
      alt: 'video_call_monochromatic',
      description: 'video_call_monochromatic'
   },


   right_arrow_fill: {
      src: './assets/images/icons/right-arrow-fill.png',
      alt: '',
      description: 'right_arrow_fill'
   },

   arrow_left: {
      src: './assets/images/icons/arrow-left.png',
      alt: '',
      description: 'arrow_left' 
   },
   arrow_down: {
      src: './assets/images/icons/arrow-down.png',
      alt: '',
      description: 'arrow_down' 
   },
   arrow_right: {
      src: './assets/images/icons/arrow-right.png',
      alt: '',
      description: 'arrow_right' 
   },

   /**Login images */
   support_agent_black: {
      src: './assets/images/login-icon/support_agent_black.png',
      alt: '',
      description: 'support_agent_black'
   },
   family_insured: {
      src: './assets/images/login-icon/family-insured.png',
      alt: 'family insured image',
      description: 'family_insured'
   },
   success_fill: {
      src: './assets/images/login-icon/success-fill.png',
      alt: 'success',
      description: 'success icon'
   },

   /**client-requirement */
   family_health_insurance: {
      src: './assets/images/client-requirement-icon/family-health-insurance.png',
      alt: 'family-health-insurance',
      description: 'family-health-insurance image'
   },


   /**Person images */

   father: { src: './assets/images/client-requirement-icon/Father.png', alt: '', description: 'Father icon' },
   me: { src: './assets/images/client-requirement-icon/Father.png', alt: '', description: 'Man icon' },
   man: { src: './assets/images/client-requirement-icon/Father.png', alt: '', description: 'Man icon' },
   mother: { src: './assets/images/client-requirement-icon/Mother.png', alt: '', description: 'Mother icon' },
   wife: { src: './assets/images/client-requirement-icon/Mother.png', alt: '', description: 'Woman icon' },
   woman: { src: './assets/images/client-requirement-icon/Mother.png', alt: '', description: 'Woman icon' },
   daughter: { src: './assets/images/client-requirement-icon/Daughter.png', alt: '', description: 'Daughter icon' },
   son: { src: './assets/images/client-requirement-icon/Son.png', alt: '', description: 'Son icon' },




   video_call: { src: './assets/images/video-call.png', alt: 'Video call image', description: 'Video call image' },
   customer_support_1: { src: './assets/images/customer-support-1.png', alt: 'customer-support-1', description: 'customer-support-1' },
   guidelines: { src: './assets/images/guidelines.png', alt: 'guidelines image', description: 'guidelines image' },
   plant: { src: './assets/images/icons/plant.png', alt: 'plant', description: 'plant' }, 
   loading: { src: './assets/images/icons/loading.gif', alt: 'loading...', description: 'loading' },
   

};
export default img;