import FormHeaderSection from "./FormHeaderSection.jsx";
import { Row, Col, Card, Form } from "react-bootstrap";
import img from "../../components/Img.jsx";

import { useForm } from "react-hook-form";
import http from "../../http.jsx";

import SweetAlert from 'sweetalert2'
import { useEffect, useState } from "react";

function Documents(props) {

    const [documents, SetDocuments] = useState({
        pan: "",
        passport: "",
        medical_examination_papers: "",
        signature: ""
    });

    useEffect(() => {
        http.post('/enrollment-form/get-documents', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                SetDocuments(resp.data); 
            } else {

            }
        });

    }, []);



    const { register, handleSubmit, formState: { errors } } = useForm();
    console.log(register);
    console.log(errors);
    // async  const onSubmitForm = data => {
    async function onSubmitForm(data) {
        // console.log(data);
        console.log("line 18");

        // await AccessFile(); 
        // console.log("line 21");
        const serialize = require('form-serialize');
        const form = document.querySelector('#form_documents');
        const form_data = serialize(form, { hash: true });

        console.log("fnominee form data >> ", form_data);


        http.post('/enrollment-form/upload-documents', form_data,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            //    console.log(resp);
            console.log("line 34");
            if (resp.success) {
                SetDocuments(resp.data);
                SweetAlert.fire(
                    'Success',
                    'Nominee data saved successfully.',
                    'success'
                );
                props.setStep('SignAndConfirm');
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });


    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    /*   async function AccessFile(id) {
           var fff  = document.querySelector('#file_passport').files[0].size;
           console.log("fff=================>" , fff);
           const file_passport = document.querySelector('#file_passport').files[0];
           const file_pan = document.querySelector('#file_pan').files[0];
           const file_medical_examination = document.querySelector('#file_medical_examination').files[0];
   
           //console.log(await toBase64(file));
   
           let file_passport_64 = await toBase64(file_passport);
           let file_pan_64 = await toBase64(file_pan);
           let file_medical_examination_64 = await toBase64(file_medical_examination);
   
           document.getElementById('pan').value = file_pan_64;
           document.getElementById('passport').value = file_passport_64;
           document.getElementById('medical_examination_papers').value = file_medical_examination_64;
           
       } */

    async function make_base64(get_id, set_id) {
        var size = document.querySelector('#file_passport').files[0].size;
        var size_kb = size / 1000;
        var size_mb = size_kb / 1000;
        if (size_mb > 5) {
            SweetAlert.fire(
                'Error!',
                'Must be file size less than 5 MB, You are trying to upload file which size is ' + size_mb + ' MB',
                'error'
            );
        }
        //   alert("MB" + size_mb);

        const file = document.querySelector('#' + get_id).files[0];
        let base_64_file_data = await toBase64(file);
        document.getElementById(set_id).value = base_64_file_data;

    }




    return (
        <>
            <Form id="form_documents" onSubmit={handleSubmit(onSubmitForm)} >
                <input type="hidden" id="pan" name="pan" />
                <input type="hidden" id="passport" name="passport" />
                <input type="hidden" id="medical_examination_papers" name="medical_examination_papers" />
                <FormHeaderSection title="Documents" sub_title="Upload Relevant Documents" setStep={props.setStep} step={props.step}  />
                <div className="documents form-section">
                    <Row>
                        <Col md={8}>

                            <div className="form-group row">
                                <div className="col-md-7">

                                    <label>Passport (Front and back both)</label>
                                    <input type="file" id="file_passport" name="file_passport" className="form-control"
                                        accept="image/*,application/pdf" size="20" onChange={() => { make_base64('file_passport', 'passport') }}
                                    />
                                    <small>Less than 5 MB</small>
                                    {(
                                        documents.passport &&
                                        <small className="ml-20"><a target="_blank" href={documents.passport}>View</a></small>
                                    )}
                                </div>
                                <div className="col-md-2 mt-2">
                                    <Card className="p-3">
                                        <i class="fa fa-camera fa-2x" aria-hidden="true"></i>
                                    </Card>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-7">

                                    <label>PAN Card</label>
                                    <input type="file" id="file_pan" name="file_pan" className="form-control"
                                        accept="image/*,application/pdf"
                                        onChange={() => { make_base64('file_pan', 'pan') }}
                                    />
                                    <small>Less than 5 MB</small>
                                    {(
                                        documents.pan &&
                                        <small className="ml-20"><a target="_blank"  href={documents.pan}>View</a></small>
                                    )}
                                </div>
                                <div className="col-md-2 mt-2">
                                    <Card className="p-3">
                                        <i class="fa fa-camera fa-2x" aria-hidden="true"></i>
                                    </Card>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-7">

                                    <label>Medical Examination Papers</label>
                                    <input type="file" id="file_medical_examination" name="file_medical_examination" className="form-control"
                                        accept="image/*,application/pdf"
                                        onChange={() => { make_base64('file_medical_examination', 'medical_examination_papers') }}
                                    />
                                    <small>Less than 5 MB</small>
                                    {(
                                        documents.medical_examination_papers &&
                                        <small className="ml-20"><a  target="_blank"  href={documents.medical_examination_papers}>View</a></small>
                                    )}
                                </div>
                                <div className="col-md-2 mt-2">
                                    <Card className="p-3">
                                        <i class="fa fa-camera fa-2x" aria-hidden="true"></i>
                                    </Card>
                                </div>
                            </div>

                        </Col>
                        <Col md={4}>
                            <Card className="guidelines-section">
                                <h4><img src={img.notebook.src} alt=""></img> Guidelines</h4>
                                <div className="col-md-12 mb-20 mt-20">
                                    <img src={img.guidelines.src} alt="" className="w-100"></img>
                                </div>
                                <small>Passport number is a 16-digit number on your aadhaar card</small>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}


export default Documents;