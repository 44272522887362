//https://stackoverflow.com/questions/63453491/how-to-export-axios-create-in-typescript
import axios from 'axios';

//import SweetAlert from 'sweetalert2'

let token = (localStorage.getItem("token") ? localStorage.getItem("token") : '');

const c = axios.create({

//export default axios.create({
//baseURL: "http://127.0.0.1:8000/api",
baseURL: "https://manipalcigna.finnovate.in/admin/api",
  headers: {
    "Content-type": "application/json",
    "Authorization": "Bearer " + token,
    "Accept": "application/json"
  },


  /*
 transformResponse: [
   (data) => {
    const d = JSON.parse(data);
     if(d.message === 'Unauthenticated.'){
    
       SweetAlert.fire({
         title: "Error!",
         text: "Token expired or invalid!",
         type: "error"
     }).then(function() {
       localStorage.clear();
       window.location = "/login";
     });
      
     } 
   },
 ] */
});



export default c;

 