import { useState, useContext, useEffect } from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from './Img';
import noteContext from '../context/notes/NoteContext';

import http from '../http';

function PlanDetails(props) {

    const context = useContext(noteContext);
    const [lgShow, setLgShow] = useState(false);

    const getQuotePopup = (package_id) => {
       // console.log("get Quote successfully for package id: " + package_id);
        setLgShow(true);
    }

    const class_list = (props.className ? props.className : 'btn btn-primary');

    const saveGetQuote = (package_id) => {

        if (!localStorage.getItem("token")) {
            window.location.href = '/login';
        }

        const payload = {
            pakage_id: package_id
        }
        http.post('/save-get-quote', payload,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                const get_quote_id = resp.data.get_quote_id;
                localStorage.setItem("get_quote_pakage_id", get_quote_id);
                window.location = "/";
            } else {
                console.log(resp.message);
            }

        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp);
        });

    }

    const [get_quote_button, setGetQuoteButton] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('my_get_quote_list').includes(props.package_id)) {
            //  setGetQuoteButton(true);
        } else {
            setGetQuoteButton(false);
        }

    }, []);


    return (
        <>
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className="">
                            <img className='full-width' src={img.customer_support_1.src} alt={img.customer_support_1.alt}></img>
                        </Col>
                        <h4>Quote Requested Successfully !!</h4>
                        <div className='center mt-20 mb-20'>Our team will reach out to you soon. Till then you can explore other services offered by Finnovate. </div>
                        <Col>
                            <Link onClick={() => { saveGetQuote(props.package_id) }} className='btn btn-lg btn-primary full-width'>Go to Finnovate</Link>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <button onClick={() => getQuotePopup(props.package_id)} className={class_list}>
                {(props.children ? props.children : "Get Quote")}  
            </button>

        </>
    )
}

export default PlanDetails;