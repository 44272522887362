import { Link } from 'react-router-dom';
import './DiseasesCovered.css'
import img from '../../Img';

function DiseasesCovered() {  

    return (
        <>
        <div className="row diseases-covered">

            <div className="col-md-6">
                    <img src="./assets/images/icons/diabetes-icon.png" alt="" className='icon' />
                    <h3 className='title'>Heart Attack</h3>
                    <p className='details'>Rest assured, our Global Health Insurance provides coverage for heart attack treatment, ensuring you receive the best medical care without financial worries.;</p>
                </div>

                <div className="col-md-6">
                    <img src="./assets/images/icons/diabetes-icon.png" alt="" className='icon' />
                    <h3 className='title'>Cancer</h3>
                    <p className='details'>With Global Health Insurance, you can access top-notch medical facilities and innovative treatments, ensuring the best possible care and support during your battle against cancer.</p>
                </div>

                <div className="col-md-6">
                    <img src="./assets/images/icons/diabetes-icon.png" alt="" className='icon' />
                    <h3 className='title'>Kidney Failure</h3>
                    <p className='details'>Costs associated with Kidney Failure treatment, including dialysis, transplants, and medications, are covered for your well-being.</p>
                </div>

                <div className="col-md-6">
                    <img src="./assets/images/icons/diabetes-icon.png" alt="" className='icon' />
                    <h3 className='title'>HIV AIDS</h3>
                    <p className='details'>Our Global Health Insurance plan includes coverage for HIV AIDS, promoting a healthier future for all our insured members regardless of their health status.</p>
                </div>

                <div className="col-md-6">
                    <img src="./assets/images/icons/diabetes-icon.png" alt="" className='icon' />
                    <h3 className='title'>Cataract</h3>
                    <p className='details'>Say goodbye to blurry vision worries with our Global Health Insurance, offering comprehensive coverage for cataract treatment worldwide.</p>
                </div>

                <div className="col-md-6">
                    <img src="./assets/images/icons/diabetes-icon.png" alt="" className='icon' />
                    <h3 className='title'>Varicose veins and Ulcers</h3>
                    <p className='details'>With Global Health Insurance, you can rest assured that treatment for varicose veins and ulcers is included, providing you with the necessary support for your healthcare requirements.</p>
                </div>

        </div>
        
        <div className='col-md-12 text-center'>
            <Link to={"/more"} className="w-200 btn btn-primary btn-md "> <img src={img.arrow_down.src} alt=""></img> More View</Link>
        </div>
    </>    
    );
}

export default DiseasesCovered;