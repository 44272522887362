/**
 * npm i react-drag-drop-files
 *  https://www.npmjs.com/package/react-drag-drop-files 
 *  */

import FormHeaderSection from "./FormHeaderSection.jsx";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useState, useContext, useEffect, useRef } from "react";
//import img from "../../components/Img.jsx";
import OTPInput from "otp-input-react";
import { FileUploader } from "react-drag-drop-files";
import http from '../../http';
import SweetAlert from 'sweetalert2';
import noteContext from "../../context/notes/NoteContext.jsx";
import SignatureCanvas from 'react-signature-canvas' // https://www.npmjs.com/package/react-signature-canvas



const fileTypes = ["JPG", "PNG", "GIF"];



function SignAndConfirm(props) {
    const context = useContext(noteContext);

    const [signatureType, setSignatureType] = useState('drop_signature');
    const [signature_image_src, setSignatureImageSrc] = useState("");     
    const [document, setDocuments] = useState({});
    const getDocumentsFun = () => {
        http.post('/enrollment-form/get-documents', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) { 
                setDocuments(resp.data);
                if(signature_image_src === ""){
                    setSignatureImageSrc(resp.data.signature);                 
                }                
            } else {

            }
        });

    }

    useEffect(() => {
        getDocumentsFun(); 
    }, []);


    const [lgShow, setLgShow] = useState(false);


    const [OTP, setOTP] = useState("");
    const [form_send_otp, set_form_send_otp] = useState('show');
    const [form_otp_verification, set_form_otp_verification] = useState('hide');
    const [form_declension, set_form_declension] = useState('hide');




    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setError: { setError2 },
        formState: { errors: errors2 }
    } = useForm();


    const {
        register: register3,
        handleSubmit: handleSubmit3,
        setError: { setError: setError3 },
        formState: { errors: errors3 }
    } = useForm();

    const {
        register: register4,
        handleSubmit: handleSubmit4,
        formState: { errors: errors4 }
    } = useForm();


    const onSubmitFormSendOtp = data => {
        //console.log(">>>31 ", data);
        const payload = {
            mobile: data.mobile
        }
        http.post('/send-mobile-otp', payload).then((response) => {
            const res = response.data;
            if (res.success) {
                set_form_send_otp('hide');
                set_form_otp_verification('show');
                set_form_declension('hide');
            } else {
                console.log(res.message);
            }
        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp);
        });
    }

    const onSubmitFormOtpVerification = data => {
        const payload = {
            mobile_otp: OTP
        }
        http.post('/mobile-otp-verification', payload).then((response) => {
            const res = response.data;
            if (res.success) {
                set_form_send_otp('hide');
                set_form_otp_verification('hide');
                set_form_declension('show');
            } else {
                // errors2.mobile_otp.message = "sssss";
                //  setError2('mobile_otp', { message: res.message });
                SweetAlert.fire(
                    'Error!',
                    res.message,
                    'error'
                );
                console.log(res.message);
            }
        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp);
        });
    }

    const onSubmitFormDeclaration = data => {
        setLgShow(true);
    }

    const [signature_name, setSignatureName] = useState("");
    const handleChangeSignatureName = (event) => {
        setSignatureName(event.target.value);
        console.log(signature_name);
    };

     const  onSubmitFormSign = () => {
        let payload = { }

        if (signatureType === 'drop_signature') { // in case drow singnature via mouse pointer  
            payload = {
                signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") //signature_src
            }
        }
        if (signatureType === 'upload_signature') { // in case upload image file 
            payload = { signature: file }
        }
        if (signatureType === 'signature_name') {
            payload = {  signature_name: signature_name    }
        }


        http.post('/enrollment-form/upload-signature', payload,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                getDocumentsFun(resp.data);
                setSignatureImageSrc(payload.signature); 
                SweetAlert.fire(
                    'Success',
                    'Signature image uploaded successfully.',
                    'success'
                ); 
                setLgShow(false);
              
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp);
        });

    }


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const [file, setFile] = useState('');
    const handleChange = async (file) => {
        const base64 = await convertBase64(file);
        setFile(base64);
        console.log(base64)
    }




    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    const [signature_src, setSignatureSrc] = useState();
    const save = () => {
        const alp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        // console.log(alp)
        setSignatureSrc(alp);
        // const base = alp.replace(/^data:image.+;base64,/, "");
    };



    return (

        <>
            <FormHeaderSection title="Sign And Confirm" sub_title="Preview the details you entered" step={props.step} setStep={props.setStep} />


            {document.signature || document.signature_name ?
                <Row>
                    <Col md="6" className="center">
                        <br />
                        <div ><label>Signature Name: {document.signature_name} </label></div> <br />
                        <img src={signature_image_src} className="full-width border"></img>  
                        <br />
                        <br />
                        <Link to="/insurance-application-status" className="btn btn-success mr-20">Finish</Link>
                        <Link onClick={() =>  setLgShow(true) } className="btn btn-primary">Edit</Link>
                    </Col>
                </Row> :
                <>
                    <Form id="form_send_otp" className={form_send_otp} onSubmit={handleSubmit(onSubmitFormSendOtp)} >
                        <div id="col-12 mobile_input_section">
                            <div className="form-group col-md-6">
                                <label>Enter your Phone Number for verification</label>
                                <input type="text" id="mobile" name="mobile" className="form-control disabled" value={localStorage.getItem('mobile')}
                                    {...register("mobile", { required: 'Mobile No is required' })}
                                ></input>
                                <span class={errors.mobile ? 'required' : ''}>
                                    {errors.mobile && errors.mobile.message}
                                </span>
                            </div>

                            <div className="mt-10 form-group col-md-6">
                                <input type="submit" className="btn btn-primary" value="Get OTP" />
                            </div>
                        </div>
                    </Form>

                    <Form id="form_otp_verification" className={form_otp_verification} onSubmit={handleSubmit2(onSubmitFormOtpVerification)} >
                        <div className="form-group col-md-6" id="mobile_otp_input_section">
                            <Row className="mb-8 mb-20 otp-section">
                                <Col md="12" className="mb-1">
                                    <Form.Label className="">Enter OTP (+91 7000-370-779)</Form.Label>
                                    <Link className="ml-20" to={"/#"}>Edit</Link>
                                </Col>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">

                                    <OTPInput
                                        id="mobile_otp"
                                        name="mobile_otp"
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={5}
                                        otpType="number"
                                        disabled={false}
                                        secure
                                    //ref={register2({ required: true })}
                                    />
                                </Form.Group>
                                <span class={errors2.mobile_otp ? 'required' : ''}>
                                    {errors2.mobile_otp && errors2.mobile_otp.message}
                                </span>
                            </Row>
                            <div className="mt-10 form-group col-md-6">
                                <input type="submit" className="btn btn-primary" value="Verify" />
                            </div>
                        </div>
                    </Form>
                    <Form id="form_declension" className={form_declension} onSubmit={handleSubmit3(onSubmitFormDeclaration)} >
                        <div className="form-group col-md-8" >
                            <label><input type="checkbox" value="1" name="declare"
                                {...register3("declare", { required: false })}
                            /> I hereby declare that all the information that I have shared with Finnovate Pvt. Ltd. is true and authentic. I have filled it with my utmost honesty and I have shared all the relevant information without hiding any kind of facts, that could hamper or change the clause of my policy.</label>
                            <span class={errors3.declare ? 'required' : ''}>
                                {errors3.declare && errors3.declare.message}
                            </span>
                        </div>
                        <div className="mt-10 form-group col-md-6">
                            <input type="submit" className="btn btn-primary" value="Sign & Continue" />
                        </div>
                    </Form>



                </>

            }

            <Form className={form_declension} onSubmit={handleSubmit4(onSubmitFormSign)} >
                <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header>
                        <div className="col-md-12 row">
                            <div className="col-md-8">
                                <h5>Sign and Continue</h5>
                                <small>Draw your sign</small>
                            </div>
                            <div className="col-md-4 right ">
                                <Link className="btn btn-link border mr-20  mt-10" onClick={() => setLgShow(false)}>Cancel</Link>
                                <input type="submit" className="btn btn-primary mt-10" onClick={onSubmitFormSign} value="Save" />
                            </div>
                        </div>

                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <label className="pointer"><input defaultChecked={true} type="radio" name="signature_upload_type" onChange={() => setSignatureType('drop_signature')} /> Drow Your Signature</label> <br />
                        {
                            (signatureType === 'drop_signature' &&
                                <div className="row">
                                    <div className="col-md-7">
                                        <div style={{ border: "dashed", color: "#1159a1" }}>
                                            <h1>Drow Your Signature</h1>
                                            <SignatureCanvas penColor='black' ref={sigCanvas}
                                                canvasProps={{ width: 420, height: 200, className: 'sigCanvas' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-5 center">
                                        <button onClick={clear} className="btn btn-sm btn-danger mr-20">Clear</button>
                                        <button onClick={save} className="btn btn-success btn-sm">Previous</button>
                                        {signature_src &&
                                            <p className="border p-10 mt-10">
                                                <img className="full-width" src={signature_src} alt=""></img>
                                                </p>
                                        }
                                    </div>

                                </div>
                            )
                        }

                        <label className="pointer"><input type="radio" name="signature_upload_type" onChange={() => setSignatureType('upload_signature')} /> OR  Upload Your Signature</label><br />
                        {
                            (signatureType === 'upload_signature' &&
                                <div className="form-group col-md-12 sign-and-confirm">
                                    <input type="hidden" defaultValue={file} />
                                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                                </div>
                            )
                        }

                        <label className="pointer"><input type="radio" name="signature_upload_type" onChange={() => setSignatureType('signature_name')} /> OR Write your name here</label>
                        {
                            (signatureType === 'signature_name' &&
                                <div className="form-group col-md-4 mt-30">
                                    <input id="signature_name" type="text" name="signature_name" className="form-control"
                                        onChange={handleChangeSignatureName}
                                    ></input>
                                </div>
                            )
                        }
                    </Modal.Body>
                </Modal>
            </Form>

        </>
    )
}



export default SignAndConfirm;