import { Card, Col, Row } from "react-bootstrap";
import img from "../components/Img";
import './Register.css'
import { get } from './../funcations'

function LoginExistingToken(props) {
    document.title = "Login Success";
   
    if(!localStorage.getItem('token')){
        window.location.reload();
    }
    if (get('token')) { 
        localStorage.setItem("token", get('token'));
        localStorage.setItem("name", get('name'));
        localStorage.setItem("email", get('email'));
        localStorage.setItem("mobile", get('mobile'));
       //@window.location.reload();
    }
    
    return (
        <>
            <Row className="register-section">
                <Col className="col-md-6 register-section-left">
                    <h1>We are here with just the right insurance for you.</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pulvinar pellentesque sed purus adipiscing aenean potenti </p>
                    <Row className="text-center family-image">
                        <img src={img.family_insured.src} alt={img.family_insured.alt}></img>
                    </Row>
                </Col>
                <Col className="col-md-6 register-section-right">
                    <Col className="col-md-11">
                        <Card>
                            <Col className="text-center">
                                <img className="fa_7x" src={img.success_fill.src} alt={img.success_fill.alt}></img>
                            </Col>
                            <Col className="mt-20 text-center mb-5">
                                <h2>Login Successfully</h2>
                                <span>Desicrption according to the user journey </span>
                            </Col>
                            <a href="/client-requirements" className="full-width btn btn-primary btn-lg" variant="primary" size="lg">Continue</a>
                        </Card>
                    </Col>
                </Col>
            </Row>
        </>
    );
}


export default LoginExistingToken;