//https://react-bootstrap.github.io/getting-started/introduction/
import './HealthInsurance.css'

import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import DiseasesCovered from './health-insurance/DiseasesCovered';


function HealthInsurance(props) {

    const [key, setKey] = useState('home');

    let img = props.images;
    return (
        <div className="section health-insurance mt-50">
            <div className="title">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src={img.file.src} alt={img.file.alt}></img>
                        <h1>Everything covered by Health Insurance</h1>
                        <p className='text-center'>It’s important to know that health insurance pays for most of your hospitalization expenses. However, it’s equally important to understand which diseases are covered under health insurance. Here’s a list of diseases covered by a general health insurance plan.</p>
                    </div>
                    <div className='col-md-4'></div>
                    <div className="col-md-4 text-center">
                        <form className='search-form'>
                            <div className="input-group">
                                <div className="input-group-btn">
                                    <img src={img.search.src} alt="" className='search-icon'></img>
                                </div>
                                <input type="text" className="form-control search-box" placeholder="Search" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="row">

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Diseases Covered">
                        <DiseasesCovered />
                    </Tab>
                    <Tab eventKey="profile" title="Major Exclusions">
                        <h1>Major Exclusions</h1>
                    </Tab>
                    <Tab eventKey="contact" title="Specific Illness">
                        <h1>Specific Illness</h1>
                    </Tab>
                </Tabs>

            </div>
        </div>
    );
}
export default HealthInsurance;