import { useState , useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import http from "../../../http";
import SweetAlert from 'sweetalert2'

function CoverageArea(props) {

  // console.log(props.formData3);
  
  props.setShortTitle('Mention the area of coverage');
  props.setLongTitle('Select your preference');

  const [coverArea, setCoverArea] = useState(props.preference);
 // setCoverArea()



/*
  const val = [
    'Worldwide Including USA and Canada',
    'Worldwide Excluding USA and Canada',
    'Asia Pacific'
  ]; */

  const [area, setArea] = useState([]);
  useEffect(() => {
    http.get('/masters-data/get-coverage-area', [],
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setArea(resp.data);
        // console.log(area);
      } else {
        SweetAlert.fire(
          'Error!',
          resp.message,
          'error'
        );
      }
  });

  },[])

  const handleSubmit = (event) => {
    event.preventDefault();


    let serialize = require('form-serialize');
    let form = document.querySelector('#my_form');
    let form_data = serialize(form, { hash: true });

    http.post('/client-other-details/add', form_data,
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        props.setFormData3(
          { ...form_data }
        );
        props.setStep(4);
      } else {
        alert(resp.message);
      }
    });




  }

  return (
    <>
      <form onSubmit={handleSubmit} id="my_form">
        <Row className="coverage-area-radio-buttons">
          {
            area.map((item, index) => {
              return (
                <Col className="col-md-12">
                  <label className={(coverArea === item.id ? 'active' : '')}>
                    <input type="radio" name="preference" value={item.id} onClick={() => { setCoverArea(item.id) }} />
                    {item.area}
                  </label>
                </Col>)
            })
          }
          { 
          /*
          <Col className="col-md-12">
            <label className={(coverArea === val[0] ? 'active' : '')}>
              <input type="radio" name="preference" value={val[0]} onClick={() => { setCoverArea(val[0]) }} />
              {val[0]}
            </label>
          </Col>

          <Col className="col-md-12">
            <label className={(coverArea === val[1] ? 'active' : '')}>
              <input type="radio" name="preference" value={val[1]} onClick={() => { setCoverArea(val[1]) }} />
              {val[1]}
            </label>
          </Col>

          <Col className="col-md-12">
            <label className={(coverArea === val[2] ? 'active' : '')}>
              <input type="radio" name="preference" value={val[2]} onClick={() => { setCoverArea(val[2]) }} />
              {val[2]}
            </label>
          </Col> */
           }

        </Row>
        <Row className="continue-btn-section">
          <Col className="col-md-4">
            <input
              onClick={() => props.setStep(2)}
              type="button" className="full-width btn btn-outline-primary btn-lg" value="Back" />
          </Col>
          <Col className="col-md-8">
            <input
              type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
          </Col>
        </Row>
      </form>
    </>
  );
}

export default CoverageArea;
