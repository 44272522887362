import { useContext } from 'react';
import './Home.css';
import ScheduleACall from '../components/widgets/buttons/ScheduleACall'
import plus from '../assets/images/icons/plus.png';

import BoxEasyStep from '../components/widgets/boxs/BoxEasyStep';

import SchemeOffer from '../components/home/SchemeOffer';

import WhyChooseFinnovate from '../components/home/WhyChooseFinnovate';
import ExplorePlan from '../components/home/ExplorePlans';
import HealthInsurance from '../components/home/HealthInsurance';
import EasyClaimProcess from '../components/home/EasyClaimProcess';
import FinnovateReviews from '../components/home/FinnovateReviews';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img from '../components/Img';
import noteContext from '../context/notes/NoteContext';




function Home(props) {
    const context = useContext(noteContext);
    // const p =   context.getQuote();
    //console.log("quote p ", p);

    document.title = "Home";
    const images = props.img;

    //    const scollToExplorePlan = useRef();

    return (
        <>

            {
                ((localStorage.getItem('get_quote_pakage_id') || context.getQuoteP) ?
                    <div className='alert alert-info primary-color'>
                        <i class="fa fa-envelope fa-2x mr-10" aria-hidden="true" ></i>
                        <label className='font-18'>You have recieved a quote from our team</label>
                        <Link to={'/get-quote-details?get_quote_id=' + localStorage.getItem('get_quote_pakage_id')}
                            className="btn btn-primary" style={{ 'position': 'absolute', 'right': '10px' }}>See the Quote</Link>

                    </div> : ''
                )
            }

            <div className='home'>
            <div className='section helth-insurance row'>
                <div className='col-md-6 left-section'>
                    <div className=''>
                        <h1 className='heading font48 lh56'>Get Health Insurance with Global Coverage</h1>
                        <p className='mt-20 mb-25 font20'>Introducing international health insurance by<br /> Manipal in collaboration with Finnovate</p>
                        <div className='mb-20 mb-20'>
                            <ScheduleACall />
                            <Link onClick={() => props.scollToExplorePlan.current.scrollIntoView()} className='btn-link btn-lg ml-20'><img className='round' alt='+' src={plus} /> Explore More</Link>
                        </div>
                        <div className='row'>
                            <div>
                                <p className='font24 mt-20'>
                                    <img src={images.right_arrow_fill.src} alt="" className='mr-10'></img>
                                    Get Insured in <span className='theme-color'>3 Easy Steps</span>
                                </p>
                            </div>
                        </div>
                        <Row className='essay-step-section'>
                            <Col md={4} className="mb-20">
                                <BoxEasyStep icon="phone" text="Schedule a Call with an Agent" />
                            </Col>
                            <Col md={4} className="mb-20">
                                <BoxEasyStep icon="file-with-bg" text="Upload Documents" />
                            </Col>
                            <Col md={4} className="mb-20">
                                <BoxEasyStep icon="warning-with-bg" text="Get Insured Instantly" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='col-md-6 home-bg_'>
                    <div className='gb-2 home-main-image-section'>
                        <img alt='' src={img.home_banner.src}></img>
                    </div>
                    { /*<div><img alt='' src='../assets/images/bg-images/home-bg-2.png'></img></div> */}
                </div>
            </div>
            <SchemeOffer images={images} />
            <WhyChooseFinnovate />
            <div ref={props.scollToExplorePlan}>
                <ExplorePlan images={images} />
            </div>
            <HealthInsurance images={images} />
            <EasyClaimProcess images={images} />
            <FinnovateReviews />
            <br />
            <br />
            </div>

        </>
    );

}

export default Home;