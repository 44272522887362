import { useEffect, useState } from "react";
import FormHeaderSection from "./FormHeaderSection.jsx";
import { Row, Col, Card, Form } from "react-bootstrap";
import img from "../../components/Img.jsx";
import http from "../../http.jsx";
import SweetAlert from 'sweetalert2'


function InsuredDetails(props) {
  

    const [rows, setRows] = useState([]);
    useEffect(() => {
        http.post('/enrollment-form/get-insured-details', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
               // const r = resp.data; 
                if(rows.length === 0){
                    setRows(resp.data);
                }
            } else {
                alert(resp.message);
            }
        });

  }, [rows]);
    // console.log(rows);


    const handleSubmit = (event) => {
        event.preventDefault();

        let serialize = require('form-serialize');
        let form = document.querySelector('#insured_details_form');
        let form_data = serialize(form, { hash: true });
        console.log("insured_details_form: data - ", form_data);    

        http.post('/enrollment-form/save-insured-details', form_data,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                props.setStep('PlanDetails');
                SweetAlert.fire(
                    'Success',
                    'Insured Details Updated Successfully.',
                    'success'
                );
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
                console.log(resp.message);
            }

        });
    }


    return (
        <>
            <Form onSubmit={handleSubmit} id="insured_details_form">

                <FormHeaderSection title="Insured Details" sub_title="Fill out the basic details for the people" step={props.step} setStep={props.setStep} />

                <div className="proposer-details form-section">
                    <Row>
                        <Col md={7}>


                            {
                                rows.map((item, key) => {

                                    const name_ = (n) => {
                                        return "data[" + key + "][" + n + "]";
                                    }

                                    return (<>
                                        {
                                            (key === 0 && (<>
                                                <Row className="mb-20">
                                                    <Col md={12} className="form-group">
                                                        <label for="unique_identification_number">Unique identification Number (of Proposer)</label>
                                                        <input type="text" name="unique_identification_number" placeholder="Unique identification Number" className="form-control" defaultValue={item.unique_identification_number}></input>
                                                    </Col>
                                                </Row>
                                            </>))
                                        }
                                        <div className="section-1 mb-20">

                                            <h5><span className="counter fill">{(key + 1)}</span> Policy Holder Details ({item.relationship})</h5>
                                            <div className="ml-20">
                                                <small className="ml-25">Soft copies of your policy documents will beshared over the phone and email address provided below</small>
                                            </div>
                                            <Row>
                                                <input type="hidden" defaultValue={item.member_id} name={name_('member_id')} />
                                                <Col md={6} className="form-group">
                                                    <label for={name_('name')}>Name of Insured Member</label>
                                                    <input type="text" name={name_('name')} placeholder="Name of Insured Member" className="form-control" defaultValue={item.name}></input>
                                                </Col>
                                                <Col md={6} className="form-group">
                                                    <label for={name_('relationship')}>Relationship</label>
                                                    <select className="form-control" name={name_('relationship')} defaultValue={item.relationship}>
                                                        <option value="">-- Select --</option>
                                                        <option value="self">Self</option>
                                                        <option value="spouse">Spouse</option>
                                                        <option value="son">Son</option>
                                                        <option value="daughter">Daughter</option>
                                                    </select>
                                                </Col>
                                                <Col md={6} className="form-group">
                                                    <label for={name_('date_of_birth')}>Date of birth</label>
                                                    <input type="date" name={name_('date_of_birth')} placeholder="Relationship" className="form-control" value={item.date_of_birth}></input>
                                                </Col>

                                                <Col md={6} className="form-group">
                                                    <label for={name_('gender')}>Gender</label>
                                                    <select className="form-control" name={name_('gender')} defaultValue={item.gender}>
                                                        <option value="">-- Select --</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                </Col>

                                                <Col md={6} className="form-group">
                                                    <label for={name_('height')}>Height (in cm)</label>
                                                    <input type="text" name={name_('height')} placeholder="Height" className="form-control" defaultValue={item.height}></input>
                                                </Col>
                                                <Col md={6} className="form-group">
                                                    <label for={name_('weight')}>Weight (in kg)</label>
                                                    <input type="text" name={name_('weight')} placeholder="Weight" className="form-control" defaultValue={item.weight}></input>
                                                </Col>
                                                <Col md={6} className="form-group">
                                                    <label for={name_('nationality')}>Nationality</label>
                                                    <input type="text" name={name_('nationality')} placeholder="nationality" className="form-control" defaultValue={item.nationality}></input>
                                                </Col>
                                                <Col md={6} className="form-group">
                                                    <label for={name_('passport_number')}>Passport Number</label>
                                                    <input type="text" name={name_('passport_number')} placeholder="Passport Number" className="form-control" defaultValue={item.passport_number}></input>
                                                </Col>

                                            </Row>
                                        </div>
                                    </>);

                                })
                            }

                            {
                        /*
                        <div className="section-1">

                            <h5><span className="counter fill">1</span> Policy Holder Details</h5>
                            <div className="ml-20">
                                <small className="ml-25">Soft copies of your policy documents will beshared over the phone and email address provided below</small>
                            </div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <label for="name_of_insured_number">Name of Insured number</label>
                                    <input type="text" name="name_of_insured_number" placeholder="Name of Insured number" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="relationship">Relationship</label>
                                    <input type="text" name="relationship" placeholder="Relationship" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="dob">Date of birth</label>
                                    <input type="date" name="dob" placeholder="Relationship" className="form-control"></input>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="gender">Gender</label>
                                    <select className="form-control" name="gender">
                                        <option>-- Select --</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="height">Height (in metres)</label>
                                    <input type="text" name="height" placeholder="Height" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="wieght">Wieght (in kg)</label>
                                    <input type="text" name="weight" placeholder="Weight" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="nationality">Nationality</label>
                                    <input type="text" name="nationality" placeholder="nationality" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="passport_number">Passport Number</label>
                                    <input type="text" name="passport_number" placeholder="Passport Number" className="form-control"></input>
                                </Col>

                            </Row>
                        </div>

                        <div className="section-2 mt-20">
                            <h5><span className="counter fill">2</span> Dependent 1 Details</h5>
                            <div className="ml-20">
                                <small className="ml-25">Soft copies of your policy documents will beshared over the phone and email address provided below</small>
                            </div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <label for="name_of_insured_number">Name of Insured number</label>
                                    <input type="text" name="name_of_insured_number" placeholder="Name of Insured number" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="relationship">Relationship</label>
                                    <input type="text" name="relationship" placeholder="Relationship" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="dob">Date of birth</label>
                                    <input type="date" name="dob" placeholder="Relationship" className="form-control"></input>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="gender">Gender</label>
                                    <select className="form-control" name="gender">
                                        <option>-- Select --</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="height">Height (in metres)</label>
                                    <input type="text" name="height" placeholder="Height" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="wieght">Wieght (in kg)</label>
                                    <input type="text" name="weight" placeholder="Weight" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="nationality">Nationality</label>
                                    <input type="text" name="nationality" placeholder="nationality" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="passport_number">Passport Number</label>
                                    <input type="text" name="passport_number" placeholder="Passport Number" className="form-control"></input>
                                </Col>

                            </Row>
                        </div>


                        <div className="section-2 mt-20">
                            <h5><span className="counter fill">3</span> Dependent 2 Details</h5>
                            <div className="ml-20">
                                <small className="ml-25">Soft copies of your policy documents will beshared over the phone and email address provided below</small>
                            </div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <label for="name_of_insured_number">Name of Insured number</label>
                                    <input type="text" name="name_of_insured_number" placeholder="Name of Insured number" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="relationship">Relationship</label>
                                    <input type="text" name="relationship" placeholder="Relationship" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="dob">Date of birth</label>
                                    <input type="date" name="dob" placeholder="Relationship" className="form-control"></input>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="gender">Gender</label>
                                    <select className="form-control" name="gender">
                                        <option>-- Select --</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="height">Height (in metres)</label>
                                    <input type="text" name="height" placeholder="Height" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="wieght">Wieght (in kg)</label>
                                    <input type="text" name="weight" placeholder="Weight" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="nationality">Nationality</label>
                                    <input type="text" name="nationality" placeholder="nationality" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="passport_number">Passport Number</label>
                                    <input type="text" name="passport_number" placeholder="Passport Number" className="form-control"></input>
                                </Col>

                            </Row>
                        </div>


                        <div className="section-2 mt-20">
                            <h5><span className="counter fill">4</span> Dependent 3 Details</h5>
                            <div className="ml-20">
                                <small className="ml-25">Soft copies of your policy documents will beshared over the phone and email address provided below</small>
                            </div>
                            <Row>
                                <Col md={6} className="form-group">
                                    <label for="name_of_insured_number">Name of Insured number</label>
                                    <input type="text" name="name_of_insured_number" placeholder="Name of Insured number" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="relationship">Relationship</label>
                                    <input type="text" name="relationship" placeholder="Relationship" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="dob">Date of birth</label>
                                    <input type="date" name="dob" placeholder="Relationship" className="form-control"></input>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="gender">Gender</label>
                                    <select className="form-control" name="gender">
                                        <option>-- Select --</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </Col>

                                <Col md={6} className="form-group">
                                    <label for="height">Height (in metres)</label>
                                    <input type="text" name="height" placeholder="Height" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="wieght">Wieght (in kg)</label>
                                    <input type="text" name="weight" placeholder="Weight" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="nationality">Nationality</label>
                                    <input type="text" name="nationality" placeholder="nationality" className="form-control"></input>
                                </Col>
                                <Col md={6} className="form-group">
                                    <label for="passport_number">Passport Number</label>
                                    <input type="text" name="passport_number" placeholder="Passport Number" className="form-control"></input>
                                </Col>

                            </Row>
                        </div>


                        */ }


                        </Col>
                        <Col md={5}>
                            <Card className="guidelines-section">
                                <h4><img src={img.notebook.src} alt=""></img> Guidelines</h4>
                                <small>Aadhaar card number is a 16-digit number on your aadhaar card</small>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}


export default InsuredDetails;