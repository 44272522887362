import { useState, useEffect, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import './PreferedPlan.css'
import { Link } from "react-router-dom";
import img from "../components/Img";
import Package from "./prefered-plan/Package";
import http from './../http'
import NoteContext from "../context/notes/NoteContext";

//import {_number_format} from './../funcations'
import GetQuotesList from "./prefered-plan/GetQuotesList";
import CallBackComponents from "../components/widgets/CallBackComponents";

function PreferedPlan() {

    const context = useContext(NoteContext);



    const [packageLists, setPackagesList] = useState([]);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.get('/masters-data/get-packages', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setPackagesList(resp.data);
                } else {
                    // 
                }
            }).catch(err => {
                const resp = err.response.data;
                context.checkTokenStatus(resp);
            });
        }
    }, []);

    const [proposer, setProposerDetails] = useState({});
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }
    }, []);


    //let proposer = context.getProposerDetails;
    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });


console.log("my_get_quote_list ::: ", localStorage.getItem('my_get_quote_list'));

    return (
        <>
            <Row>
                <Col md={8}>
                    <div className="back">
                        <Link to="/#"> <img src={img.arrow_left.src} alt=""></img> </Link>
                        Select your prefered plan
                    </div>
                    <Row>
                        <Col md={9}>Lorem ipsum dolor sit amet, consec pellentesque sed purus adipiscing aenean potenti </Col>
                        <Col md={3} className="right">
                            <Link to={"/compare-plans"} className="btn btn-link border"><  img src={img.compare.src} alt=""></img> Compare plans</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Card className="mt-20 p-3">
                            <Row>
                                <Col md={3} className="p-2"> <img src={img.users.src} alt=""></img> Policy For</Col>
                                <Col md={9} className="p-2"> {ages_html}
                                </Col>
                                <Col md={3} className="p-2"> <img src={img.web_sm.src} alt=""></img>  Covergae Area</Col>
                                <Col md={9} className="p-2">{proposer.coverage_area}  <br />{proposer.vision_and_dental_covered === 'yes' ? 'With includes Dental and Vision in Base' : 'Doesnot Include Dental and Vision'}</Col>
                            </Row>
                            <Row>
                                <Col md={12} className="text-right">
                                    <Link to={"/client-requirements?action=edit"} className="btn-link"><img src={img.edit.src} alt=""></img> Edit Requirement</Link>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                    <Row>
                        {
                            packageLists.map((row) => {
                                return (<Package row={row} />);
                            })
                        }

                    </Row>

                   

                </Col>
                <Col md={4}>
                    <GetQuotesList />
                    <CallBackComponents /> 
                </Col>

            </Row>
        </>
    )
}

export default PreferedPlan;