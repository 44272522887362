
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import img from '../Img';
import './buttons/ScheduleACall.css'
import './ContactOnWhatsapp.css'

import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import http from './../../http'
import SweetAlert from 'sweetalert2'

function ContactOnWhatsapp(props) {

  const { register, handleSubmit, setError, formState: { errors } } = useForm();


  const [lgShow, setLgShow] = useState(false);

  const onSubmitRegister = request => {
    // console.log(request);
    let name = request.name;
    let phone = "+919136925379"; // request.phone;
    let text = request.text;
    const payload = {
      name: name,
      mobile: request.phone,
      message: text
    }
    http.post('/save-whatsapp-query', payload,
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        window.location.href = 'https://api.whatsapp.com/send?name=' + name + '&phone=' + phone + '&text=' + text;
      } else {
        SweetAlert.fire(
          'Error!',
          resp.message,
          'error'
        );
      }
    });



  }
  return (
    <>
      <button className="btn btn-success btn-lg mobile-full-width" onClick={() => setLgShow(true)}>
        <img src="./assets/images/icons/whatsapp.png" alt=".." className="mr-10"></img>
        Contact on Whatsapp
      </button>


      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className="image-section section-1">
              <img className='w-90' src={img.video_call_monochromatic.src} alt={img.video_call_monochromatic.alt}></img>
            </Col>
            <Col md={6} className="text-center section-2">
              <div className='icon text-center mb-20'>
                <img src={img.whatsapp_fill_green_c.src} alt={img.whatsapp_fill_green_c.alt} />
              </div>
              <div className='title text-center mb-25'>
                <h2>Contact on Whatsapp</h2>
                <span>with our Insurance Agent</span>
              </div>

              <Form action='https://api.whatsapp.com/send' method='GET' target='_blank' onSubmit={handleSubmit(onSubmitRegister)}>
                <fieldset>
                  <Form.Group className="mb-3">
                    <Form.Control placeholder="Full Name" size="lg" name="name" {...register("name", { required: 'Name is required' })} />
                    <span class={errors.name ? 'required' : ''}>
                      {errors.name && errors.name.message}
                    </span>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control placeholder="Whatsapp Mobile Number" size="lg" name="phone" {...register("phone", { required: 'Mobile Number is required' })} />
                    <span class={errors.phone ? 'required' : ''}>
                      {errors.phone && errors.phone.message}
                    </span>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control as="textarea" placeholder="Tell us your Query" size="lg" name="text"   {...register("text", { required: 'Query is required' })} />
                    <span class={errors.text ? 'required' : ''}>
                      {errors.text && errors.text.message}
                    </span>
                  </Form.Group>

                  <Button as="input" type="submit" value="Submit" id="submit_btn" className='btn btn-lg btn-primary' />
                  <Row className='privacy-policy'>
                    <p>Finnovate will never share or spam your email or phone number. To know more about our privacy policy click here <Link to={"/#"}>click here</Link></p>
                  </Row>
                </fieldset>
              </Form>

            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ContactOnWhatsapp;