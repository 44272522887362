
import img from "../Img";

const cardBox = (icon, title, details) => {
    return (
        <div className="col-md-4">
            <div className="card m-20 p-25">
                <div className='icon text-center mb-15'><img src={icon} alt=''></img></div>
                <div className='title text-center mb-15'><strong>{title}</strong></div>
                <div className='details text-center mb-15'>{details}</div>
            </div>
        </div>  
    );
}


function WhyChooseFinnovate() {

    return (
        <div className="section scheme-offer mt-50">
            <div className="title ">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src={img.file.src} alt={img.file.alt}></img>
                        <h1>Why you should choose Finnovate ?</h1>
                        <p className='text-center'>Get all the listed benefits when you become the part of our Finnovate Family</p>
                    </div>
                </div>
            </div>
            <div className="row">
                
                {cardBox(img.file.src, 'International Emergency Services', 'Emergency medical assistance and coverage for international health emergencies.')}

                {cardBox(img.light.src, 'Worldwide Coverage of 1 Million USD', 'Extensive protection for global health expenses ranging $250k, $500k & $1 million.')}

                {cardBox(img.man_step.src, 'Rehabilitation and Wellness', 'Specialized programs and therapies for holistic healing and optimal well-being.')}

                {cardBox(img.card.src, 'Cashless OPD Coverage', 'Seamless Cashless payment for outpatient medical expenses with global coverage.')}

                {cardBox(img.medical_box.src, 'Flexible Medical Care', 'Flexibility to choose deductible and coverage limits based on personal needs')}

                {cardBox(img.file.src, '24/7 dedicated helpline', 'Expert assistance at any time, ensuring your queries are addressed promptly')}

            </div>
        </div>
    );
}
export default WhyChooseFinnovate;