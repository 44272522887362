 import Timeline from '../widgets/Timeline';
 
function SchemeOffer(props ) {  
    let img = props.images;
  return (
    <div className="section scheme-offer mt-50">
        <div className="title ">
            <div className="row"> 
                <div className="col-md-12 text-center">
                    <img src={img.file.src} alt={img.file.alt}></img>
                    <h1>What does the scheme offer ?</h1>
                    <p className='text-center'>Get all the listed benefits when you become the part of our Finnovate Family</p>
                </div>                
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 pb-25 pt-25 family-monochromatic">
                <img src={img.family_monochromatic_2.src} alt="" ></img>    
            </div>
            <div className="col-md-6">
                <Timeline />
            </div>
        </div>
    </div>
  );
}
export default SchemeOffer;

