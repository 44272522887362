import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function FormHeaderSection(props) {

    const backStep = (step) => {
       // alert(step);
        switch (step) {
            case 'SignAndConfirm':
                return props.setStep('Documents');

            case 'Documents':
                return props.setStep('Nominee');

            case 'Nominee':
                return props.setStep('AdditionalHealthDetails');

            case 'AdditionalHealthDetails':
                return props.setStep('HealthQuestionnaire');

            case 'HealthQuestionnaire':
                return props.setStep('PlanDetails');

            case 'PlanDetails':
                return props.setStep('InsuredDetails');

            case 'InsuredDetails':
                return props.setStep('ProposerDetails');

            case 'ProposerDetails':
                return props.setStep('ProposerDetails');

            default:
                return false;
        }

    };

    return (
        <div className="header">
            <Row className="p-10">
                <Col md={8}><h4>{props.title}</h4>
                    <small>{props.sub_title}</small>
                </Col>
                <Col md={4} className="right">
                    <Link className={ (props.step === 'ProposerDetails' ? 'disabled ' : '' ) +" btn btn-link border mr-10"} onClick={() => backStep(props.step)}><i class="fa fa-angle-left" aria-hidden="true"></i> Back</Link>
                    <button type="submit" className={ (props.step === 'SignAndConfirm' ? 'disabled ' : '' ) +" btn btn-link border btn-primary"}>Next <i class="fa fa-angle-right" aria-hidden="true"></i></button>

                </Col>
            </Row>
            <Row><hr /></Row>
        </div>
    )
}
export default FormHeaderSection;