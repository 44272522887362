import { useState, useEffect, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import './PreferedPlan.css'
import { Link } from "react-router-dom";
import img from "../components/Img";
import http from '../http'
import NoteContext from "../context/notes/NoteContext";

import { package_icon } from '../funcations'
import GetQuotesListNew from "./prefered-plan/GetQuotesListNew";
import CallBackComponents from "../components/widgets/CallBackComponents";


function Dashboard() {

    const context = useContext(NoteContext);

    const [packageLists, setPackagesList] = useState([]);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.get('/masters-data/get-packages', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setPackagesList(resp.data);


                } else {
                    // 
                }
            }).catch(err => {
                const resp = err.response.data;
                context.checkTokenStatus(resp);
            });
        }
    }, []);

    const [proposer, setProposerDetails] = useState({});

    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }


    }, []);


    //let proposer = context.getProposerDetails;
    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });


    //console.log("my_get_quote_list ::: ", localStorage.getItem('my_get_quote_list'));

    const [all_quotes, setAllQuotes] = useState([]);
    const [pending_quotes, setPendingQuotes] = useState(0);
    const [approved_quotes, setApprovedQuotes] = useState(0);

    useEffect(() => {
        let a = 0; let p = 0;
        all_quotes.map((item, index) => {
            if (item.approval_status === 'approved') {
                a++;
            }
            if (item.approval_status === 'pending') {
                p++;
            }
        });
        setApprovedQuotes(a);
        setPendingQuotes(p);
    }, [all_quotes]);




    return (
        <>
            <Row>
                <Col md={8}>
                    <div>
                        <h4>Dashboard</h4>
                    </div>
                    <Row>
                        <div className="col-md-4">
                            <Card className="p-10 min-height-100 requested_quotes_details_card" style={{ "background-image": "url(" + img.plant.src + ")" }}>
                                <span>Requested Quotes </span>
                                <div className="mt-15 mb-10">
                                    <span className="font30 mr-20">{approved_quotes + pending_quotes}</span>
                                    <span className="pending_label"> <i class="fa fa-arrow-up" aria-hidden="true"></i> {pending_quotes} Pending</span>
                                </div>
                                <div><span className="approved_label">{approved_quotes}</span> <span>Approved</span></div>
                            </Card>
                        </div>
                        <div className="col-md-8">
                            <Card className="p-10 min-height-100">
                                <table className="width-100 mt-10">
                                    <tr>
                                        {
                                            packageLists.map((row) => {
                                                return (
                                                    <td>
                                                        <Link to={"/plan-details?id=" + row.id} style={{ "text-decoration": 'none' }}>
                                                            <img className="fa_3x mr-10" src={package_icon(row.name)} alt=""></img>
                                                            <span className="font18">{row.name} Plan</span>
                                                        </Link>
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                </table>
                                <div className="row mt-10">
                                    <div className="col-md-6 center"><Link to={"/compare-plans"} className="btn btn-link border"><img src={img.compare.src} alt=""></img> Compare plans</Link></div>
                                    <div className="col-md-6  center"><Link to="/client-requirements?action=edit" className="btn border">Calculate Premium <i class="fa fa-arrow-right" aria-hidden="true"></i></Link></div>
                                </div>
                            </Card>

                        </div>
                    </Row>
                    <div className="height-40"></div>
                    <Row>
                        {
                            (all_quotes.length > 0 && <div className="left"><h4>All Quote List</h4></div>)
                        }

                        <GetQuotesListNew className="col-md-6 mb-25" setAllQuotes={setAllQuotes} />
                    </Row>

                </Col>
                <Col md={4}>
                    <GetQuotesListNew last_quote={true} className="col-md-12 mb-25" setAllQuotes={setAllQuotes} />
                    <CallBackComponents />
                </Col>

            </Row>
        </>
    )
}

export default Dashboard;