import { React, useEffect , useContext, useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import FormHeaderSection from "./FormHeaderSection.jsx";
import img from "../../components/Img.jsx";
import http from "../../http.jsx";
import SweetAlert from 'sweetalert2'

import noteContext from '../../context/notes/NoteContext';

//import { useForm } from "react-hook-form";

function ProposerDetails(props ) {
   /* 
    console.log("pppppp:  " , props);
    const s = "InsuredDetails"; 

    setTimeout(function(){ 
        props.setStep('InsuredDetails');
        console.log("props.step: -----> ", props.step);
    },5000);
 */
    const context = useContext(noteContext);

    // const { register, handleSubmit, setError, formState: { errors } } = useForm(); // for register form
    const [old_data , setOldData] = useState({});
    
    useEffect(() => {
        http.post('/proposer-details', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } },
            { async: false }
        ).then((response) => {
            const resp = response.data;  
            if (resp.success) { 
                setOldData(resp.data);

                document.getElementById('gender').value = resp.data.gender;
                document.getElementById('material_status').value = resp.data.material_status;  
 
                    
            } else {
                alert(resp.message);
            }
        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp); 
        });
    }, [context]); 


    let newDate = new Date()
    let current_date = newDate.toISOString().substring(0, 10);


    const handleSubmit = (event) => {
        event.preventDefault();

        let serialize = require('form-serialize');
        let form = document.querySelector('#proper_form');
        let form_data = serialize(form, { hash: true });

        http.post('/client-other-details/add', form_data,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) { 
                props.setStep('InsuredDetails');
                SweetAlert.fire(
                    'Success',
                    "Proposer Details Updated Successfully.",
                    'success'
                ).then(function () {
                   //
                });
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
                console.log(resp.message);
            }

        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp); 
        });
    }

    return (
        <>

            <Form onSubmit={handleSubmit} id="proper_form">
                <FormHeaderSection title="Proposer Details" sub_title="Fill out the basic details for the people" step={props.step} setStep={props.setStep} />
                <div className="proposer-details form-section">
                    <Row>
                        <Col md={8}>
                            <div className="section-1">
                                <h5><span className="counter fill">1</span> Proposer Details</h5>
                                <div className="ml-20">
                                    <small className="ml-25">The person paying for th epolicy has to be the proposer</small>
                                </div>
                                <Row>
                                    <Col md={6} className="form-group">
                                        <label for="full_name">Full Name</label>
                                        <input type="text" id="proposer_name" defaultValue={old_data.proposer_name} name="full_name" placeholder="Full Name" className="form-control" />
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="dob">Date of birth</label>
                                        <input type="date" name="date_of_birth" defaultValue={old_data.date_of_birth } placeholder="Date of birth" className="form-control" id="date_of_birth" min="1950-01-01" max={current_date} />
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="gender">Gender</label>
                                        <select className="form-control" name="gender" id="gender" defaultValue={old_data.gender }>
                                            <option value="">-- Select --</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="material_status">Material Status</label>
                                        <select className="form-control" name="material_status" id="material_status" defaultValue={old_data.material_status }>
                                            <option>-- Select --</option>
                                            <option value="single">Single</option>
                                            <option value="married">Married</option>
                                        </select>
                                    </Col>

                                    <Col md={6} className="form-group">
                                        <label for="aadhaar_number">Aadhaar Number</label>
                                        <input defaultValue={old_data.aadhaar_number } type="text" id="aadhaar_number" name="aadhaar_number" placeholder="Adhaar Number" className="form-control"></input>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="pancard_number">Pancard Number</label>
                                        <input defaultValue={old_data.pan } type="text" name="pancard" placeholder="Pancard Number" className="form-control" id="pancard" />
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="customer_GSTIN">Customer GSTIN (if any)</label>
                                        <input defaultValue={old_data.customer_gst_no } type="text" id="customer_gst_no" name="customer_gst_no" placeholder="Customer GSTIN (if any)" className="form-control"></input>
                                    </Col>

                                </Row>
                            </div>

                            <div className="section-2 mt-20">
                                <h5><span className="counter fill">2</span> Contact Details</h5>
                                <div className="ml-20">
                                    <small className="ml-25">Soft copies of your policy documents will beshared over the phone and email address provided below</small>
                                </div>
                                <Row>
                                    <Col md={6} className="form-group">
                                        <label for="phone_number">Phone Number</label>
                                        <input defaultValue={old_data.mobile } type="text" name="mobile" placeholder="Phone Number" className="form-control" id="mobile" />
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="email">Email</label>
                                        <input defaultValue={old_data.email } type="email" name="email" placeholder="Email" className="form-control" id="email" />
                                    </Col>
                                </Row>
                            </div>






                            <div className="section-3 mt-20">
                                <h5><span className="counter fill">3</span> Communication address</h5>
                                <div className="ml-20">
                                    <small className="ml-25">The physical polies of your policy documents will be sent to this address</small>
                                </div>
                                <Row>
                                    <Col md={6} className="form-group">
                                        <label for="address_line_1">Flat, House no., Building, Apartment</label>
                                        <input defaultValue={old_data.address_line_1 } type="text" name="address_line_1" placeholder="Flat, House no., Building, Apartment" className="form-control" id="address_line_1"></input>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="address_line_2">Area, Street, Sector, Village</label>
                                        <input defaultValue={old_data.address_line_2 } type="text" id="address_line_2" name="address_line_2" placeholder="Area, Street, Sector, Village" className="form-control"></input>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="pincode">Pincode</label>
                                        <input defaultValue={old_data.pincode } type="text" name="pincode" id="pincode" placeholder="Pincode" className="form-control"></input>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="area">Area</label>
                                        <input defaultValue={old_data.area } type="text" name="area" id="area" placeholder="Area" className="form-control"></input>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="city">City</label>
                                        <input defaultValue={old_data.city } type="text" name="city" id="city" placeholder="City" className="form-control"></input>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="state">State</label>
                                        <input defaultValue={old_data.state } type="text" name="state" id="state" placeholder="State" className="form-control"></input>
                                    </Col>
                                </Row>
                            </div>


                        </Col>
                        <Col md={4}>
                            <Card className="guidelines-section">
                                <h4><img src={img.notebook.src} alt=""></img> Guidelines</h4>
                                <small>Aadhaar card number is a 16-digit number on your aadhaar card</small>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}


export default ProposerDetails;