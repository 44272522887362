import { Card, Col, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import img from "../components/Img";
import './Register.css'

import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import OTPInput from "otp-input-react";

import http from "../http";

function Register(props) {
    document.title = "Register"

    const [step, setStep] = useState(1);
    const [OTP, setOTP] = useState("");
    const [OTP2, setOTP2] = useState("");

    const { register, handleSubmit, setError, formState: { errors } } = useForm(); // for register form


    const {
        handleSubmit: handleSubmitOtp,
    } = useForm();
    /*
        http.post('/user-details',[]).then((response2)=>{
            console.log(response2.data);
        }); */
    const [em, setEm] = useState({
        email: '',
        mobile:''
    });    
    const onSubmitRegister = request => {

        console.log(request);
        const payload = {
            'name': request.full_name,
            'email': request.email,
            'mobile': request.phone_number,
            'pan': request.pan,
            'password': request.password,
            'confirm_password': request.confirm_password
        }
        setEm({
            email: request.email,
            mobile: request.phone_number
        })

        http.post('/register', payload).then((response) => {
            // console.log(localStorage.getItem("token"));
            const resp = response.data; 
            if (resp.success) {
               // alert(resp.message);
                console.log(resp);
                const api_data = resp.data;
                console.log('token',api_data.token); 
                 
                localStorage.setItem("token", api_data.token);
                localStorage.setItem("name", api_data.user.name);
                localStorage.setItem("email", api_data.user.email);
                localStorage.setItem("mobile", api_data.user.mobile);
                localStorage.setItem("register_step", 2);
                setStep(2);
                /*set_register_card('hide');
                set_otp_card('show');
                set_success_card('hide');  */

            } else {
                alert(resp.message);
                const error = resp.errors;
                if (error.name) { 
                    setError('full_name', { message: error.name[0] });
                }
                if (error.email) {
                    setError('email', { message: error.email[0] });
                }
                if (error.pan) {
                    setError('pan', { message: error.pan[0] });
                }

                if (error.mobile) {
                    setError('phone_number', { message: error.mobile[0] });
                }
                if (error.password) {
                    setError('password', { message: error.password[0] });
                }

                if (error.confirm_password) {
                    setError('confirm_password', { message: error.confirm_password[0] });
                }

                // console.log(resp.data);
            }
        });
    }

    const onSubmitOtp = data => {
        console.log("Phone Number otp value: " + OTP);
        console.log("Email otp value: " + OTP2);
        const payload = {
            "mobile_otp": parseInt(OTP),
            "email_otp": parseInt(OTP2), 
        }

        http.post('/otp-verification', payload ,
            { headers: {"Authorization": "Bearer "+localStorage.getItem("token")} }
        ).then((response2) => {
            console.log(response2.data);
            const resp = response2.data;
            if (resp.success) {
                localStorage.setItem("register_step", 3);
                setStep(3);
                setError('otp', { message: false });
                setError('otp1', { message: false });
            } else {
                //alert(resp.message);
                const error = resp.errors;
                if (error.mobile) {
                    setError('otp1', { message: error.mobile });

                }
                if (error.email) {
                    setError('otp', { message: error.email });

                }
            }
            //setOTP('');
            //setOTP2('');

        });
        /*
        set_register_card('hide');
        set_otp_card('hide');
        set_success_card('show'); */
    }


    const [register_card, set_register_card] = useState('show');
    // set_register_card('show');
    const [otp_card, set_otp_card] = useState('hide');
    const [success_card, set_success_card] = useState('hide');

    if (!localStorage.getItem("register_step")) {
        localStorage.setItem("register_step", 1);
    }
    console.log("register_step : " + localStorage.getItem("register_step"));

    useEffect(() => {
        //alert(step);
        if (step === 1) {
            set_register_card('show');
            set_otp_card('hide');
            set_success_card('hide');
        }
        if (step === 2) {
            set_register_card('hide');
            set_otp_card('show');
            set_success_card('hide');
        }
        if (step === 3) {
            set_register_card('hide');
            set_otp_card('hide');
            set_success_card('show');
        }
    }, [step]);

    console.log(step);
    return (
        <>
            <Row className="register-section">
                <div className="col-md-6 register-section-left">
                    <h1>We are here with just the right insurance for you.</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pulvinar pellentesque sed purus adipiscing aenean potenti </p>
                    <Row className="text-center family-image">
                        <img src={img.family_insured.src} alt={img.family_insured.alt}></img>
                    </Row>

                </div>
                <div className="col-md-6 register-section-right">
                    <div className="col-md-11">
                        <Card className={register_card}>
                            <span>login if you already have an account</span>
                            <h2>Sign up to get started</h2>

                            <Form id="register_form" onSubmit={handleSubmit(onSubmitRegister)} className="register-form">
                                <Row className="mb-8 mb-20">

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Full name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Eg Satish Kaushik"
                                            name="full_name"
                                            className={errors.full_name ? 'error' : ''}
                                            {...register("full_name", { required: 'Full Name is required' })}
                                        />

                                        <span class={errors.full_name ? 'required' : ''}>
                                            {errors.full_name && errors.full_name.message}
                                        </span>

                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Email Adress</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="abc@gmail.com"
                                            name="email"
                                            className={errors.email ? 'error' : ''}
                                            {...register("email", { required: 'Email is required' })}

                                        />
                                        <span class={errors.email ? 'required' : ''}>
                                            {errors.email && errors.email.message}
                                        </span>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone Number"
                                            name="phone_number"
                                            size="10"
                                            className={errors.phone_number ? 'error' : ''}
                                            {...register("phone_number", { required: 'Phone Number is required' })}
                                        />
                                        <span class={errors.phone_number ? 'required' : ''}>
                                            {errors.phone_number && errors.phone_number.message}
                                        </span>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                                        <Form.Label>PAN numer</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="PAN numer"
                                            name="pan"
                                            className={errors.pan ? 'error' : ''}
                                            {...register("pan", { required: 'PAN is required' })}
                                        />
                                        <span class={errors.pan ? 'required' : ''}>
                                            {errors.pan && errors.pan.message}
                                        </span>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom05">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            name="password"

                                            className={errors.password ? 'error' : ''}
                                            {...register("password", { required: 'Password is required' })}
                                        />
                                        <span class={errors.password ? 'required' : ''}>
                                            {errors.password && errors.password.message}
                                        </span>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom06">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirm Password"
                                            name="confirm_password"
                                            className={errors.confirm_password ? 'error' : ''}
                                            {...register("confirm_password", { required: 'Confirm password is required' })}
                                        />
                                        <span class={errors.confirm_password ? 'required' : ''}>
                                            {errors.confirm_password && errors.confirm_password.message}
                                        </span>

                                    </Form.Group>

                                    <p><Link to={'/login'}>I already have an account (Login)</Link></p>

                                </Row>
                                <input type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
                            </Form>
                        </Card>


                        <Card className={otp_card}>
                            <span>To complete setup of your new account, </span>
                            <h2>Enter OTP for Verificaion</h2>
                            <Form id="register_otp_form" onSubmit={handleSubmitOtp(onSubmitOtp)} className="register-form">
                                <Row className="mb-8 mb-20">


                                    <Col md="12" className="mb-1">
                                        <Form.Label>Enter OTP (+91 {em.mobile})</Form.Label>
                                        <Link className="otp-edit-btn" to={"/#"}>Edit</Link>
                                    </Col>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <OTPInput
                                            name="otp1"
                                            value={OTP}
                                            onChange={setOTP}
                                            autoFocus
                                            OTPLength={5}
                                            otpType="number"
                                            disabled={false}
                                            secure
                                        //   {...register("otp1", { required: false })}
                                        />
                                        <span class={errors.otp1 ? 'required' : ''}>
                                            {errors.otp1 && errors.otp1.message}
                                        </span>
                                        { /*<ResendOTP handelResendClick={() => console.log("Resend clicked")} /> */}

                                    </Form.Group>
                                </Row>

                                <Row className="mb-8 mb-20">
                                    <Col md="12" className="mb-1">
                                        <Form.Label>Enter OTP ({em.email})</Form.Label>
                                        <Link className="otp-edit-btn" to={"/#"}>Edit</Link>
                                    </Col>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <OTPInput
                                            name="otp[]"
                                            value={OTP2}
                                            onChange={setOTP2}
                                            autoFocus
                                            OTPLength={5}
                                            otpType="number"
                                            disabled={false}
                                            secure
                                        // {...register("otp", { required: false })}
                                        />
                                        <span class={errors.otp ? 'required' : ''}>
                                            {errors.otp && errors.otp.message}
                                        </span>
                                    </Form.Group>
                                </Row>
                                <Button type="submit" className="full-width" variant="primary" size="lg">Continue</Button>
                            </Form>
                        </Card>

                        <Card className={success_card}>
                            <Col className="text-center">
                                <img className="fa_7x" src={img.success_fill.src} alt={img.success_fill.alt}></img>
                            </Col>
                            <Col className="mt-20 text-center mb-5">
                                <h2>Registeration Completed</h2>
                                <span>Congratulations, You are now a part of Finnovate Community </span>
                            </Col>
                            <a href={"/client-requirements"} className="full-width btn btn-primary btn-lg" >Continue</a>
                        </Card>

                    </div>
                </div>
            </Row>
        </>
    );
}


export default Register;