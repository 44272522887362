import { Link } from 'react-router-dom';
import '../../assets/css/Navbar.css';
import { useState, useContext, useEffect } from 'react';
//import { useNavigate } from "react-router-dom";


import http from '../../http';
import noteContext from '../../context/notes/NoteContext';



import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function MyNavbar(props) {

  const context = useContext(noteContext);

  const openScheduleCallPopup = () => {
    context.setScheduleACall(true);
  }

  const [current, setCurrect] = useState('/');
  const active = (para) => {
    //console.log('para :'+para );
    setCurrect(para);
  }

  const logout = () => {
    http.post('/logout', [],
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      context.checkTokenStatus(resp);
      // console.log(resp);
      if (resp.success) {
        localStorage.clear();
        /*
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("mobile");
        localStorage.removeItem("get_quote_pakage_id"); */
        window.location = "/login";
      } else {
        // localStorage.clear();
        // window.location = "/login";
        //alert(resp.message);
      }

    }).catch(err => {
      const resp = err.response.data;
      context.checkTokenStatus(resp);
    });
  }

  //const navigate = useNavigate();
  const loadExplorePlans = () => {
    // alert(localStorage.getItem('explore_plans'));
    //navigate("/"); 
    //window.location.hash.substr(1)
    props.scollToExplorePlan.current.scrollIntoView();

  }

  const scrollDown = () => {
    setExplorePlansClickCount(explore_plans_click_count + 1);
  }


  const [explore_plans_click_count, setExplorePlansClickCount] = useState(1);

  useEffect(() => {
    if (explore_plans_click_count > 1) {
      loadExplorePlans();
      active('/')
    }

  }, [explore_plans_click_count]);

  return (
    <>


      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/"> <img className='logo' alt='Finnovate' src="https://www.finnovate.in/assets/images/logo.svg"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">

              <Link to={"/"} className={(current === '/' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/')}>Home</Link>

              { /* <Link to={"/explore-plans"} className={(current === '/explore-plans' ? 'active' : '') + " nav-item nav-link "} onClick={() => active('/explore-plans')}>Explore Plans</Link> */}
              {current !== '/' ?
                <Link onClick={() => loadExplorePlans()} className="nav-item nav-link">Explore Plans</Link> :
                <Link to={"/#explore_plans"} onClick={() => scrollDown()} className="nav-item nav-link">Explore Plans</Link>
              }
              <Link onClick={() => openScheduleCallPopup()} className=" nav-item nav-link">Schedule a Call</Link>
              {/* <Link  to={"/schedule-a-call"} className={(current === '/schedule-a-call' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/schedule-a-call')}>Schedule a Call</Link> */}
              {
                (localStorage.getItem("token") ?
                  <NavDropdown title={localStorage.getItem('name')} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/dashboard ">Dashboard</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
                  </NavDropdown>
                  :
                  <>
                    <Link to={"/login"} className={(current === '/login' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/login')}>Login</Link>
                    <Link to={"/register"} className={(current === '/register' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/register')}>Register</Link>
                  </>
                )

              }



            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/*}
      <nav className="navbar navbar-expand-lg _navbar-light _bg-light">
        <img className='logo' alt='Finnovate' src="https://www.finnovate.in/assets/images/logo.svg"></img>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">


            <Link to={"/"} className={(current === '/' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/')}>Home</Link>

          
            {current !== '/' ?
              <Link onClick={() => loadExplorePlans()} className="nav-item nav-link">Explore Plans</Link> :
              <Link to={"/#explore_plans"} onClick={() => scrollDown()} className="nav-item nav-link">Explore Plans</Link>
            }
            <Link onClick={() => openScheduleCallPopup()} className=" nav-item nav-link">Schedule a Call</Link>
           
            {
              (localStorage.getItem("token") ?
                <Link className={(current === '/logout' ? 'active' : '') + " nav-item nav-link"} onClick={() => logout()}>Logout</Link>
                :
                <>
                  <Link to={"/login"} className={(current === '/login' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/login')}>Login</Link>
                  <Link to={"/register"} className={(current === '/register' ? 'active' : '') + " nav-item nav-link"} onClick={() => active('/register')}>Register</Link>
                </>
              )

            }


          </div>
        </div>
      </nav>
            */}

    </>
  );
}

export default MyNavbar;
