import './CallBackComponents.css';
import { Card } from 'react-bootstrap';
import img from '../Img';
import { useForm } from "react-hook-form";
import SweetAlert from 'sweetalert2'
import http from './../../http';

function CallBackComponents(props) {

    const { register, handleSubmit, setError, formState: { errors } } = useForm(); // for register form

    const onSubmitCallBackForm = data => {

        const payload = data;

        http.post('/calls/add', payload).then((response) => {

            const resp = response.data;
            if (resp.success) {
                SweetAlert.fire(
                    'Success',
                    resp.message,
                    'success'
                );

            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });


    }

console.log(errors);
    return (
        <div className="video-call-section">
            <Card>
                <div className="video-img">
                    <img src={img.video_call.src} alt={img.video_call.src}></img>
                </div>
                <div className="text-center">
                    <h5>Get a free quotation & assistance </h5>
                    <span>Feel free to share your number, we won’t spam you with calls or messages</span>
                </div>
                <form onSubmit={handleSubmit(onSubmitCallBackForm)}>
                    <div className="mt-20 mb-20">
                        <input className="form-control" name="mobile" type="text" placeholder="Enter your phone number" maxLength="10" minLength="10"
                            {...register("mobile", {
                                 required: 'Phone Number is required'                               
                              })}></input>
                    </div>
                    <div className='mb-20'>
                        <span class={errors.mobile ? 'required' : ''}>
                            {errors.mobile && errors.mobile.message}
                        </span>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary btn-md">Call back</button>
                    </div>
                </form>

            </Card>
        </div>
    );


}
export default CallBackComponents;
