import { React, useEffect, useState } from 'react'
import { Card, Col, Row } from "react-bootstrap";
import { _number_format_with_icon , _number_format} from '../../funcations';
import img from '../Img';
import http from './../../http'

function PlanDetails(props) {

    
   
    const [get_quotes_list, setGetQuoteList] = useState([]);

    useEffect(() => {

        if (localStorage.getItem("token")) {
            const payload = {
                get_quote_id: localStorage.getItem('get_quote_id')
            }

            http.post('/get-quote-data', payload,
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setGetQuoteList(resp.data[0]);
                   
                } else {
                }
            }).catch(err => {
                const resp = err.response.data;
                console.log("Error: ", resp); 
            });;
        }
    }, []); 


    const [proposer, setProposerDetails] = useState({});
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }
    }, []);


    //let proposer = context.getProposerDetails;
    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });

    const package_img = (pakage_name) => {

        if (pakage_name === 'Bronze') {
            return img.bronze.src;
        }
        if (pakage_name === 'Silver') {
            return img.silver.src;
        }
        if (pakage_name === 'Gold') {
            return img.gold.src;
        }
    }

    const [gTotal_amounts, setGTotalAmounts] = useState(
        );
        useEffect(() => {
            setGTotalAmounts(
                parseFloat(get_quotes_list.base_premium) +
                parseFloat(get_quotes_list.opd_covered_premium_amounts) +
                parseFloat(get_quotes_list.deductible_total_amounts) +
                parseFloat(get_quotes_list.family_discount_total_amounts))
        }, [get_quotes_list]);

    return (
        <>
            <div className="pakage-summary-form">
                <Card >
                    <div className='header'>
                        <img src={package_img(get_quotes_list.package_name)} alt=""></img>
                        <h4> {get_quotes_list.package_name}</h4>
                        <small>Health insurance</small>
                    </div>
                    <br />
                    Details
                    <hr />
                    <Row>
                        <Col className="col-6"><img src={img.person.src} alt=""></img> <label> COVER AMOUNT</label></Col>
                        <Col className="col-6"><label>USD {_number_format(get_quotes_list.package_cover_amounts)}</label></Col>
                    </Row>
                    <Row>
                        <Col className="col-6"><img src={img.users.src} alt=""></img> <label> POLICY FOR</label></Col>
                        <Col className="col-6"><label>{ages_html}</label></Col>
                    </Row>
                    <Row>
                        <Col className="col-6"><img src={img.web_sm.src} alt=""></img> <label> Covergae AREA</label></Col>
                        <Col className="col-6"><label> {get_quotes_list.coverage_area}</label></Col>
                    </Row>

                    <Row className="mt-20">
                        <Col className="col-7"><h5>Premium</h5></Col>
                    </Row>
                    <Row className="mb-10">
                        <hr />
                        <Col className="col-7"><h4>Total</h4></Col>
                        <Col className="col-5"><b>{_number_format_with_icon(gTotal_amounts)}</b></Col>
                    </Row> 
                </Card>
            </div>
        </>
    )
}

export default PlanDetails;