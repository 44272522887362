import { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../../components/Img";
import http from '../../http'
import NoteContext from "../../context/notes/NoteContext";

import { _number_format_usd } from '../../funcations'

function GetQuotesList(props) {

    const context = useContext(NoteContext);


    const [get_quotes_list, setGetQuoteList] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/get-quote-data', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    
                    setGetQuoteList(resp.data);
                    if(props.last_quote && resp.data[0]){
                        setGetQuoteList([resp.data[0]]);
                    }
                    

                    if (props.setAllQuotes) {
                        props.setAllQuotes(resp.data);
                    }
                } else {
                }
            }).catch(err => {
                const resp = err.response.data;
                console.log("Error: ", resp);
                context.checkTokenStatus(resp);
            });;
        }
    }, []);



    const [proposer, setProposerDetails] = useState({});
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }
    }, []);


    //let proposer = context.getProposerDetails;
    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });



    const package_img = (pakage_name) => {

        if (pakage_name === 'Bronze') {
            return img.bronze.src;
        }
        if (pakage_name === 'Silver') {
            return img.silver.src;
        }
        if (pakage_name === 'Gold') {
            return img.gold.src;
        }
    }


    const loadPage = () => {
        if (props.page_load_count) {
            props.setPageLoadCount(props.page_load_count + 1);
        }
    }

    if (!localStorage.getItem('my_get_quote_list')) {
        localStorage.setItem("my_get_quote_list", [0]);
    }

    const getIcon = (icon) => {
        return icon === 'cancel' ? <img className="w15" src={img.cancel.src} alt=""></img> : <img className="w15" src={img.checked_fill.src} alt=""></img>

    }

    return (
        <>
          
            {

                get_quotes_list.map((item, index) => {
                    localStorage.setItem("my_get_quote_list", [localStorage.getItem('my_get_quote_list'), item.package_type_master_id]);
                    return (<>
                        <div className={props.className && props.className}>
                            <Card className="p-20">
                                <div className="row">
                                    <div className="col-md-8"> {props.last_quote && <small>Last Requested</small> } <h4>Quote Status</h4></div>
                                    <div className="col-md-4">
                                        <Link className="btn btn-success" to={"/get-quote-details?get_quote_id=" + item.get_quote_id} onClick={() => loadPage()}>View</Link>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <img src={package_img(item.package_name)} alt="" className="flot-left mr-20 w50"></img>
                                    <h4 class="">{item.package_name} <small>{'($ ' + _number_format_usd(item.package_cover_amounts) + ')'}</small></h4>
                                    <span className="font12">{item.coverage_area}</span>
                                </div>
                                <br/>
                                <strong>Add ons:</strong>
                                <div>
                                    <span className="mr-10">{item.opd_covered === 'yes' ? getIcon('checked_fill') : getIcon('cancel')}  OPD</span>
                                    <span className="mr-10"> {item.dental_and_vision === 'yes' ? getIcon('checked_fill') : getIcon('cancel')} Dental Cover</span>
                                    <span className="mr-10">{item.deductible > 0 ? getIcon('checked_fill') : getIcon('cancel')} Deductible</span>
                                </div>
                                <div className="row">
                                    <div> <span className="mr-15">Member: <b>{item.family_member_age_list.length}</b></span>
                                        <span>Approval Status:</span>
                                        <span className={"status_" + item.approval_status}> <b>{item.approval_status.toUpperCase()}</b></span>
                                    </div>
                                </div>
                                 <div className="right font12"><i>{item.created_at_2}</i></div>
                            </Card>
                        </div>
                    </>)
                })
            }
        </>
    )
}

export default GetQuotesList;