
import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import img from '../../Img';
import './ScheduleACall.css'
import ContactOnWhatsapp from '../ContactOnWhatsapp';

import noteContext from '../../../context/notes/NoteContext';


function ScheduleACall(props) {

  const context = useContext(noteContext);

  const [lgShow, setLgShow] = useState(context.getScheduleACall);

  const [calendlyPopup, setCalendlyPopup] = useState(false);

  useEffect(() => {
    //setCounter(counter+1);
    console.log("form ScheduleACall components ");
    setLgShow(context.getScheduleACall);
  }, [context]);

  // setLgShow(context.getScheduleACall);


  let url = "https://calendly.com/finnovate/financial-fitness-consultation";
  if (localStorage.getItem('name') && localStorage.getItem('name')) {
    url += "?name=" + localStorage.getItem('name') + "&email=" + localStorage.getItem('email');
  }

  const closePopup = () => {
    setLgShow(false);
    context.setScheduleACall(false);
  }

  return (
    <>
      <Button className='btn-lg mobile-full-width' onClick={() => setLgShow(true)}> <img src={img.phone.src} alt={img.phone.alt} />Schedule A Call</Button>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => closePopup(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className="image-section section-1">
              <img className='w-90' src={img.video_call_monochromatic.src} alt={img.video_call_monochromatic.alt}></img>
            </Col>
            <Col md={6} className="text-center section-2">
              <div className='icon text-center mb-20'>
                <img src={img.phone_2.src} alt={img.phone_2.alt} />
              </div>
              <div className='title text-center mb-25'>
                <h2>Schedule A Call</h2>
                <span>with our Insurance Agent</span>
              </div>
              <div className='text-center book-on-calendy mb-20'>
                {/*<a class="btn btn-primary btn-lg" href={url} target="_blank"><img src={img.calendar.src} alt={img.calendar.alt} /> Book On Calendy</a> */}

                <button class="btn btn-primary btn-lg" onClick={() => setCalendlyPopup(true)} 
                style={{padding: "16px 50px"}}>
                  <img src={img.calendar.src} alt={img.calendar.alt} /> Book On Calendy</button>
              </div>
              <span className=''>OR</span>
              <div className='whatsapp text-center  mt-20'>
                <ContactOnWhatsapp />
              </div>
            </Col>

          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={calendlyPopup}
        onHide={() => setCalendlyPopup(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <object type="text/html" data={url}
              width="800px" height="700px">
            </object>
          </Row>
        </Modal.Body>

      </Modal>

    </>
  );
}


export default ScheduleACall;