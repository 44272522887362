import { useState, useEffect } from "react";
import NoteContext from "./NoteContext";
import SweetAlert from 'sweetalert2'
import http from './../../http';


const NoteState = (props) => {
    const company = {
        "name": "finnovate",
        "address": "Mumbai",
        "pin": "400093"
    }

    const [login, SetLogin] = useState(false);
    const [formData, SetFormData] = useState({ id: 1092 });

    const TokenExpire = (bool) => {
      //  alert("Note State---");
        if (bool) {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("mobile");
            window.location = "/login";
        }
    }

    const checkTokenStatus = (response) => {
        //  console.log("checkTokenStatus: ", response);
        if (response.message === 'Unauthenticated.' || response.message === 'Token has expired') {
            SweetAlert.fire({
                icon: 'warning',
                title: "Error!",
                text: "Token expired or invalid. Please Re-Login and try again",
                type: "error"
            }).then(function () {
                localStorage.clear();
                window.location = "/login";
            });
        }
    }
    const [getQuoteP, setQuoteP] = useState(0);
    const getQuote = () => {
        if (localStorage.getItem("token")) {
            http.post('/get-quote-data', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    const p = resp.data[0].package_type_master_id; 
                   // console.log("recent pakage_id-> ", p);
                    setQuoteP(p);
                    localStorage.setItem('get_quote_pakage_id', p);
                } else {
                    // 
                }
            }).catch(err => {
                const resp = err.response.data;
                  checkTokenStatus(resp);
            });;
        }
    }

    const [getScheduleACall, setScheduleACall] = useState(false);

    const [getProposerDetails, setProposerDetails] = useState({});
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }
    }, []);

    const [packageLists, setPackagesList] = useState([]);
     useEffect(() => {
        if (localStorage.getItem("token")) {
            http.get('/masters-data/get-packages', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setPackagesList(resp.data);
                } else {
                    // 
                }
            }).catch(err => {
                const resp = err.response.data;
                 checkTokenStatus(resp);
            });
        }
    }, []);
 


    return (
        <NoteContext.Provider value={{
            TokenExpire: TokenExpire,
            company_info: company,
            login: login,
            SetLogin: SetLogin,
            formData: formData,
            SetFormData: SetFormData,
            checkTokenStatus: checkTokenStatus,
            getQuote: getQuote,
            getQuoteP: getQuoteP,
            getScheduleACall: getScheduleACall,
            setScheduleACall: setScheduleACall,
            getProposerDetails: getProposerDetails,
            packageLists: packageLists
            //  enrollmentFormStep: enrollmentFormStep,
            // setEnrollmentFormStep: setEnrollmentFormStep
        }}
        >
            {props.children}
        </NoteContext.Provider>
    );
}

export default NoteState;