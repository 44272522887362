import { useState, useEffect } from 'react';
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../components/Img";
//import GetQuote from "../../components/GetQuote";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Accordion from 'react-bootstrap/Accordion';

import { _number_format, _number_format_with_icon, get, _number_format_usd } from '../../funcations';
import http from './../../http';
//import SweetAlert from 'sweetalert2'

import GetQuotesList from './GetQuotesList';

function GetQuoteDetails(props) {
    const navigate = useNavigate();

    const [page_load_count, setPageLoadCount] = useState(1);

    const [get_quote_data, setQuoteDetailsData] = useState([]);
    const [x, setX] = useState(false);
    const [ages, setAges] = useState([]);
    const getQuoteDetailsData = () => {
        if (localStorage.getItem("token")) {


            http.post('/get-quote-data', { get_quote_id: get('get_quote_id') },
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    const data = resp.data[0];
                    setQuoteDetailsData(data);
                    if (data.approval_status === 'pending') {
                        setX(true);
                    } else {
                        setX(false);
                    }
                    setAges(data.family_member_age_list);
                }
            });
        }
    }


    useEffect(() => {
        getQuoteDetailsData();
        window.scrollTo(0, 0);
    }, [page_load_count]);

    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });


    const [key, setKey] = useState('About');

    const questions_obj = [
        {
            id: 1,
            ques: 'How can I buy the plan ?',
            ans: 'Ans  '
        },
        {
            id: 2,
            ques: 'How can I connect to Insurance Agent again ?',
            ans: 'ans...'
        },
        {
            id: 3,
            ques: 'How can I connect to Insurance Agent again ?',
            ans: 'ans...'
        },
        {
            id: 4,
            ques: 'Is good credit score necessary for this insurance ?',
            ans: 'ans...'
        }


    ];

    const package_img = (pakage_name) => {
        if (pakage_name === 'Bronze') {
            return img.bronze.src;
        }
        if (pakage_name === 'Silver') {
            return img.silver.src;
        }
        if (pakage_name === 'Gold') {
            return img.gold.src;
        }
    }


    const [gTotal_amounts, setGTotalAmounts] = useState(
    );
    useEffect(() => {
        localStorage.setItem('get_quote_id', get_quote_data.get_quote_id);
        setGTotalAmounts(
            parseFloat(get_quote_data.base_premium) +
            parseFloat(get_quote_data.opd_covered_premium_amounts) +
            parseFloat(get_quote_data.deductible_total_amounts) +
            parseFloat(get_quote_data.family_discount_total_amounts))
    }, [get_quote_data]);

    return (
        <>

            <Row>
                <div className="back mb-20">
                    <Link onClick={() => navigate(-1)}> <img src={img.arrow_left.src} alt=""></img> Back</Link>
                </div>
                <Col md={8}>

                    <Card className="pakage-card">
                        <Row className="pakage-header">
                            <Col md={8}>
                                <img src={package_img(get_quote_data.package_name)} alt="" className="flot-left mr-20"></img>
                                <h4>{get_quote_data.package_name}</h4>
                                <span>Health insurance</span>
                            </Col>
                            <Col md={4}>
                                <h4>Rs. {_number_format(gTotal_amounts, x)}</h4>
                                <span>Per Annum</span>
                            </Col>
                        </Row>

                        <Row className="p-3">
                            <Col md={12} className="font14 fw500">
                                <form id="client_form" name="client_form">
                                    <Row className="mb-12">
                                        <Col md={4} className=""> <img src={img.dollar.src} alt=""></img> Cover Amounts</Col>
                                        <Col md={8} className="left">USD {_number_format_usd(get_quote_data.package_cover_amounts)}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={4} className=""> <img src={img.users.src} alt=""></img> Policy For</Col>
                                        <Col md={8} className="">{ages_html}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={4} className=""> <img src={img.web_sm.src} alt=""></img>  Covergae Area</Col>
                                        <Col md={8} className="">{get_quote_data.coverage_area}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={6} className=""> <img src={img.stethoscope.src} alt=""></img>  Includes Dental and Vision </Col>
                                        <Col md={6} className="">{get_quote_data.dental_and_vision}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={6} className=""> <img src={img.rupee.src} alt=""></img>  OPD Covergae</Col>
                                        <Col md={6} className="">$ {_number_format_usd(get_quote_data.opd_covered_amounts)}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={6} className=""> <img src={img.rupee.src} alt=""></img>  Deductible</Col>
                                        <Col md={6} className="">$ {_number_format_usd(get_quote_data.deductible)}</Col>
                                    </Row>
                                </form>
                            </Col>

                        </Row>
                    </Card>

                    <div className="mt-20 key-benefits">
                        <h5> <img src={img.cil_badge.src} alt=""></img> Key Benefits</h5>
                        <Row>
                            <div className="col-md-4 col-sm-12 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.rupee.src} alt=""></img> <strong>Covers all In-Patient care</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>
                            <div className="col-md-4 col-sm-12 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.stethoscope.src} alt=""></img>  <strong>Out-Patient Treatment (OPD) </strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date</div>
                                </Card>
                            </div>
                            <div className="col-md-4 col-sm-12 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.person.src} alt=""></img>  <strong>Covered on Individual basis</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>
                            <div className="col-md-4 col-sm-12 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.web_sm.src} alt=""></img>  <strong>International Emergency Services</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>
                            <div className="col-md-4 col-sm-12 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.person2.src} alt=""></img>  <strong>Rehab & wellness</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>

                        </Row>
                    </div>
                    <div className='mt-30 mb-20'>
                        <h3><img src={img.info_green.src} alt=""></img> More Information</h3>
                    </div>
                    <Card className='mt-20 p-20'>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="About" title="About">
                                <h5>About</h5>
                                <p>Manipal in association with Finnovate is provide international health insurance which aims to provide you the best health insurance which is eligible to avail medical services worldwide. This insurance will enable you :</p>
                                <ul className='bullet-point-1'>
                                    <li>To get immediate hospitalization</li>
                                    <li>To avail air ambulance services</li>
                                    <li>To claim OPD expenses</li>
                                    <li>To claim dental expenses</li>
                                </ul>
                            </Tab>
                            <Tab eventKey="Coverage" title="Coverage">
                                <h5>Coverage</h5>
                            </Tab>
                            <Tab eventKey="Inclusions" title="Inclusions">
                                <h5>Inclusions</h5>
                            </Tab>
                            <Tab eventKey="Exclusions" title="Exclusions">
                                <h5>Exclusions</h5>
                            </Tab>
                            <Tab eventKey="ClaimProcess" title="Claim Process">
                                <h5>Claim Process</h5>
                            </Tab>

                        </Tabs>
                    </Card>

                    <div className='mt-30 mb-20'>
                        <h3><img src={img.question_green.src} alt=""></img> Commonly Asked Questions</h3>
                    </div>
                    <Card className='mt-20'>
                        <Accordion defaultActiveKey="0">

                            {
                                questions_obj.map((row) => {
                                    return (
                                        <Accordion.Item eventKey={row.id}>
                                            <Accordion.Header>Q.  {row.ques}</Accordion.Header>
                                            <Accordion.Body>
                                                {row.ans}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }

                        </Accordion>
                    </Card>

                </Col>
                <Col md={4} className="pakage-summary-form">
                    <Card >
                        Summary
                        <hr />
                        <Row>
                            <Col className="col-7"><label><small>Base premium</small> </label></Col>
                            <Col className="col-5 right"><label>{_number_format_with_icon(get_quote_data.base_premium, x)}</label></Col>
                        </Row>
                        <Row>
                            <Col className="col-7"><label> OPD Coverage</label></Col>
                            <Col className="col-5 right"><label>  {_number_format_with_icon(get_quote_data.opd_covered_premium_amounts, x)} </label></Col>
                        </Row>
                        <Row>
                            <Col className="col-7"><label>Deductible ({get_quote_data.deductible_percentage}%)</label></Col>
                            <Col className="col-5 right"><label> {_number_format_with_icon(get_quote_data.deductible_total_amounts, x)}</label></Col>
                        </Row>
                        <Row className="mt-20">
                            <Col className="col-7"><label><small>Family Discount ({get_quote_data.family_discount_percentage} %)</small></label></Col>
                            <Col className="col-5 right"><label>  {_number_format_with_icon(get_quote_data.family_discount_total_amounts, x)}</label></Col>
                        </Row>
                        <div><hr /></div>
                        <Row className="mt-20 mb-20">
                            <Col className="col-6"><h5>Total</h5></Col>
                            <Col className="col-6 right"> <b>{_number_format_with_icon(gTotal_amounts, x)}</b> </Col>
                        </Row>
                        <div className=''>
                            {
                                (get_quote_data.approval_status === 'approved') ?
                                    <Link to={'/insurance-application-status?get_quote_id=' + get('get_quote_id')} className="btn btn-lg btn-primary full-width">Buy Now  <img src={img.arrow_right.src} alt=""></img></Link> :
                                    <Button type="button" variant="secondary" disabled className="btn btn-lg desabled full-width">Buy Now  <img src={img.arrow_right.src} alt=""></img></Button>


                            }

                        </div>

                    </Card>
                    <br />
                    <GetQuotesList setPageLoadCount={setPageLoadCount} page_load_count={page_load_count} />
                </Col>

            </Row>
        </>
    )
}

export default GetQuoteDetails;