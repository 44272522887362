import { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import img from "../../../components/Img";
import { get } from './../../../funcations'

import http from "../../../http";
import { Link } from "react-router-dom";

function DobOfPepopleInclude(props) {

  props.setShortTitle('Mention DOB’s of all insured persons');
  props.setLongTitle('Let’s check the age of all insured');

  // eslint-disable-next-line
  const [_proposerData, setProposerData] = useState(props.proposerData);

  if (props.proposerData === null) {
    setProposerData({});
  }
  
 // console.log("_proposerData::", _proposerData);

  const [spouse_dob, setSpouseDob] = useState();

  const [son_dob, setSonDob] = useState([]);
  const [daughter_dob, setDaughterDob] = useState([]);

  useEffect(() => {
    console.log("_proposerData::line 30 :::", props.proposerData);
    if (props.proposerData && _proposerData.members) {
      _proposerData.members.map((member) => {
        if (member.relationship === 'spouse') {
          setSpouseDob(member.date_of_birth)
        }
        if (member.relationship === 'son') {
          setSonDob(son_dob => [...son_dob, member.date_of_birth]);
        }
        if (member.relationship === 'daughter') {
          setDaughterDob(daughter_dob => [...daughter_dob, member.date_of_birth]);
        }

      });
    }
  }, [props, _proposerData])

  // console.log(props.formData1);
  //console.log("son_dob:: ", son_dob);



  let newDate = new Date()
  let current_date = newDate.toISOString().substring(0, 10);

  let members = [];

  let icon_self = img.man.src;
  let icon_spouse = img.woman.src;
  if (props.formData1.gender === 'female') {
    icon_self = img.woman.src;
    icon_spouse = img.man.src;
  }

  if (props.formData1.self === true) {
    members.push(
      <Col className="col-md-12 dob-list">
        <div className="input-area">
          <img src={icon_self} alt=''></img>
          <span>Self</span>
          <input type="date" name={`dob[self]`} max={current_date} defaultValue={(props.proposerData !== null ? _proposerData.date_of_birth : '')} />
        </div>
      </Col>
    );
  }

  if (props.formData1.spouse === true) {
    members.push(
      <Col className="col-md-12 dob-list">
        <div className="input-area">
          <img src={icon_spouse} alt=''></img>
          <span>Spouse</span>
          <input type="date" name={`dob[spouse]`} max={current_date} defaultValue={spouse_dob} />
        </div>
      </Col>
    );
  }

  for (let i = 1; i <= props.formData1.son; i++) {
    members.push(
      <Col className="col-md-12 dob-list">
        <div className="input-area">
          <img src={img.son.src} alt=''></img>
          <span>Son {i}</span>
          <input type="date" name={`dob[son][${i}]`} max={current_date} defaultValue={son_dob[(i - 1)]} />
        </div>
      </Col>
    );
  }

  for (let i = 1; i <= props.formData1.daughter; i++) {
    members.push(
      <Col className="col-md-12 dob-list">
        <div className="input-area">
          <img src={img.daughter.src} alt=''></img>
          <span>Daughter {i}</span>
          <input type="date" name={`dob[daughter][${i}]`} max={current_date} defaultValue={daughter_dob[(i - 1)]} />
        </div>
      </Col>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    let serialize = require('form-serialize');
    let form = document.querySelector('#my_form');

    //let str = serialize(form);
    // console.log(str);

    let form_data = serialize(form, { hash: true });
    //console.log(form_data);

    http.post('/family-members/add', form_data,
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response2) => {
      const resp = response2.data;
      if (resp.success) {
        props.setFormData2(
          { ...form_data }
        );
        props.setStep(3);
      } else {
        alert(resp.message);
      }
    });


  }

  const goBack = () => {
    console.log(props.formData1);
    props.setStep(1);
  }

  return (
    <>
      <form onSubmit={handleSubmit} id="my_form">
        <input type="hidden" name="action" value={get('action')} />
        <input type="hidden" name="self_gender" value={props.formData1.gender} className="form-control" placeholder="Eg Satish Kaushik ..." />

        <Row>

          <div className="col-md-12 mt-20">
            <label className="form-label">Full name (Proposer)</label>
            <input type="text" name="full_name" defaultValue={(props.proposerData !== null ? _proposerData.proposer_name : '')} className="form-control" placeholder="Eg Satish Kaushik ..." />

          </div>

          <div className="col-md-12 p-20">
            <h5 className="bold">Date of birth of people insured</h5>
          </div>

        </Row>
        <Row className="dob-section">

          {members}

        </Row>
        <Row className="continue-btn-section">
          <Col className="col-md-4">
            <input
              onClick={() => goBack()}
              type="button" className="full-width btn btn-outline-primary btn-lg" value="Back" />
          </Col>
          <Col className="col-md-8">
            <input type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
          </Col>
          <br />
          {(get('action') === 'edit' && <Link className="right" to="#" onClick={() => { props.setStep(3) }}>Skip Without Changes</Link>)}

        </Row>
      </form>
    </>
  );
}

export default DobOfPepopleInclude;
