import './Timeline.css';

function Timeline (props) {
    return (
        <>
            <div className="wrapper">

                <div className="center-line"></div>

                <div className="row row-2">
                <section>
                    <i className="icon fas fa-home">1</i>
                    <div className="details">
                    <span className="title">Worldwide Global Health Coverage</span> 
                    </div>
                    <p>Worldwide global health
                        coverage with Higher Sum
                        Insured options from USD
                        250,000 to USD 1 Million
                        ensuring no compromise
                        when it comes to your
                        health protection.</p>
                    
                </section>
                </div>

                <div className="row row-2">
                <section>
                    <i className="icon fas fa-star">2</i>
                    <div className="details">
                    <span className="title">Cashless Access</span> 
                    </div>
                    <p>Cashless access to
                        best-in-class global
                        medical provider network</p>
                    
                </section>
                </div>

                <div className="row row-2">
                <section>
                    <i className="icon fas fa-rocket">3</i>
                    <div className="details">
                    <span className="title">Extremely Convenient and  Seamless On-boarding experience</span> 
                    </div>
                    <p>Extremely convenient and
                        seamless on-boarding
                        experience, no requirement
                        of pre policy medical
                        check-up till Age 50</p>
                    
                </section>
                </div>

                <div className="row row-2">
                    <section>
                        <i className="icon fas fa-globe">4</i>
                        <div className="details">
                        <span className="title">Exhaustive cover for Outpatient</span> 
                        </div>
                        <p>Exhaustive cover for
                            Outpatient Care including
                            consultations, prescribed
                            drugs, diagnostics and
                            wellness programs</p>
                        
                    </section>
                </div>  

            </div>
        </>
    );
}

export default Timeline;