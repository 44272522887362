import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";

import img from "../../components/Img";
import './ComparePlans.css';
import GetQuote from '../../components/GetQuote';
import http from './../../http'

//import { packages_list_filter } from './../../funcations';


function ComparePlans() {
    const navigate = useNavigate();

    const [proposer, setProposerDetails] = useState({});
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }
    }, []);
    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });

    const yes = <img src={img.check.src} alt=""></img>;
    const no = <img src={img.cancel.src} alt=""></img>;



    return (
        <>
            <Row>
                <Col md={12}>
                    <div className="back">
                        <Link onClick={() => navigate(-1)}> <img src={img.arrow_left.src} alt=""></img> Back </Link>
                    </div>
                </Col>
            </Row>
            <Card>
                <div className="row"> 
                        <div className="col-md-5">
                            <div className="row" style={{padding: "10px"}}>
                                <Col md={4} className="p-2"> <img src={img.users.src} alt=""></img> POLICY FOR</Col>
                                <Col md={8} className="p-2">{ages_html} {/* Self (22yrs), Spouse (24yrs), Son (2yrs), Daughter ({"<1yrs"}) */}</Col>
                                <Col md={5} className="p-2"> <img src={img.web_sm.src} alt=""></img>  Covergae AREA</Col>
                                <Col md={7} className="p-2">{proposer.coverage_area}</Col>
                            </div>
                            <p> <Link to={'/client-requirements?action=edit'} className="btn btn-link btn-md"><img src={img.edit.src} alt=""></img> Edit Plan</Link></p>
                        </div>
                        <div className="col-md-7">
                            <table className="header-table" style={{ 'width': '100%' }}>
                                <tr className="center">
                                    <td>
                                        <p className="center"><img src={img.warning_red.src} alt=""></img></p>
                                        <h5>Bronze</h5>
                                        <GetQuote package_id={1} className="btn btn-primary">Get Quote</GetQuote>
                                    </td>
                                    <td>
                                        <p className="center"><img src={img.warning_gray.src} alt=""></img></p>
                                        <h5>Silver</h5>
                                        <GetQuote package_id={2} />

                                        <p className="center mt-10"><button className="btn btn-success btn-sm btn-round">Recmended</button></p>
                                    </td>
                                    <td>
                                        <p className="center"><img src={img.warning_yellow.src} alt=""></img></p>
                                        <h5>Gold</h5>
                                        <GetQuote package_id={3} />
                                    </td>
                                </tr>
                            </table>
                        </div> 
                </div>
          
                <table className="table table-bordered">
                    <thead className="thead-light">
                        <tr className="center th-heading" style={{ 'width': '100%' }}>
                            <th style={{ 'width': '40%' }} className="left">Plan Options</th>
                            <th style={{ 'width': '20%' }}>Bronze Plan</th>
                            <th style={{ 'width': '20%' }}>Silver Plan</th>
                            <th style={{ 'width': '20%' }}>Gold Plan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Maximum Overall Base Sum Insured  (in USD)</td>
                            <td className="center">250,000</td>
                            <td className="center">500,000</td>
                            <td className="center">1,000,000</td>
                        </tr>
                        <tr>
                            <td>Choice of Area of Covers</td>
                            <td className="center">Bronze Plan</td>
                            <td className="center">Silver Plan</td>
                            <td className="center">Gold Plan</td>
                        </tr>
                        <tr>
                            <td>Worldwide Excluding USA & Canada </td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>

                        <tr>
                            <td>Worldwide Including USA & Canada,</td>
                            <td className="center">{no}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>

                        <tr>
                            <td>Asia Pacific including Hong Kong & Singapore</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                    </tbody>
                </table>


                <table className="table table-bordered">
                    <thead className="thead-light">
                        <tr className="center th-heading" style={{ 'width': '100%' }}>
                            <th style={{ 'width': '40%' }} className="left">In-Patient hospitalization & Day care Benefits</th>
                            <th style={{ 'width': '20%' }}>Bronze Plan</th>
                            <th style={{ 'width': '20%' }}>Silver Plan</th>
                            <th style={{ 'width': '20%' }}>Gold Plan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Hospital accommodation (No room rent capping, Room category as per opted plan)</td>
                            <td className="center" colSpan="2">In India: No Capping on Room Rent Out of India: Any Hospital Room up to Private Room</td>
                            <td className="center">In India:No Capping on Room Rent Out of India: Any Hospital Room up to Private Room</td>
                        </tr>
                        <tr>
                            <td>Intensive care / high dependency unit</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td>In-Parient Specialists’ consultation / diagnostic tests / Prescription drugs & materials / Surgical appliances & fees, including anaesthesia and theatre charges / Physician and therapist fees etc.</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td>Organ Transplant </td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Psychiatric & Psychological Care</b> <br />(In-patient)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>

                        <tr>
                            <td><b>Parental Accommodation</b> (One parent staying in hospital with an insured child under 18)</td>
                            <td className="center">1,000</td>
                            <td className="center">1,000</td>
                            <td className="center">1,000</td>
                        </tr>
                        <tr>
                            <td><b>Kidney dialysis</b><br />(in the location of assignment, patient country of domicile or centre of excellence nearest the location of assignment)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Home Nursing</b> <br />(Max Up to 30 days / Sub limit of benefit)</td>
                            <td className="center">{no}</td>
                            <td className="center">{no}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Reconstructive Surgery </b> <br />(following an accident or surgery for an eligible condition as per plan benefits)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Private Surface Ambulance</b></td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Local Air ambulance </b> <br />(Up to USD, when operated under an air ambulance license, Payable for within territorial boundaries of India, On pre-approval and reimbursement basis only)</td>
                            <td className="center">10,000</td>
                            <td className="center">10,000</td>
                            <td className="center">15,000</td>
                        </tr>
                        <tr>
                            <td><b>Short Term Rehabilitation</b> <br />(For therapies, cardiac & pulmonary rehab after a trauma inducing event, that the same begins within 30 days of the end of that original treatment)</td>
                            <td className="center">Up to 2,500; No. of days: 30</td>
                            <td className="center">Up to 2,500; No. of days: 30</td>
                            <td className="center">Up to 5,000; No. of days: 45</td>
                        </tr>
                        <tr>
                            <td><b>Hospital Cash Benefit / Day </b><br />(Max Upto 30 days per day for In-Patient treatment)</td>
                            <td className="center">100</td>
                            <td className="center">100</td>
                            <td className="center">100</td>
                        </tr>
                        <tr>
                            <td><b>Robotics & Cyber Knife Surgery/ Stem Cells Theraphy </b><br />(Special Conditions will apply)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>AIDS/HIV Treatment</b></td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Hospice and Palliative Care</b></td>
                            <td className="center">10,000</td>
                            <td className="center">10,000</td>
                            <td className="center">10,000</td>
                        </tr>

                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead className="thead-light">
                        <tr className="center th-heading" style={{ 'width': '100%' }}>
                            <th style={{ 'width': '40%' }} className="left">International Emergency Services</th>
                            <th style={{ 'width': '20%' }}>Bronze Plan</th>
                            <th style={{ 'width': '20%' }}>Silver Plan</th>
                            <th style={{ 'width': '20%' }}>Gold Plan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b>*Emergency Evacuation</b><br />(to be taken to the nearest hospital where the necessary treatment is available (International boundaries ONLY and with prior Approval) </td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>*Medical Repatriation</b> <br /> (Return to India only)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>*Repatriation of Mortal Remains </b><br />(Return to India)</td>
                            <td className="center" colSpan="3">100 (5,000 / 10,000 ); 150 (25,000)</td>
                        </tr>
                        <tr>
                            <td><b>Limit of Scope & Area of Coverage for OPD</b></td>
                            <td className="center" colSpan="3">
                                Area of Cover - India, Scope of cover - Pre & Post Hospitalisation upto OPD Sum Insured <br />
                                -------------<br />
                                Area of cover - Outside India, Scope of cover - Coverage includes,<br />
                                (i) Consultations with Medical Practitioners & Specialist<br />
                                (ii) Prescribed Medicines, Drugs, Dressings<br />
                                (iii) Diagnostic tests Up to OPD Sum Insured<br />
                            </td>
                        </tr>
                        <tr>
                            <td><b>Diagnostic tests / Out-Patient Surgery / Doctor consultation fees / Prescribed drugs & Dressings charges</b>(as per Overall OPD limit & scope of cover for applicable AOC)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Non Surgical & Minor surgical treatment</b></td>
                            <td className="center">1,000</td>
                            <td className="center">1,000</td>
                            <td className="center">1,000</td>
                        </tr>
                        <tr>
                            <td><b>Complementary Treatments: </b><br />(Physiotherapy / Osteopathy / chiropractic / Acupuncture / AYUSH/ Homeopathy treatment (Part of overall OPD Limit)</td>
                            <td className="center">{no}</td>
                            <td className="center">{no}</td>
                            <td className="center">Benefit Limit (Overall OPD Limit) 1,000 (5,000); 1,500 (10,000); 2,000 (25,000) No of Visits - 30</td>
                        </tr>
                        <tr>
                            <td><b>Travel vaccinations </b><br /> (Part of overall OPD Limit)</td>
                            <td className="center" colSpan="3">Benefit Limit (Overall OPD Limit) 100 (5,000); 250 (10,000 / 25,000)</td>
                        </tr>
                        <tr>
                            <td><b>Prescribed speech therapy and occupational therapy </b><br />(As per Scope of Cover & Part of Overall OPD Limit)</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                            <td className="center">{yes}</td>
                        </tr>
                        <tr>
                            <td><b>Psychiatric & Psychological Care </b><br />(on Out - patient, post 12 months waiting period & Part of Overall OPD Limit)</td>
                            <td className="center">{no}</td>
                            <td className="center">{no}</td>
                            <td className="center">Benefit Limit (Overall OPD Limit) NIL (5,000); 500 (10,000); 1,000 (25,000)</td>
                        </tr>
                        <tr>
                            <td><b>Prescribed Health Appliances </b> <br />(e.g. CPAP device, hearing aids, infusion pump, digital foetal monitor etc. as prescribed by the Specialist & Part of Overall OPD Limit)</td>
                            <td className="center" colSpan="3">Benefit Limit (Overall OPD Limit) 750 (5,000); 1000 (10,000; 25,000)</td>
                        </tr>
                        <tr>
                            <td><b>International Wellness </b><br />(Routine Health and well being check-up including screening for the early detection of illness or disease, Child Immunisation etc. and is part of Overall OPD Limit)</td>
                            <td className="center" colSpan="3">Benefit Limit (Overall OPD Limit) 750 (5,000); 1,000 (10,000; 25,000)</td>
                        </tr>
                    </tbody>
                </table>


                <table className="table table-bordered">
                    <thead className="thead-light">
                        <tr className="center th-heading" style={{ 'width': '100%' }}>
                            <th style={{ 'width': '40%' }} className="left"><b>Optional Covers</b> (over and above base sum insured</th>
                            <th style={{ 'width': '20%' }}>Bronze Plan</th>
                            <th style={{ 'width': '20%' }}>Silver Plan</th>
                            <th style={{ 'width': '20%' }}>Gold Plan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b>Vision Overall Limit</b></td>
                            <td className="center">{no}</td>
                            <td className="center">150</td>
                            <td className="center">250</td>
                        </tr>
                        <tr>
                            <td><b>Mandatory Deductible </b> (in Vision cover overall limit)</td>
                            <td className="center">{no}</td>
                            <td className="center">50</td>
                            <td className="center">75</td>
                        </tr>
                        <tr>
                            <td><b>Dental Overall Limit</b></td>
                            <td className="center">{no}</td>
                            <td className="center">500</td>
                            <td className="center">750</td>
                        </tr>
                        <tr>
                            <td><b>Optional Deductible Options</b> <br /> (On annual aggregate basis at family level)</td>
                            <td className="center" colSpan="3">NIL/5,000 / 10,000 / 15,000</td>
                        </tr>

                    </tbody>
                </table>
            </Card>



        </>
    )
}

export default ComparePlans;