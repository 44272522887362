
import './assets/fonts/Inter/static/Inter-Black.ttf';
import './assets/fonts/Inter/static/Inter-Bold.ttf';
import './assets/fonts/Inter/static/Inter-ExtraBold.ttf';
import './assets/fonts/Inter/static/Inter-ExtraLight.ttf';
import './assets/fonts/Inter/static/Inter-Light.ttf';
import './assets/fonts/Inter/static/Inter-Medium.ttf';
import './assets/fonts/Inter/static/Inter-Regular.ttf';
import './assets/fonts/Inter/static/Inter-SemiBold.ttf';
import './assets/fonts/Inter/static/Inter-Thin.ttf';
import './assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf';

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/Footer.css';
//import './assets/css/dashboard.css';
import './assets/css/Global.css';
import './assets/css/Mobile.css';
import RouterPage from './RouterPage';
import MyNavbar from './components/layouts/MyNavbar';
//import MyNavbar2 from './components/layouts/MyNavbar2';
import Footer from './components/layouts/Footer';

import { useState, useRef } from 'react';

import NoteState from './context/notes/NoteState';
 


function App() {
  const [current, setCurrect] = useState('/');
  const active_page_fun = (para) => {
    setCurrect(para);
    console("current page is: " + current);
  }

  const scollToExplorePlan = useRef();
  
  return (
    <>
    
      <NoteState>
        <div className='App'>

          <div className='headerWpr mainwrapper'>
            <div className='innercontainer'>
              <MyNavbar active_page_fun={active_page_fun} scollToExplorePlan={scollToExplorePlan} />
            </div>
          </div>

          <div>
            <div className="innercontainer mb40 app container">
              <div className='mainwrapper'>
                <div className='content-data'>
                  <RouterPage scollToExplorePlan={scollToExplorePlan} />
                </div> 
                <Footer />
              </div>
            </div>
          </div>

        </div>
      </NoteState>
    </>

  );
}

export default App;

