import { Link } from 'react-router-dom';
import './PricingCard.css';
import img from '../Img'; 

function PricingCard(props) {

  const style = {
    'list-style-image': 'url(' + img.correct_gray.src + ')'
  }

  const package_img = (pakage_name) => {
    if (pakage_name === 'Bronze') {
      return img.bronze.src;        
    }
    if (pakage_name === 'Silver') {
        return img.silver.src;
    }
    if (pakage_name === 'Gold') {
      return img.gold.src;
    }
}

  return (
    <div className={(props.recommended === 1 ? ' active ' : '') + " pricing-card"}>
      <div className='icon text-center mb-15'><img src={package_img(props.name)} alt=""></img></div>
      <div className='name text-center mb-15'><strong>{props.name + " PACKAGE" } </strong></div>
      <div className='interval text-center mb-15'><span>{props.interval}</span></div>
      <div className='save-button text-center mb-15'>
        {
          props.recommended === 1 && (
            <Link to={"/plan-details?id=" + props.package.id} className="btn btn-success btn-sm mr-10">Recommended</Link> )
        }
        <Link to={"/plan-details?id=" + props.package.id} className="btn btn-warning btn-sm">{props.save}</Link>

      </div>
      <div className='feature-list'>
        <ul style={style}>
          {
            props.package_points.map((item, index) => {
              return <li>{item}</li>
            })
          }
        </ul>

      </div>
    </div>
  );
}
export default PricingCard;
