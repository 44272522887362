 
import './BoxEasyStep.css'
 
  function BoxEasyStep(props ) {  
    return (
      <div className= { 'box essay-step ' + props.class} >
          <img src={'./assets/images/icons/'+props.icon+'.png'} alt='+'></img> 
          <p>{props.text}</p>
      </div>
    );
  }


  export default BoxEasyStep;

