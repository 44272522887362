import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import img from "../../../components/Img";
import Select from 'react-select';
//import { Link } from "react-router-dom";


function PeopleCoveredOption(props) {

  props.setShortTitle('Details of all Insured persons');
  props.setLongTitle('Select family members to be covered');


  const [icon1, setIcon1] = useState(img.me.src);
  const [icon2, setIcon2] = useState(img.wife.src);


  const [self, setSelf] = useState(false);
  const [spouse, setSpouse] = useState(false);
  const [son, setSon] = useState(false);
  const [son_count, setSonCount] = useState(0);
  const [daughter, setDaughter] = useState(false);
  const [daughter_count, setDaughterCount] = useState(0);



  const [memberCounter, setMemberCount] = useState();

  const member_cal = () => {
    let c = 0;
    c += (self ? 1 : 0);
    c += (spouse ? 1 : 0);

    if (daughter === false ? setDaughterCount(0) : '');
    if (son === false ? setSonCount(0) : '');

    c += son_count;
    c += daughter_count;

    setMemberCount(c);
  }
  // eslint-disable-next-line
  const [_proposerData, setProposerData] = useState([]);

  useEffect(() => {
    // EDIT PROPOSER DATA  
    if (props.proposerData !== null) {

      setProposerData(props.proposerData);

      setSelectedValue(_proposerData.gender); // set existing gender 
      if (_proposerData.gender === 'male') {
        setIcon1(img.me.src);
        setIcon2(img.wife.src);
      } else {
        setIcon2(img.me.src);
        setIcon1(img.wife.src);
      }
      setSelf(true);
      setSpouse(true);
      setChildControls();
    }

  }, [props, _proposerData]);


 // console.log("proposerData:::::]]]]]] ", _proposerData);

  const setChildControls = () => {
    if (props.proposerData !== null) {

      setTimeout(() => {
        if (_proposerData) {
          let d = 0; let s = 0;
          _proposerData.members.map((member) => {
            if (member.relationship === 'son') {
              s += 1;
              setSon(true);
            }
            if (member.relationship === 'daughter') {
              d += 1;
              setDaughter(true);
            }
          });

          setSonCount(s);
          setDaughterCount(d);
        }
      }, 1000)
    }


  }





  useEffect(() => {


    if (props.formData1.gender) {

      const family = {
        ...props.formData1
      }
      //alert(props.formData1.daughter);
      setSelf(family.self);
      setSpouse(family.spouse);

      setSon(family.son ? true : false);
      setDaughter(family.daughter ? true : false);

      setSonCount(family.son);
      setDaughterCount(family.daughter);

      setSelectedValue(family.gender);
      props.setFormData1({});

    }

    /*
      if(son_count > 0){
        setSon(true);
      } 
  
      if(daughter_count >0){
        setDaughter(true);
      } 
    */

    if (son === true && son_count === 0) {
      setSonCount(1);
    }
    if (daughter === true && daughter_count === 0) {
      setDaughterCount(1);
    }


    member_cal();




  });

  const handleSubmit = (event) => {
    event.preventDefault();
    props.setFormData1(
      {
        gender: selectedValue,
        self: self,
        spouse: spouse,
        son: son_count,
        daughter: daughter_count
      }
    );
    console.log(props.formData1);
    if (memberCounter > 6) {
      alert("Can't add members beyond 6 members");
    } else {
      props.setStep(2);
    }

  }





  const data = [
    {
      value: '',
      label: '-- Select --'
    },
    {
      value: 'male',
      label: "Male"
    },
    {
      value: 'female',
      label: "Female"
    }
  ];

  // set value for default selection
  const [selectedValue, setSelectedValue] = useState('');


  // handle onChange event of the dropdown
  const handleChange = e => {
    setSelectedValue(e.value);
    var val = e.value;
    if (val === 'male') {
      setIcon1(img.me.src);
      setIcon2(img.wife.src);
    } else {
      setIcon2(img.me.src);
      setIcon1(img.wife.src);
    }
    //alert(e.value);
  }

  const sonControl = (type) => {
    if (type === 'plus') {
      if (son_count === 0) {
        setSon(true);
      }
      setSonCount(son_count + 1);
    }
    if (type === 'minus') {
      setSonCount(son_count - 1);
    }
  }

  const daughterControl = (type) => {
    if (type === 'plus') {
      if (daughter_count === 0) {
        setDaughter(true);
      }
      setDaughterCount(daughter_count + 1);
    }
    if (type === 'minus') {
      setDaughterCount(daughter_count - 1);
    }

  }


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="people-covered-option">
          {memberCounter > 6 && (
            <p><span className="required">Can't add members beyond 6 members</span></p>
          )}
          <Row>
            <Col className="col-md-8 option">
              <div className="gender-div form-group">
                <label className="flot-left right mr-20">I am </label>
                <div className=" col-md-12">
                  <Select
                    name="gender"
                    style={{ 'width': '200px' }}
                    placeholder="-- Select --"
                    value={data.find(obj => obj.value === selectedValue)} // set selected value
                    options={data} // set list of the data
                    defaultValue="male"
                    onChange={handleChange} // assign onChange function 
                  />
                </div>
              </div>
            </Col>
            <Col className="col-md-6 option">
              <label>
                <div className={(self === true ? 'active' : '') + " person"} >
                  <img src={icon1} alt=''></img>
                  <span>Self</span>
                  <input type="checkbox" name="self" onClick={() => { setSelf(old => !old) }} />
                </div>
              </label>
            </Col>
            <Col className="col-md-6 option">
              <label>
                <div className={(spouse === true ? 'active' : '') + " person"} >
                  <img src={icon2} alt=''></img>
                  <span>Spouse</span>
                  <input type="checkbox" name="spouse" onClick={() => { setSpouse(old => !old) }} />
                </div>
              </label>
            </Col>
            <Col className="col-md-6 option">
              <label className="children">
                <div className={(daughter === true ? 'active' : '') + " person"} >
                  <img src={img.daughter.src} alt=''></img>
                  <span>Daughter</span>
                  <input type="checkbox" name="daughter" onClick={() => { setDaughter(old => !old) }} />
                </div>
              </label>
              <span className="quantity">
                <i onClick={() => { daughterControl('minus') }}> - </i>
                <input type="number" name="daughter_count" value={daughter_count} min="0" max="4" onChange={() => { setDaughterCount(daughter_count) }}></input>
                <i onClick={() => { daughterControl('plus') }}> + </i>
              </span>
            </Col>
            <Col className="col-md-6 option">
              <label className="children">
                <div className={(son === true ? 'active' : '') + " person"} >
                  <img src={img.son.src} alt=''></img>
                  <span>Son</span>
                  <input type="checkbox" name="son" onClick={() => { setSon(old => !old) }} />
                </div>
              </label>
              <span className="quantity">
                <i onClick={() => { sonControl('minus') }}> - </i>
                <input type="number" name="son_count" value={son_count} min="0" max="4" onChange={() => { setSonCount(son_count) }} ></input>
                <i onClick={() => { sonControl('plus') }}> + </i>
              </span>
            </Col>
          </Row>

          <Row className="continue-btn-section">
            <Col className="col-md-12">
              <input type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
}

export default PeopleCoveredOption;
