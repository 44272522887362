import { useState, useEffect } from "react";

import { Row, Col, Card, Form } from "react-bootstrap";
import FormHeaderSection from "./FormHeaderSection.jsx";
import img from "../../components/Img.jsx";
import http from "../../http.jsx";

import SweetAlert from 'sweetalert2'

import OrderCardDisplay from './../../components/widgets/OrderCardDisplay'



function PlanDetails(props) {

    const [area, setArea] = useState([]);
    useEffect(() => {
        http.get('/masters-data/get-coverage-area', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                setArea(resp.data);
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });

    }, [])

    const [pakages, setPakages] = useState([]);
    useEffect(() => {
      http.get('/masters-data/get-packages', [],
        { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
      ).then((response) => {
        const resp = response.data;
        if (resp.success) {
          setPakages(resp.data);
        } else {
          SweetAlert.fire(
            'Error!',
            resp.message,
            'error'
          );
        }
      });
      getPlanDetails();
    }, [])


const [plan_details, setPlanDetails] = useState({});
const getPlanDetails =()=> {
    http.post('/enrollment-form/get-plan-details', [],
        { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
        const resp = response.data;
        if (resp.success) {
            const plan_details = resp.data;
            setPlanDetails(plan_details);
            document.getElementById('policy_tenure').value = plan_details.policy_tenure;
        } else {
            alert(resp.message);
        }
    });
}

    const handleSubmit = (event) => {
        event.preventDefault();

        let serialize = require('form-serialize');
        let form = document.querySelector('#plan_details_form');
        let form_data = serialize(form, { hash: true });
        console.log("plan_details_form: data - ", form_data);

        http.post('/enrollment-form/save-plan-details', form_data,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {

            const resp = response.data;
            if (resp.success) {
                SweetAlert.fire(
                    'Success',
                    'Plan Details Data Updated Successfully.',
                    'success'
                );
                props.setStep('HealthQuestionnaire');
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
                console.log(resp.message);
            }

        });
    }


const [sum_insured, setSumInsured] = useState();

    return (
        <>
            <Form onSubmit={handleSubmit} id="plan_details_form">
                <FormHeaderSection title="Plan Details" sub_title="Fill out the basic details for the people" step={props.step} setStep={props.setStep} />

                <div className="proposer-details form-section">
                    <Row>
                        <Col md={7}>
                            <div className="section-1">
                                <Row>
                                    <Col md={6} className="form-group">
                                        <label for="policy_tenure">Policy Tenure</label>
                                        <select className="form-control disabled" name="policy_tenure" id="policy_tenure">
                                            <option value="">-- Select --</option>
                                            <option value={1}>01 Year</option> 
                                        </select>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="coverage_type">Coverage type</label>
                                        <select className="form-control disabled" name="coverage_type" id="coverage_type"  value={plan_details.coverage_type}>
                                            <option value="">-- Select --</option>
                                            <option value="individual" selected>Individual</option>
                                            <option value="family">Family</option>
                                        </select>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="plan_name">Plan Name</label> 
                                        <select className="form-control disabled" name="plan_name" id="plan_name"  value={plan_details.sum_insured}>
                                            <option value="">-- Select --</option>
                                            {
                                                pakages.map((item, index) => { 
                                                    return (<option value={item.id}>{item.name}</option>)
                                                })
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <label for="sum_insured">Sum Insured </label>                                        
                                        <select className="form-control disabled" name="sum_insured" id="sum_insured"  value={plan_details.sum_insured}>
                                            <option value="">-- Select --</option>
                                            {
                                                pakages.map((item, index) => { 
                                                    return (<option value={item.id}>{item.amounts_ipd}</option>)
                                                })
                                            }
                                        </select>

                                    </Col>
                                    <Col md={12} className="form-group">
                                        <label for="area_of_cover">Area of Cover</label>
                                        <select className="form-control disabled" name="area_of_cover" id="area_of_cover" value={plan_details.area_of_cover}>
                                            <option value="">-- Select --</option>
                                            {
                                                area.map((item, index) => {
                                                    return (<option value={item.id}>{item.area}</option>)
                                                })
                                            }
                                        </select>
                                    </Col>

                                    <Col md={12}>Out Patient Treatment (optional)</Col>

                                    <Col md={6} className="form-group">
                                        <label for="out_patient_coverage_amount">Coverage Amount</label>
                                        <select className="form-control disabled" name="out_patient_coverage_amount" id="out_patient_coverage_amount" 
                                         value={plan_details.out_patient_coverage_amount}>
                                            <option value="">-- Select --</option>
                                            {
                                                pakages.map((item, index) => {
                                                    return (<option value={item.amounts_opd}>($ {item.amounts_opd})</option>)
                                                })
                                            } 
                                             <option value="0">$ 0.00</option>
                                        </select>
                                    </Col>

                                    <Col md={12}> Deductible (optional) </Col>
                                    <Col md={6} className="form-group">
                                        <label for="deductible_coverage_amount ">Coverage Amount</label>
                                        <select className="form-control disabled" name="deductible_coverage_amount" id="deductible_coverage_amount" value={plan_details.deductible_coverage_amount}>
                                            <option value="">-- Select --</option>
                                            <option value="5000.00">$ 5,000 </option>
                                            <option value="10000.00">$ 10,000 </option>
                                            <option value="15000.00">$ 15,000</option>
                                            <option value="0.00">$ 0.00</option>    
                                        </select>
                                    </Col>
                                    <Col md={12} className="form-group">
                                        <label for="dental_vision_cover disabled">
                                        <input type="checkbox" name="dental_vision_cover" 
                                        checked={plan_details.dental_vision_cover === 'yes' ? true : false} value="yes" id="dental_vision_cover" /> Dental + Vision Cover (optional)</label>
                                    </Col>



                                </Row>
                            </div>


                        </Col>
                        <Col md={5} className="pakage-summary-form">
                        <OrderCardDisplay />
                         {/*   <Card >
                                <div className='header'>
                                    <img src={img.warning_yellow.src} alt=""></img>
                                    <h4> Gold Package</h4>
                                    <small>Health insurance</small>
                                </div>
                                <br />
                                Details
                                <hr />
                                <Row>
                                    <Col className="col-6"><img src={img.person.src} alt=""></img> <label> COVER AMOUNT</label></Col>
                                    <Col className="col-6"><label>USD 1,000,000</label></Col>
                                </Row>
                                <Row>
                                    <Col className="col-6"><img src={img.users.src} alt=""></img> <label> POLICY FOR</label></Col>
                                    <Col className="col-6"><label>Self (22yrs), Spouse (24yrs), Son (2yrs), Daughter ({`<1yrs`})</label></Col>
                                </Row>
                                <Row>
                                    <Col className="col-6"><img src={img.web_sm.src} alt=""></img> <label> Covergae AREA</label></Col>
                                    <Col className="col-6"><label> Worldwide Including USA and Canada</label></Col>
                                </Row>

                                <Row className="mt-20">
                                    <Col className="col-7"><h5>Premium</h5></Col>
                                </Row>
                                <Row className="mb-10">
                                    <hr />
                                    <Col className="col-7"><h6>Annual</h6></Col>
                                    <Col className="col-5"><label>₹ 83,588</label></Col>

                                </Row>
                                <Row className="mt-10 mb-10">
                                    <hr />
                                    <Col className="col-7"><h4>Total</h4></Col>
                                    <Col className="col-5"><strong>₹ 2,728/month</strong></Col>
                                </Row>

                            </Card> */}

                        </Col>

                    </Row>
                </div>
            </Form>
        </>
    )
}


export default PlanDetails;