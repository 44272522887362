import { Card, Col, Row } from "react-bootstrap";
import img from "../../components/Img";
import { Link } from "react-router-dom";

import './Package.css';
import {_number_format} from './../../funcations'


import GetQuote from '../../components/GetQuote';

function Package(props) {

    const package_img = (pakage_name) => {

        if (pakage_name === 'Bronze') {           
            return img.bronze.src;
        }
        if (pakage_name === 'Silver') {
            return img.silver.src;
        }
        if (pakage_name === 'Gold') {
            return img.gold.src;
        }
    }


    return (
        <>
            <Card className={ "pakage-card mt-20 " +  (props.row.selected && " selected-package-div ") }>
                <Row className="pakage-header">
                    <Col md={9}>
                        <img src={package_img(props.row.name)} alt="" className="flot-left mr-20"></img>
                        <h4>{props.row.name}</h4>
                        <span>Health insurance</span>
                    </Col>
                    <Col md={3}>
                        {(props.row.selected) && (<Link to={"/plan-details?id=" + props.row.id} className="btn btn-sm btn-success btn-round">Selected</Link>)}
                    </Col>
                </Row>

                <Row className="mt-20 button-lists">
                    <div className="ul nowrap_">
                        <button size="sm" className="btn btn-default w-auto btn-round mr-10 mb-10"><img src={img.rupee.src} alt=""></img> Covers all In-Patient care</button> 
                        <button size="sm" className="btn btn-default w-auto btn-round mr-10  mb-10"><img src={img.stethoscope.src} alt=""></img> Out-Patient Treatment (OPD) </button>  
                        <button size="sm" className="btn btn-default w-auto btn-round mr-10  mb-10"><img src={img.person.src} alt=""></img> Covered on Individual basis</button> 
                        <button size="sm" className="btn btn-default w-auto btn-round mr-10 mb-10"><img src={img.web_sm.src} alt=""></img> International Emergency Services</button>  
                    </div>
                </Row>

                <Row className="pakage-list mt-20">
                    {
                        props.row.package_features.map((val) => {
                            return (<Col md={6} className="item">
                                <img src={img.correct_green.src} alt=""></img>
                                <span> {val}</span>
                            </Col>);
                        })
                    }

                </Row>

                <Row className="pakage-footer">
                    <Col md={{ span: 8, offset: 4 }}>
                        <h4 className="flot-left">
                            <small>Covered</small><br />
                            {_number_format(props.row.amounts_ipd) }
                        </h4>
                        <Link to={"/plan-details?id=" + props.row.id} className="btn btn-default ml-20 mr-20">More Details</Link>
                        <GetQuote package_id={props.row.id} />
                    </Col>
                </Row>

            </Card>
        </>
    )
}

export default Package;