import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import './FinnovateReviews.css'
import useWindowDimensions from '../useWindowDimensions';

import img from '../Img';

function FinnovateReviews() {

    const { height, width } = useWindowDimensions();
    console.log(height);

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const reviewBox = (person) => {
        return (
            <>
                <div className="col-md-6 col-sm-12 item" style={{'min-width': '500px !important;'}}>
                    <img src={img.mdi_comma.src} alt={img.mdi_comma.alt} className="comma" />
                    <div className="row persons-card">
                        <div className="col-md-8  text-center">
                            <p>{person.about}</p>
                            <span className="name">-{person.name} </span>
                        </div>
                        <div className="col-md-4">
                            <img src={person.image} alt={person.name + ' photo '} className="profile-image" />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const p = [
        {
            id: 1,
            name: 'Mahima Gupta',
            about: 'Finnovate has really helped me to select and buy the best health insurance policy which suits my needs and budget',
            image: img.mahima_gupta.src
        },
        {
            id: 2,
            name: 'Naveen Bajaj',
            about: 'Finnovate has really helped me to select and buy the best health insurance policy which suits my needs and budget',
            image: img.naveen_bajaj.src
        },
        {
            id: 3,
            name: 'Mahima Gupta',
            about: 'Finnovate has really helped me to select and buy the best health insurance policy which suits my needs and budget',
            image: img.mahima_gupta.src
        },
        {
            id: 4,
            name: 'Naveen Bajaj',
            about: 'Finnovate has really helped me to select and buy the best health insurance policy which suits my needs and budget',
            image: img.naveen_bajaj.src
        },
        {
            id: 5,
            name: 'Mahima Gupta',
            about: 'Finnovate has really helped me to select and buy the best health insurance policy which suits my needs and budget',
            image: img.mahima_gupta.src
        },
        {
            id: 6,
            name: 'Naveen Bajaj',
            about: 'Finnovate has really helped me to select and buy the best health insurance policy which suits my needs and budget',
            image: img.naveen_bajaj.src
        }
    ];

    return (
        <>
            <div className="section finnovate-reviews mt-50">
                <div className="title ">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <img src={img.mdi_comma_with_c.src} alt={img.mdi_comma_with_c.alt}></img>
                            <h1>Finnovate Reviews</h1>
                            <p className='text-center'>Our customers have left us some great reviews and you can read them here</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-35 persons-card-section">

                   
                            
                        {
                            (width > 840) ?
                             (
                                    <>
                                     <Carousel activeIndex={index} onSelect={handleSelect}>
                                        <Carousel.Item>
                                            {reviewBox(p[0])}
                                            {reviewBox(p[1])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[2])}
                                            {reviewBox(p[3])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[4])}
                                            {reviewBox(p[5])}
                                        </Carousel.Item>
                                        </Carousel>
                                    </>
                                ) :
                                (
                                    <>
                                    <Carousel activeIndex={index} onSelect={handleSelect}>
                                        <Carousel.Item>
                                            {reviewBox(p[0])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[1])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[2])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[3])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[4])}
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            {reviewBox(p[5])}
                                        </Carousel.Item>
                                        </Carousel>

                                    </>
                                )

                        }


                </div>
            </div>
        </>
    );
}

export default FinnovateReviews;