 
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs'; 
import { Link } from 'react-router-dom';

import '../widgets/Timeline.css';
 
function EasyClaimProcess(props ) {  
  const [key, setKey] = useState('home');
    let img = props.images;
  return (
    <div className="section scheme-offer mt-50">
        <div className="title ">
            <div className="row"> 
                <div className="col-md-12 text-center">
                    <img src={img.file.src} alt={img.file.alt}></img>
                    <h1>What does the scheme offer ?</h1>
                    <p className='text-center'>Get all the listed benefits when you become the part of our Finnovate Family</p>
                    <Link to={"/#"} className="btn btn-primary btn-lg">Contact for Claim Process 
                    <img src={img.arrow_right.src} alt=""></img> </Link>
                </div>                
            </div>
        </div>
        <div className="row mt-35">
            <div className="col-md-6 pb-25 pt-25 family-monochromatic">
                <img src={img.easy_claim_process.src} alt="" ></img>
            </div>
            <div className="col-md-6">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    >
                    <Tab eventKey="home" title="Cashless Claims">
                        <p>Search and Explore health insurances with international coverage, also see it according to your needs and location preferences</p>

                        <div className="wrapper">

                            <div className="center-line"></div>

                            <div className="row row-2">
                            <section>
                                <i className="icon fas fa-home">1</i>
                                <div className="details">
                                <span className="title">Inform the insurance company</span> 
                                </div>
                                <p>For any planned treatments, you need to inform the insurance company 2 days before hospitalisation. In case of emergency, you can inform within 24 hours of admission. Insurance company will acknowledge the intimation.</p>
                                
                            </section>
                            </div>

                            <div className="row row-2">
                            <section>
                                <i className="icon fas fa-star">2</i>
                                <div className="details">
                                <span className="title">Hospitalization</span> 
                                </div>
                                <p>You need to submit a insurance claim form along with original copies of hospital bills, doctor consultation reports, and diagonstic reports.</p>
                                
                            </section>
                            </div>

                            <div className="row row-2">
                            <section>
                                <i className="icon fas fa-rocket">3</i>
                                <div className="details">
                                <span className="title">File the claim</span> 
                                </div>
                                <p>Within 30 days post discharge, you can initiate the claim online or at any of the registered offices of the insurance company. You need to fill a claim form and attach all the reports and bills.</p>
                                
                            </section>
                            </div>

                            <div className="row row-2">
                                <section>
                                    <i className="icon fas fa-globe">4</i>
                                    <div className="details">
                                    <span className="title">Insurer’s decision</span> 
                                    </div>
                                    <p>Based on the claim form and submitted documents, insurance company will settle the claims as per policy terms and conditions.</p>
                                    
                                </section>
                            </div>  

                            </div>

                    </Tab>
                    <Tab eventKey="profile" title="Reimbursement Claims">
                        <h1>Reimbursement Claims content..</h1>
                    </Tab> 
                </Tabs>
            </div>
        </div>
    </div>
  );
}
export default EasyClaimProcess;

