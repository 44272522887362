import { Col, Row } from "react-bootstrap";
import img from "../../../components/Img";
import { useState, useRef, useEffect } from "react";

import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useNavigate } from "react-router-dom";
import Overlay from 'react-bootstrap/Overlay';
import http from "../../../http";

import SweetAlert from 'sweetalert2'


function AdditionalInformation(props) {
  const navigate = useNavigate();
 // console.log("my daa=ta : ", props.proposerData);


  // eslint-disable-next-line
  const [_proposerData, setproposerData] = useState(props.proposerData ? props.proposerData : { 
    vision_and_dental_covered : '',
    opd_covered : '',
    opd_covered_amount : '',
    deductible : ''
   }); //opd_covered
  
 

  props.setShortTitle('Select various riders for a 360° coverage');
  props.setLongTitle('Let’s have a comprehensive coverage');


  /* const[amount, setAmount] = useState(0);
  const[premiumAmonut, setpremiumAmonut] = useState(0); */

  const [show, setShow] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);

  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    let serialize = require('form-serialize');
    let form = document.querySelector('#my_form');
    let form_data = serialize(form, { hash: true });

    http.post('/client-other-details/add', form_data,
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        props.setFormData5(
          {
            ...form_data,
            /* opd_covered: opdCovered,
             dental_covered: dentalCovered,
             amount: amount,
             premium_amonut: premiumAmonut,*/
          }
        );
        //props.setDonePopup(true);
        //props.setStep(6);
        navigate('/dashboard');

      } else {
        SweetAlert.fire(
          'Error!',
          resp.message,
          'error'
        );
      }

    });



  }


  const [pakages_for_opd, setPakages] = useState([]);
  useEffect(() => {
    http.get('/masters-data/get-packages', [],
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setPakages(resp.data);
      } else {
        SweetAlert.fire(
          'Error!',
          resp.message,
          'error'
        );
      }
    });

  }, [])
 

  const [selected_vision_and_dental_covered, setVisionAndDentalSelector] = useState(_proposerData.vision_and_dental_covered);
  const [selected_opd_covered, setSelectedOpdCovered] = useState(_proposerData.opd_covered);
  const [opd_selector, setOpdSelector] = useState(selected_opd_covered === 'yes' ? 'show' : 'hide');


  const opdRadioOnChange = (flage) => {
    setOpdSelector(flage);
    setSelectedOpdCovered(flage === 'show' ? 'yes' : 'no');
  }

const learnMore =() => {
  props.setDonePopup(true)
}

  return (
    <>


      <Overlay target={target1.current} show={show} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <h5>OPD Coverage</h5>
            OPD coverage refers to coverage provided by an insurance policy or health plan for outpatient department (OPD) services. <br />
            <Link  onClick={ learnMore } > Learn More </Link>
          </Tooltip>
        )}
      </Overlay>

      <Overlay target={target2.current} show={showTooltip2} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <h5>Deductible</h5>
            <div>Deductible means a cost sharing requirement under a health insurance policy that provides that the Insurer will not be liable for a specified currency amount in case of the Impatient hospitalization or day care treatment claim.</div>
            <Link  onClick={learnMore}> Learn More </Link>
          </Tooltip>
        )}
      </Overlay>

      <Overlay target={target3.current} show={showTooltip3} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            <h5>Deductible</h5>
            <div>Deductible means a cost sharing requirement under a health insurance policy that provides that the Insurer will not be liable for a specified currency amount in case of the Impatient hospitalization or day care treatment claim.</div>
            <Link  onClick={learnMore}> Learn More </Link>
          </Tooltip>
        )}
      </Overlay>


      <form onSubmit={handleSubmit} id="my_form">
        <div className="additional-information">
          <Row>
            <p>
              <label>OPD Coverage</label>
              <span className="pointer" ref={target1} onClick={() => setShow(!show)}><img src={img.help.src} alt='' /></span>
            </p>
            <hr className="bottom-border" />
          </Row>

          <Row>
            <Col className="col-md-6 mt-20 ">
              <label className="">OPD Covered</label>
            </Col>
            <Col className="col-md-6 text-right radio_button">
              <label><input type="radio" name='opd_covered' checked={selected_opd_covered === 'yes'} value="yes" onClick={() => { opdRadioOnChange('show') }} />Yes </label>
              <label><input type="radio" name='opd_covered' checked={selected_opd_covered === 'no'} value="no" onClick={() => { opdRadioOnChange('hide') }} />No </label>
            </Col>
          </Row>

          <Row className={opd_selector}>
            <Col className="col-md-10 mt-20 ">
              <label>If yes, then select amount</label>
              <select className="form-control form-control-lg" name="opd_covered_amount">
                <option value="">-- Select --</option>
                {
                  pakages_for_opd.map((row, index) => {
                    return (<option selected={_proposerData.opd_covered_amount === row.amounts_opd ? 'selected' : ''} 
                    value={row.amounts_opd}>$ {row.amounts_opd}</option>)
                  })
                }
              </select>
            </Col>
          </Row>


          <Row className="mt-20">
            <p><label>Deductible</label> <span className="pointer" ref={target2} onClick={() => setShowTooltip2(!showTooltip2)}><img src={img.help.src} alt='' /></span> (Premium is Discounted )</p>
            <hr className="bottom-border" />
          </Row>
          <Row>
            <Col className="col-md-10">
              <label>Deductible</label>
              <select className="form-control    form-control-lg" name="deductible" defaultValue={_proposerData.deductible}>
                <option value="">-- Select --</option>
                <option value="5000.00">$ 5, 000</option>
                <option value="10000.00">$ 10, 000</option>
                <option value="15000.00">$ 15, 000</option>
                <option value="0.00">$ 0</option>
              </select>
            </Col>
          </Row>

          <Row className="mt-20">
            <p><label>Vision & Dental Coverage</label> <span className="pointer" ref={target3} onClick={() => setShowTooltip3(!showTooltip3)}><img src={img.help.src} alt='' /></span> </p>


            <hr className="bottom-border" />
          </Row>
          <Row>
            <Col className="col-md-6"><br />Vision & Dental Covered</Col>
            <Col className="col-md-6 text-right radio_button">
              <label><input type="radio" defaultChecked={selected_vision_and_dental_covered === 'yes'} name='vision_and_dental_covered' value="yes" />Yes </label>
              <label><input type="radio" defaultChecked={selected_vision_and_dental_covered === 'no'} name='vision_and_dental_covered' value="no" />No </label>

            </Col>
          </Row>


          <Row className="continue-btn-section">
            <Col className="col-md-4">
              <input
                onClick={() => props.setStep(4)}
                type="button" className="full-width btn btn-outline-primary btn-lg" value="Back" />
            </Col>
            <Col className="col-md-8">
              <input
                type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
}

export default AdditionalInformation;
