import { useEffect, useState } from "react";
import { Card, Col, Modal, Row, Button, ButtonGroup } from "react-bootstrap";
import img from "../../components/Img";


import './ClientRequirements.css';

import PeopleCoveredOption from "./sections/PeopleCoveredOption";
import DobOfPepopleInclude from "./sections/DobOfPepopleInclude";
import EditDobOfPepopleInclude from "./sections/EditDobOfPepopleInclude";
import CoverageArea from "./sections/CoverageArea";
import SumInsured from "./sections/SumInsured";
import AdditionalInformation from "./sections/AdditionalInformation";
import { Link } from "react-router-dom";
import http from './../../http';
import {get} from './../../funcations'


function ClientRequirements() {
    

    const [donePopup, setDonePopup] = useState(false);
    
    const [step, setStep] = useState(1);

    const [shortTitle, setShortTitle] = useState('Details of all Insured persons');
    const [longTitle, setLongTitle] = useState('Select family members to be covered');


    const [formData1, setFormData1] = useState({});
    const [formData2, setFormData2] = useState({});
    const [formData3, setFormData3] = useState({});
    const [formData4, setFormData4] = useState({});
    const [formData5, setFormData5] = useState({});

    if (step > 1) {
        console.log(formData1);
        console.log(formData2);
        console.log(formData3);
        console.log(formData4);
        console.log(formData5);
        // setLgShow(true);
    } 


    const [proposerData, setproposerData] = useState(null);
    /**
   * check existing data 
   */ 
    useEffect(() => { 
       
        if(!localStorage.getItem("token")){
            window.location.href = '/login';
        }

        http.post('/proposer-details', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                const data = resp.data;
                setproposerData(data);

                if (data.proposer_name && get('action') !== 'edit') { 
                    setStep(3);
                }
                if(data.preference && get('action') !== 'edit'){
                    setStep(4);
                }
                if(data.covered_ranges && get('action') !== 'edit'){    
                    setStep(5);
                }
                if(data.opd_covered || data.opd_covered_amount){
                    if(get('action') !== 'edit'){
                        //window.location.replace('/plan-details?id='+data.package_type_master_id);
                        window.location.replace('/dashboard');
                    }
                }

            }
        });
      
     }, []);
    

    return (
        <>
            <Modal
                size="lg"
                show={donePopup}
                onHide={() => setDonePopup(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <strong> Dental and Vision Coverage</strong>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <p>Dental and vision cover has to be opted together. It is not available for Bronze plan. </p>
                        <Col>
                            <ButtonGroup aria-label="Basic example" className="group-toggle">
                                <Button variant="secondary" size="lg"> Dental </Button>
                                <Button variant="secondary" size="lg"> Vision </Button>
                            </ButtonGroup>
                        </Col>


                        <h4>Vision</h4>
                        <strong>Vision Cover Includes :</strong>
                        <p><ul>
                            <li>A single examination of the eyes by an optometrist or ophthalmologist per Policy Year</li>
                            <li>Expenses for lens, eyeglass frames, prescription sunglasses to correct vision</li>
                            <li>Waiting Period : 0 months</li>
                        </ul></p>

                        <strong>Sum Insured amount will be as per plan :</strong>
                        <p><ul>
                            <li>Silver Plan - $150-with deductible $50</li>
                            <li>Gold Plan -$250 with deductive of $75</li>
                        </ul></p>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="#" className="btn btn-primary" onClick={() => setDonePopup(false)}>Done</Link>
                </Modal.Footer>
            </Modal>

            <Row className="register-section">
                <Col className="col-md-6 register-section-left">
                    <h1>Ensuring you the best in <br/><br/>class <span className="theme-color">Risk management</span></h1>
                    <p>Finnovate will help you in managing your various financial life risks, one of which is a medical emergency. We will be providing a best in class health insurance policy which has a global cover.</p>
                    <Row className="text-center family-image">
                        <img src={img.family_health_insurance.src} alt={img.family_health_insurance.alt}></img>
                    </Row>

                </Col>
                <Col className="col-md-6 register-section-right">
                    <Col className="col-md-11">
                        <Card className=''>

                            <p> {step !== 1 && (
                                <span className="pointer back-arrow" onClick={() => setStep(step - 1)} ><img src={img.arrow_left.src} alt="" ></img></span>
                            )}
                                {shortTitle}
                            </p>

                            <h2>{longTitle}</h2>

                            <Row className="mb-8 mb-20">
                                <div className="stepper-list">
                                    <span className={step === 1 && 'active'}>1</span> -----
                                    <span className={step === 2 && 'active'}>2</span> -----
                                    <span className={step === 3 && 'active'}>3</span> -----
                                    <span className={step === 4 && 'active'}>4</span> -----
                                    <span className={step === 5 && 'active'}>5</span>
                                </div>

                                {step === 1 && (<PeopleCoveredOption
                                    setShortTitle={setShortTitle}
                                    setLongTitle={setLongTitle}

                                    setFormData1={setFormData1}
                                    formData1={formData1}

                                    step={step}
                                    setStep={setStep} proposerData={proposerData} />)}

                                {(!get('action') && step === 2)  && (<DobOfPepopleInclude
                                    setShortTitle={setShortTitle}
                                    setLongTitle={setLongTitle}

                                    setFormData1={setFormData1}
                                    formData1={formData1}
                                    setFormData2={setFormData2}
                                    formData2={formData2}

                                    step={step}
                                    setStep={setStep} 
                                    proposerData={proposerData} />)}

                                  { (step === 2 && get('action') === 'edit') && (<EditDobOfPepopleInclude
                                    setShortTitle={setShortTitle}
                                    setLongTitle={setLongTitle}

                                    setFormData1={setFormData1}
                                    formData1={formData1}
                                    setFormData2={setFormData2}
                                    formData2={formData2}

                                    step={step}
                                    setStep={setStep} 
                                    proposerData={proposerData} />)}


                                {step === 3 && (<CoverageArea
                                    setShortTitle={setShortTitle}
                                    setLongTitle={setLongTitle}

                                    setFormData3={setFormData3}
                                    formData3={formData3}

                                    step={step} setStep={setStep}
                                    preference = {proposerData ? proposerData.preference : ''}  />)}

                                {step === 4 && (<SumInsured
                                    setShortTitle={setShortTitle}
                                    setLongTitle={setLongTitle}
                                    setFormData4={setFormData4}
                                    formData4={formData4}
                                    step={step}
                                    setStep={setStep} 
                                    covered_ranges = {proposerData ? proposerData.covered_ranges : '' }/>)}

                                {step === 5 && (<AdditionalInformation
                                    setShortTitle={setShortTitle}
                                    setLongTitle={setLongTitle}

                                    setFormData1={setFormData1}
                                    formData1={formData1}
                                    setFormData2={setFormData2}
                                    formData2={formData2}
                                    setFormData3={setFormData3}
                                    formData3={formData3}
                                    setFormData4={setFormData4}
                                    formData4={formData4}

                                    setFormData5={setFormData5}
                                    formData5={formData5}
                                    step={step}
                                    setStep={setStep}
                                    setDonePopup={setDonePopup} 
                                    proposerData={proposerData} />)}



                            </Row>

                        </Card>





                    </Col>
                </Col>
            </Row>

        </>
    );
}

export default ClientRequirements;


