import ScheduleACall from "../widgets/buttons/ScheduleACall"; 
import ContactOnWhatsapp from "../widgets/ContactOnWhatsapp";

function Footer() {
  return (
    <footer className='container'>
      <div className="row">
        <div className="col-md-5">
          <div className="footer-logo col-md-9">
            <img className='logo' alt='Finnovate' src="https://www.finnovate.in/assets/images/logo.svg"></img>
            <br />
            <p className="short-desc">India's first end-to-end ecosystem that simplifies investing, streamlines tracking and empowers you to achieve your financial goals.</p>
          </div>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-3">
              <strong>Pages</strong>
              <ul>
                <li><a href="/#">Home</a></li>
                <li><a href="/#">Plan</a></li>
                <li><a href="/#">Contact Us</a></li>
                <li><a href="/#">About</a></li>
              </ul>
            </div>
            <div className="col-md-3">
              <strong>Products</strong>
              <ul>
                <li><a href="/#">Stocks</a></li>
                <li><a href="/#">Mutual Funds</a></li>
                <li><a href="/#">Health Insurance</a></li>
                <li><a href="/#">Stocks</a></li>
              </ul>
            </div>
            <div className="col-md-3">
              <strong>Health Insurance</strong>
              <ul>
                <li><a href="/#">Stocks</a></li>
                <li><a href="/#">Mutual Funds</a></li>
                <li><a href="/#">Health Insurance</a></li>
                <li><a href="/#">Stocks</a></li>
              </ul>
            </div>
            <div className="col-md-3">
              <strong>Options</strong>
              <ul>
                <li><a href="/#">Stocks</a></li>
                <li><a href="/#">Mutual Funds</a></li>
                <li><a href="/#">Health Insurance</a></li>
                <li><a href="/#">Stocks</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-15">
        <div className="col-md-9">
          <span>hello@finnovate.in | +91-9876543210</span>
        </div>
        <div className="col-md-3 social-icons">
          <a href="https://twitter.com/FinnovateLive" target="_blank"><img src='./assets/images/icons/twitter.png' alt=""></img></a>
          <a href="https://www.linkedin.com/company/finnovateofficial" target="_blank"><img src='./assets/images/icons/linkedin.png' alt=""></img></a>
          <a href="https://www.instagram.com/finnovate.in/" target="_blank"><img src='./assets/images/icons/instagram.png' alt=""></img></a>
          <a href="https://www.facebook.com/Finnovate" target="_blank"><img src='./assets/images/icons/facebook.png' alt=""></img></a>
        </div>
      </div>
      <div className="row buttons">
        <div className="col-sm-12 col-md-3 mb-20">
          <ScheduleACall />
        </div>
        <div className="col-md-4 col-sm-12">
        <ContactOnWhatsapp /> 
        </div>



      </div>
      <hr />
      <div className="row ">
        <div className="col-md-5 copyright-section">
          <p>Copyright © 2022. Finnovate Financial Services Pvt Ltd. All Rights Reserved.</p>
        </div>
        <div className="col-md-7 disclaimer">
          <strong>Disclaimer</strong>
          <p>All the information on this website is published in good faith and for general information purpose only. Website Name does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Website.com), is strictly at your own risk. will not be liable for any losses and/or damages in connection with the use of our website</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

