import { useState, useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../../components/Img"; 
import http from '../../http'
import NoteContext from "../../context/notes/NoteContext";

import { _number_format_usd, _number_format_usd_with_icon } from '../../funcations'

function GetQuotesList(props) {

    const context = useContext(NoteContext);


    const [get_quotes_list, setGetQuoteList] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/get-quote-data', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setGetQuoteList(resp.data);
                } else {
                }
            }).catch(err => {
                const resp = err.response.data;
                console.log("Error: ", resp);
                context.checkTokenStatus(resp);
            });;
        }
    }, []); 

 

    const [proposer, setProposerDetails] = useState({});
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                }
            });
        }
    }, []);


    //let proposer = context.getProposerDetails;
    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });



    const package_img = (pakage_name) => {

        if (pakage_name === 'Bronze') {
            return img.bronze.src;
        }
        if (pakage_name === 'Silver') {
            return img.silver.src;
        }
        if (pakage_name === 'Gold') {
            return img.gold.src;
        }
    }


const loadPage = () => { 
    if(props.page_load_count){ 
        props.setPageLoadCount(props.page_load_count + 1);
    } 
}

if(!localStorage.getItem('my_get_quote_list')){
    localStorage.setItem("my_get_quote_list", [0]);
}
 

    return (
        <>
            {
                ( get_quotes_list.length>0 && <div className="center"><h3>All Quote List</h3></div>)
            }
            
            <div className={ props.className &&  props.className   + " video-call-section p-0 " }>
                {
                
                    get_quotes_list.map((item, index) => {
                      //  let old_p = (localStorage.getItem('my_get_quote_list') ? localStorage.getItem('my_get_quote_list'): [0] );
                        localStorage.setItem("my_get_quote_list", [localStorage.getItem('my_get_quote_list') , item.package_type_master_id ]);
                        return (<>
                            <Card>
                                <div>
                                    <img src={package_img(item.package_name)} alt="" className="flot-left mr-20"></img>
                                    <h4 class="">{item.package_name} <small>{'($ ' + _number_format_usd(item.package_cover_amounts) + ')'}</small></h4>

                                    <span>Health insurance</span>
                                    <hr />
                                </div>
                                <div className="mb-10">
                                    <img src={img.users.src} alt=""></img> <b> Policy For: </b>
                                    {

                                        item.family_member_age_list.map((member, sub_index) => {
                                            return (<span>{member.relationship + '(' + member.age + '), '}</span>)
                                        })
                                    }
                                </div>
                                <div className="mb-10">
                                    <img src={img.web_sm.src} alt=""></img> <b>Covergae Area:</b> {item.coverage_area}
                                    {(item.dental_and_vision === 'yes' && ' With include Dental And Vision')} <br />
                                    {(' OPD Covergae - $' + _number_format_usd(item.opd_covered_amounts))} <br />
                                    {(' Deductible - $' + _number_format_usd(item.opd_covered_amounts))}
                                </div>
                                <div className="mb-10 "><b>Approval Status:</b> <span className={"approval_status_"+item.approval_status}>{
                                item.approval_status.toUpperCase()  
                                }</span></div>
                                <div>

                                    <Link className="btn btn-primary" to={"/get-quote-details?get_quote_id=" + item.get_quote_id} onClick={()=>loadPage()}>View</Link>

                                </div>
                                <div className="right font-10"><i>{item.created_at_2}</i></div>
                            </Card>
                            <br />
                        </>)
                    })
                }
            </div>
        </>
    )
}

export default GetQuotesList;