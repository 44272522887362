import { Link } from "react-router-dom"







function Page404() {
    return (
        <div className='center' style={{ padding: "100px", color: "red" }}>
            <h1>404</h1><br />
            <h2>!Page Not Found</h2><br />
            <Link to="/">Go To Home Page</Link>
        </div>
    )

}

export default Page404;