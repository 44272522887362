import FormHeaderSection from "./FormHeaderSection.jsx";
import { Row, Col, Card, Form } from "react-bootstrap";
import img from "../../components/Img.jsx";

import { useForm } from "react-hook-form";
import http from "../../http.jsx";
import SweetAlert from 'sweetalert2'

import { useState, useEffect } from "react";
//import noteContext from "../../context/notes/NoteContext.jsx";

function Nominee(props) {

    ////const global = useContext(noteContext);
    //global.TokenExpire(true);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

  

    const getCountries = () => {
        http.post('/locations/countries', []).then((response) => {
            const res = response.data;
            if (res.success) {
                setCountries(res.data)
                //   console.log(countries);
            } else {
                console.log(res.message);
            }
        });
    }
   
    const getStates = (country) => {
       //let country = e2.target.value;
        http.post('/locations/states', { country:  country } ).then((response) => {
            const res = response.data;
            if (res.success) {
                setStates(res.data)

            } else {
                console.log(res.message);
            }
        });
    }  
    const getCities = (state) => {
        // let state = e.target.value;
         http.post('/locations/cities', { state: state }).then((response) => {
             const res = response.data;
             if (res.success) {
                 setCities(res.data)
             } else {
                 console.log(res.message);
             }
         });
     }


    const handleChangeCountry = (e) => {
        getStates(e.target.value);
    }
    const handleChangeState = (e) => {
        getCities(e.target.value);
    }


    useEffect(() => {
        getCountries();
        // getStates();
        // getCities();
        get_nominee();
    }, [])


    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    // console.log(register);
    //console.log(errors);
    const onSubmitForm = data => {
        // console.log(data);

        let serialize = require('form-serialize');
        let form = document.querySelector('#form_nominee');
        let form_data = serialize(form, { hash: true });
        // console.log("fnominee form data >> ", form_data);


        http.post('/enrollment-form/save-nominee', form_data,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            //    console.log(resp);
            if (resp.success) {
                SweetAlert.fire(
                    'Success',
                    'Nominee data saved successfully.',
                    'success'
                );
                props.setStep('Documents');
            } else {

                const error = resp.errors;
                if (error.name) {
                    setError('name', { message: error.name[0] });
                }
                if (error.pincode) {
                    setError('pincode', { message: error.pincode[0] });
                }
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });

    }

    const [oldNomineeData, setOldNomineeData] = useState({
        country: "india"
    });
    function get_nominee() {

        http.post('/enrollment-form/get-nominee', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                const data = resp.data;
                setOldNomineeData(data);
                // alert(data.country);
                

                if( data.name){
                    //  data.country;
                    /*if(data.country !== ''){
                       alert(data.country);
                        getStates(data.country);
                    } */

                    if(data.state  !== ''){
                        //alert(data.state);
                        getStates(data.country);
                    }

                    if(data.city  !== ''){
                        //alert(data.city);
                        getCities(data.state);
                    }

                    if(data.same_as_proposer_address === 'yes'){
                        document.getElementById("same_as_proposer_address_yes").checked = true;
                    }else{
                        document.getElementById("same_as_proposer_address_no").checked = true;
                    }
                   /*
                    document.getElementById("address").value = data.address;
                    document.getElementById("city").value = data.city;
                    document.getElementById("state").value = data.state;
                    document.getElementById("pincode").value =  data.pincode; */
                } 

            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });
    }
    return (
        <>

            <Form id="form_nominee" onSubmit={handleSubmit(onSubmitForm)} >
                <FormHeaderSection title="Nominee" sub_title="Fill out the basic details for the people" step={props.step} setStep={props.setStep} />
                <div className="proposer-details form-section">
                    <Row>
                        <Col md={8}>

                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Name of Nominee</label>
                                    <input type="text" name="name" id="name" className="form-control" defaultValue={oldNomineeData.name}
                                        {...register("name", { required: 'Name of Nominee is required' })}
                                    ></input>
                                    <span class={errors.name ? 'required' : ''}>
                                        {errors.name && errors.name.message}
                                    </span>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Relationship with Insurer</label>
                                    <input type="text" name="relationship_with_insurer" id="relationship_with_insurer" className="form-control"
                                        defaultValue={oldNomineeData.relationship_with_insurer}
                                    ></input>
                                </div>
                            </div>


                            <div className="form-group row">
                                <Col md={8}>Nominee has different residential address from that of Proposer</Col>
                                <Col md={4}>
                                    <div className="radio_button right">
                                        <label><input type="radio" id="same_as_proposer_address_yes" name="same_as_proposer_address" value="yes" />Yes</label>
                                        <label><input type="radio" id="same_as_proposer_address_no" name="same_as_proposer_address" value="no" />No</label>
                                    </div>
                                </Col>
                            </div>

                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Address</label>
                                    <input type="text" name="address" id="address" className="form-control"
                                        defaultValue={oldNomineeData.address}></input>
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Country</label>
                                    <select className="form-control" name="country" id="country" onChange={ handleChangeCountry }>
                                        <option value="">-- Select --</option>
                                        {
                                            countries.map((item, key) => {
                                                var s = item.name === oldNomineeData.country ? "selected" : '';
                                                return (<option selected={ s } value={item.name}>{item.name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>State</label>
                                    <select className="form-control" name="state" id="state" onChange={ handleChangeState }>
                                        <option value="">-- Select --</option>
                                        {
                                            states.map((item, key) => {
                                                var s2 = item.name === oldNomineeData.state ? "selected" : '';
                                                return (<option selected={ s2 } value={item.name}>{item.name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>City</label>
                                    <select className="form-control" name="city" id="city" defaultValue={oldNomineeData.address}>
                                        <option value="">-- Select --</option>
                                        {
                                            cities.map((item, key) => {
                                                var s3 = item.name === oldNomineeData.city ? "selected" : '';
                                                return (<option selected={ s3 } value={item.name}>{item.name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Pincode</label>
                                    <input type="text" name="pincode" id="pincode" className="form-control"
                                    defaultValue={oldNomineeData.pincode}
                                        {...register("pincode", { required: 'Pin Code is required' })}
                                    ></input>
                                    <span class={errors.pincode ? 'required' : ''}>
                                        {errors.pincode && errors.pincode.message}
                                    </span>
                                </div>
                            </div>


                        </Col>
                        <Col md={4}>
                            <Card className="guidelines-section">
                                <h4><img src={img.notebook.src} alt=""></img> Guidelines</h4>
                                <small>Aadhaar card number is a 16-digit number on your aadhaar card</small>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default Nominee;