import { React, useEffect, useState } from 'react'
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from '../components/Img';
import './InsuranceApplicationStatus.css';
import http from './../http'
import { useForm } from "react-hook-form";
import OrderCardDisplay from './../components/widgets/OrderCardDisplay'
import SweetAlert from 'sweetalert2'

import {uc_first} from './../funcations'

function PlanDetails(props) {
    const navigate = useNavigate();
    useEffect(() => {
        check_done_pending_modules();
        //   getMyQuote();
    }, [])

    const [makePayment, setMakePayment] = useState('disabled');
    const [policyStatus, setPolicyStatus] = useState('disabled');

    const [modules, setModules] = useState({});

    const check_done_pending_modules = (step) => {
        http.post('/enrollment-form/check-done-pending-modules', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                const m = resp.data;
                setModules(m);
            } else {
                // 
            }
        }).catch(err => {
            // const resp = err.response.data;
            // context.checkTokenStatus(resp);
        });;
    }

    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const onSubmitMedicalExamination = data => {
        const payload = data;
        http.post('/enrollment-form/progress-update', payload).then((response) => {
            //  console.log(response.data);
            const resp = response.data;
            if (resp.success) {
                SweetAlert.fire(
                    'Success',
                    resp.message,
                    'success'
                );
                getProgressDetails();
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });


    }

    const [progress_details, setProgressDetails] = useState({});

    /*  const getMyQuote = () => { 
          if (localStorage.getItem("token")) {
              const payload = {
                  get_quote_id: localStorage.getItem('get_quote_id')
              }
  
              http.post('/get-quote-data', payload,
                  { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
              ).then((response) => {
                  const resp = response.data; 
                  if (resp.success) { 
                      localStorage.setItem('enrollment_form_verified_at', resp.data[0].enrollment_form_verified_at_2);
                      setMyOrder(resp.data[0]);                   
                  } else {
                      localStorage.removeItem('enrollment_form_verified_at');
                  }
              }).catch(err => {
                  const resp = err.response.data;
                  console.log("Error: ", resp);
              });;
          }
      } */

    const getProgressDetails = () => {
        localStorage.setItem('enrollment_form_verified_status', 'pending');
        if (localStorage.getItem("token")) {
            const payload = {
                get_quote_id: localStorage.getItem('get_quote_id')
            }
            http.post('/enrollment-form/progress-details', payload,
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    localStorage.setItem('enrollment_form_verified_status', resp.data.enrollment_form_verified_status);
                    localStorage.setItem('enrollment_form_verified_at', resp.data.enrollment_form_verified_at_2);
                    setProgressDetails(resp.data);
                } else {
                    localStorage.removeItem('enrollment_form_verified_at');
                    localStorage.removeItem('enrollment_form_verified_status');
                }
            }).catch(err => {
                const resp = err.response.data;
                console.log("Error: ", resp);
            });;
        }
    }

    const [checkMedicalExaminationMembersFlag, setcheckMedicalExaminationMembersFlag] = useState(false);
    const checkMedicalExaminationMembers = () => {
        if (localStorage.getItem("token")) {
            // in case find member type which age >=50 of any health issue...
            http.post('/enrollment-form/check-medical-examination-members', {},
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    if (resp.data.length > 0) {
                        setcheckMedicalExaminationMembersFlag(true);
                        // alert("true");
                    } else {
                        //   alert("false");
                    }
                }
            }).catch(err => {
                const resp = err.response.data;
                console.log("Error: ", resp);
            });;
        }
    }

    const getProposerDetails = () => {
        if (localStorage.getItem("token")) {

            http.post('/proposer-details', {},
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerAddress(
                        resp.data.address_line_1 + " " +
                        resp.data.address_line_2 + " " +
                        resp.data.area + " " +
                        resp.data.city + " " +
                        resp.data.pincode
                    );
                } else {
                }
            }).catch(err => {
                const resp = err.response.data;
                console.log("Error: ", resp);
            });;
        }
    }

    const [proposerAddress, setProposerAddress] = useState("");
    useEffect(() => {
        getProposerDetails();
        checkMedicalExaminationMembers();
        getProgressDetails();
    }, [])

    const [otherAddress, setOtherAddress] = useState("");

    const handleKeyPress = (event) => {
        setOtherAddress(event.target.value);
    }

    var currentdate = new Date();
    let mm = ("0" + (currentdate.getMonth() + 1)).slice(-2);
    let dd = ("0" + currentdate.getDate()).slice(-2);
    var current_datetime = currentdate.getFullYear() + "-" + mm + "-" + dd + "T" + currentdate.getHours() + ":" + currentdate.getMinutes();

const [else_address, setElseAddress] = useState('none'); 
const show_hide_address=(val)=>{  
    setElseAddress(val);
}

    return (
        <>



            <Row className='insurance-application-status'>
                <div className="back mb-20">
                    <Link onClick={() => navigate(-1)}> <img src={img.arrow_left.src} alt=""></img> Back</Link>
                    <br />
                    <h4>You are almost there</h4>
                    <span>Fill these forms and finish all formalities from your side</span>
                </div>
                <Col md={8}>

                    <Card className="fill">
                        {
                            (progress_details.enrollment_form_verified_status === 'approved' || progress_details.enrollment_form_verified_status === 'rejected') ? 
                                <div className={'enrollment_form_verified_status_'+ progress_details.enrollment_form_verified_status +" right"}>
                                  Enrollment Form Status:  <small>{uc_first(progress_details.enrollment_form_verified_status) }</small>
                                </div>
                                 :
                                <div className="right warning-text">
                                    <small><img src={img.wavy_warning.src} alt=""></img> We are currently evaluating your documents we will get back to you in 5-6 business days.</small>
                                </div>
                        }

                      


                        <div>
                            {progress_details.enrollment_form_verified_status === 'approved' ? <i class="fa fa-check-circle primary-color fa-2x mr-5 flot-left mr-10" aria-hidden="true"></i> : <span className='counter'>1</span>}

                            <h5 >Enrollment Form</h5>
                        </div>
                        <div className='text'>
                            <p>Form including basic details about the insured person and some lifestyle related questions</p>
                            <div>
                                {(modules.module_sign_and_confirm !== 1 ?
                                    <Link to={'/enrollment-form'} className='btn btn-link border'> <img src={img.edit_2.src} alt=""></img> Proceed to Fill</Link> :
                                    <>
                                        <Link to={'/enrollment-form'} className='btn btn-link border mr-20'> <img src={img.edit.src} alt=""></img> Edit</Link>
                                        <Link to={'/enrollment-form?preview=1'} className='btn btn-link border'> <img src={img.show.src} alt=""></img> Preview</Link>
                                    </>
                                )}


                            </div>
                        </div>

                    </Card>

                    {
                        checkMedicalExaminationMembersFlag &&

                        <Card className={(progress_details.enrollment_form_verified_status === 'approved' ? "" : " disabled ") + " fill mt-20 mb-20"}>
                            <div>
                                {progress_details.medical_examination_scheduled_at_2 ? <i class="fa fa-check-circle primary-color fa-2x mr-5 flot-left mr-10" aria-hidden="true"></i> : <span className='counter'>2</span>}
                                <h5 style={{ float: 'left', 'margin-right': '30px' }}>Medical Examination</h5>
                            </div>
                            <div className='text'>
                                <p>Application for pepople who are more then 50 years</p>
                                <form onSubmit={handleSubmit(onSubmitMedicalExamination)}>
                                    <div className='row'>
                                        {
                                            progress_details.medical_examination_scheduled_at_2 ?
                                                <>
                                                    <div><b>Medical Examination Schedule At:</b> <i>{progress_details.medical_examination_scheduled_at_2}</i></div>
                                                    <div><b>Address:</b> <i>{progress_details.medical_examination_address}</i></div>
                                                </>
                                                :
                                                <>
                                                    <div className='col-md-6'>
                                                        <input type="datetime-local" name="medical_examination_scheduled_at" min={current_datetime}
                                                            className={errors.medical_examination_scheduled_at ? 'error form-control' : 'form-control'}
                                                            {...register("medical_examination_scheduled_at", { required: 'This filed is required' })}
                                                        ></input>
                                                        <span class={errors.medical_examination_scheduled_at ? 'required' : ''}>
                                                            {errors.medical_examination_scheduled_at && errors.medical_examination_scheduled_at.message}
                                                        </span>

                                                        <div>
                                                            <br />
                                                            <label onClick={()=>show_hide_address('none')}><input type="radio" name="medical_examination_address" value={proposerAddress}
                                                                {...register("medical_examination_address", { required: 'This filed is required' })}
                                                            /> Cofirm Address <br />
                                                                <i>{proposerAddress}</i></label>
                                                            <br />
                                                            <br />
                                                            <label onClick={()=>show_hide_address('block')}><input type="radio" name="medical_examination_address" defaultValue={otherAddress} value={otherAddress}
                                                                {...register("medical_examination_address", { required: 'This filed is required' })}
                                                            /> Or Else</label>
                                                            <textarea style={{display:else_address}} className='form-control' placeholder='Enter Full Address'
                                                                onChange={handleKeyPress}
                                                            ></textarea>
                                                            <span class={errors.medical_examination_address ? 'required' : ''}>
                                                                {errors.medical_examination_address && errors.medical_examination_address.message}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <button type="submit" className='btn  btn-primary border'> <i class="fa fa-calendar" aria-hidden="true"></i> Schedule </button>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </form>

                            </div>
                        </Card>

                    }
                    <Card className={progress_details.make_payment + " fill mt-20 mb-20"}>
                        <div>
                            <span className='counter'>2</span>
                            <h5 style={{ float: 'left', 'margin-right': '30px' }}>Make Payment</h5>
                        </div>
                        <div className='text'>
                            <p>Pay your first premium</p>
                            <Link className='btn  btn-link border'> Pay Now</Link>
                            <Link className='btn  btn-link border'><img src={img.download.src} alt=""></img>  Download Reciept</Link>
                        </div>
                    </Card>

                    <Card className={progress_details.policy_status === 'done' ? " fill " : " disabled fill "}>
                        <div>
                            <span className='counter'>3</span>
                            <h5 style={{ float: 'left', 'margin-right': '30px' }}>Policy Status</h5>
                        </div>
                        <div className='text'>
                            <p>If everything goes right from insurer’s end then we will email you your insurance paper</p>
                            <Link className='btn  btn-link border'> View Status</Link>
                            <Link className='btn  btn-link border'><img src={img.download.src} alt=""></img> Download Documents</Link>

                        </div>

                    </Card>
                </Col>
                <Col md={4} className="pakage-summary-form">
                    <OrderCardDisplay />
                </Col>

            </Row>

        </>
    )
}

export default PlanDetails;