import FormHeaderSection from "./FormHeaderSection.jsx";
import { Row, Col, Card, Form } from "react-bootstrap";
import img from "../../components/Img.jsx";

import { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import http from "../../http.jsx";
import noteContext from '../../context/notes/NoteContext';
import SweetAlert from 'sweetalert2'


function AdditionalHealthDetails(props) {
    const context = useContext(noteContext);

    const { register, handleSubmit, formState: { errors } } = useForm();
    //console.log(register);
    //console.log(errors);
    const onSubmitForm = data => {
        //console.log(data);



        let serialize = require('form-serialize');
        let form = document.querySelector('#form_additional_health_details');
        let form_data = serialize(form, { hash: true });
        console.log("form_additional_health_details >> ", form_data.input);


        http.post('/enrollment-form/save-additional-health-details', form_data.input,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            //console.log("LINE 33 : ",resp);
            if (resp.success) {
                SweetAlert.fire(
                    'Success',
                    'Additional Health Details Updated Successfully.',
                    'success'
                );
                // console.log(resp.data); 
                props.setStep('Nominee');
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp);
        });


    }

    const [rows, setRows] = useState([]);
    const get_additional_health_details = () => {
        http.post('/enrollment-form/get-additional-health-details', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                const data = resp.data;
                setRows(data);


                /*  if(data.self){
                      const self = data.self;  
                      document.getElementById('self_member_id').value = self.member_id;
                      document.getElementById('self_name_of_the_medical_problem').value = self.name_of_the_medical_problem;
                      document.getElementById('self_area_of_the_body_affected').value = self.area_of_the_body_affected;
                      document.getElementById('self_symptoms').value = self.symptoms;
                      document.getElementById('self_treatment_start_date').value = self.treatment_start_date;
                      document.getElementById('self_treatment_end_date').value = self.treatment_end_date;
                      document.getElementById('self_current_status_of_the_medical_problem').value = self.current_status_of_the_medical_problem;
                  }
      
                  if(data.spouse){
                      const spouse = data.spouse;
                      document.getElementById('spouse_member_id').value = spouse.member_id;
                      document.getElementById('spouse_name_of_the_medical_problem').value = spouse.name_of_the_medical_problem;
                      document.getElementById('spouse_area_of_the_body_affected').value = spouse.area_of_the_body_affected;
                      document.getElementById('spouse_symptoms').value = spouse.symptoms;
                      document.getElementById('spouse_treatment_start_date').value = spouse.treatment_start_date;
                      document.getElementById('spouse_treatment_end_date').value = spouse.treatment_end_date;
                      document.getElementById('spouse_current_status_of_the_medical_problem').value = spouse.current_status_of_the_medical_problem;
                      
                     
                  } */


            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        }).catch(err => {
            console.log(err.response.data);
            //   const resp = err.response.data;
            //  context.checkTokenStatus(resp);
        });
    }

    useEffect(() => {
        get_additional_health_details();
    }, [])


    const current_status = [
        "Ongoing", "Completed", "Recovery", "Recurrent or likely to recur"
    ]; 
    return (
        <>
            <Form id="form_additional_health_details" onSubmit={handleSubmit(onSubmitForm)}>
                <FormHeaderSection title="Additional Health Details"
                    sub_title="Please enter any particular details that might be relevant to your health." step={props.step} setStep={props.setStep} />
                <div className="proposer-details form-section">
                    <Row>
                        <Col md={8}>
                            {
                                rows.map((item, index) => { 
                                    let input = "input[" + index + "]";
                                    return (<div className="section-loop">
                                        <input type="hidden" name={input + "[member_id]"} value={item.member_id} className="form-control"></input>
                                        <h5>{item.name}  ({item.relationship}) </h5>
                                        <div className="ml-20"><strong>The name of the illness or medical problem. Where applicable, state the area of the body affected.</strong></div>
                                        <div className="form-group">
                                            <label for={input + "[name_of_the_medical_problem]"}>Name of the medical problem</label>
                                            <input type="text" name={input + "[name_of_the_medical_problem]"} className="form-control" defaultValue={item.name_of_the_medical_problem}></input>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Area of the body affected</label>
                                            <input type="text" className="form-control" name={input + "[area_of_the_body_affected]"} defaultValue={item.area_of_the_body_affected}></input>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <strong>When did the symptoms occur and when did you last have symptoms?</strong><br />
                                            <label>Symptoms</label>
                                            <textarea name={input + "[symptoms]"} type="text" className="form-control" defaultValue={item.symptoms}></textarea>
                                        </div>

                                        <div className="ml-20"><strong>What treatment was provided? (include details ofo medication and dates of when createment started and endded?</strong><br /></div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Treatment start date</label>
                                                <input type="date" name={input + "[treatment_start_date]"} defaultValue={item.treatment_start_date} className="form-control"></input>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Treatment end date</label>
                                                <input type="date" name={input + "[treatment_end_date]"} defaultValue={item.treatment_start_date} className="form-control" ></input>
                                            </div>
                                        </div>

                                        <div className="ml-20"><strong>What is the current status of the illness or medical problem?</strong></div>
                                        <div className="form-group col-md-8">
                                            <label>Current status of the medical problem</label>
                                            <select className="form-control" name={input + "[current_status_of_the_medical_problem]"} defaultValue={item.current_status_of_the_medical_problem}>
                                                <option value="">-- Select --</option>
                                                {
                                                    current_status.map((val) => {
                                                        return <option value={val}>{val}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <hr />
                                    </div>)

                                })
                            }

                        </Col>
                        <Col md={4}>
                            <Card className="guidelines-section">
                                <h4><img src={img.notebook.src} alt=""></img> Guidelines</h4>
                                <small>Aadhaar card number is a 16-digit number on your aadhaar card</small>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default AdditionalHealthDetails;