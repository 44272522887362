import { Card, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import http from "../http";

import img from "../components/Img";
import './Register.css'

import { useForm } from "react-hook-form";
import { useState, useContext, useEffect } from "react";
import NoteContext from "../context/notes/NoteContext";
import SweetAlert from 'sweetalert2'


function Login(props) {
    document.title = "Login"
    const context = useContext(NoteContext);

    context.SetLogin(false);


    useEffect(() => {
        if (localStorage.getItem('token')) {
            // alert("logined");
            window.location.href = "/";
        }
    }, [])

    const { register, handleSubmit, setError, formState: { errors } } = useForm(); // for register form

    const onSubmitLogin = data => {

        const payload = data;
        http.post('/login', payload).then((response) => {
            console.log(response.data);
            const resp = response.data;
            if (resp.success) {
                const token = resp.data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("name", resp.data.user.name);
                localStorage.setItem("email", resp.data.user.email);
                localStorage.setItem("mobile", resp.data.user.mobile);
                set_login_card('hide');
                set_success_card('show'); 
                setTimeout(() => {
                    window.location.href = "/client-requirements";
                }, 1000)

            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });


    }



    const [login_card, set_login_card] = useState('show');
    // set_register_card('show'); 
    const [success_card, set_success_card] = useState('hide');





    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    return (
        <>
            <Row className="register-section container">
                <div className="col-md-6 register-section-left">
                    <h1>Your Personalized Global Health Insurance Partner</h1>
                    <p>Whether you're traveling abroad or residing in a new country, we've got you covered with our comprehensive global health insurance plans. </p>
                    <Row className="text-center family-image">
                        <img src={img.family_insured.src} alt={img.family_insured.alt}></img>
                    </Row>

                </div>
                <div className="col-md-6 register-section-right">
                    <div className="col-md-12">
                        <Card className={login_card}>
                            <span>Find the best healthcare</span>
                            <h2>Login to get started</h2>

                            <Form id="register_form" onSubmit={handleSubmit(onSubmitLogin)} className="register-form">
                                <Row className="mb-8 mb-20">

                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="abc@gmail.com"
                                            name="email"
                                            className={errors.email ? 'error' : ''}
                                            {...register("email", {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "invalid email address"
                                                }
                                            })}

                                        />
                                        <span class={errors.email ? 'required' : ''}>
                                            {errors.email && errors.email.message}
                                        </span>
                                    </Form.Group>
                                    { /*
                                    <Form.Group as={Col} md="12" controlId="validationCustom05">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            className={errors.password ? 'error' : ''}
                                            {...register("password", { required: 'Password is required' })}
                                        />
                                        <span class={errors.password ? 'required' : ''}>
                                            {errors.password && errors.password.message}
                                        </span>
                                    </Form.Group> */}


                                    <div className="col-md-12 mb-20">
                                        <label>Password</label>
                                        <div className="input-group">
                                            <input type={passwordType}
                                                onChange={handlePasswordChange}
                                                defaultValue={passwordInput}
                                                name="password"
                                                placeholder="Password"
                                                className={"form-control " + (errors.password ? ' error' : '')}

                                                {...register("password", { required: 'Password is required' })}
                                            />

                                            <Link
                                                style={{ "margin-top": "20px", "height": "48px", "border": "1px solid #ccc" }}
                                                className="btn btn-default" onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </Link>
                                        </div>
                                    </div>
                                    <p>
                                        <span class={errors.password ? 'required' : ''}> {errors.password && errors.password.message} </span>
                                    </p>
                                    <br />

                                    <p><Link to={'/register'}>I don’t have an account (Signup)</Link></p>
                                    <p><Link to={'/forgot-password'}>Forgot Password ?</Link></p>

                                </Row>
                                <input type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
                            </Form>
                        </Card>
                        <Card className={success_card}>
                            <Col className="text-center">
                                <img className="fa_7x" src={img.success_fill.src} alt={img.success_fill.alt}></img>
                            </Col>
                            <Col className="mt-20 text-center mb-5">
                                <h2>Login Successfully</h2>
                                <span>Desicrption according to the user journey </span>
                            </Col>
                            <div className="center">
                                <img src={img.loading.src} alt={img.loading.alt} style={{width:'200px'}}></img>
                            </div>
                            {/*<a href="/client-requirements" className="full-width btn btn-success btn-lg" variant="primary" size="lg">Loading...</a> */}
                        </Card>
                    </div>
                </div>
            </Row>
        </>
    );
}


export default Login;