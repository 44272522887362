import { useState, useEffect } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../components/Img";
import GetQuote from "../../components/GetQuote";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Accordion from 'react-bootstrap/Accordion';

import { _number_format, _number_format_with_icon, get, deductible_option_list } from '../../funcations';
import http from './../../http';
import SweetAlert from 'sweetalert2'

import GetQuotesList from './GetQuotesList';
import CallBackComponents from '../../components/widgets/CallBackComponents';



function PlanDetails() {
    const navidate = useNavigate();

    const [getPackage, setPackagesList] = useState([]);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            http.get('/masters-data/get-packages?package_id=' + get('id'), [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setPackagesList(resp.data);
                } else {
                    SweetAlert.fire(
                        'Error!',
                        "Invalid Package Id",
                        'error'
                    );
                }
            }).catch(err => {
                //  const resp = err.response.data;
                // checkTokenStatus(resp);
            });
        }else{
            navidate('/login')
        }
    }, []);


    const [pakages_for_opd, setPakages] = useState([]);
    useEffect(() => {
        http.get('/masters-data/get-packages', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                setPakages(resp.data);
                /*resp.data.map((row, index) => {
                   if(row.id === get('id')) {
                    //setPackagesList(row);
                   }
                }); */
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                );
            }
        });

    }, [])


    //console.log("packageLists :::::------>",getPackage );

    const getProposerData = () => {
        if (localStorage.getItem("token")) {
            http.post('/proposer-details', [],
                { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
            ).then((response) => {
                const resp = response.data;
                if (resp.success) {
                    setProposerDetails(resp.data);
                    setSummary(resp.data.summary);
                    setDeductibleOption(resp.data.summary.deductible_option);
                    calSummary();
                }
            });
        }
    }


    const [proposer, setProposerDetails] = useState({});
    const [summary, setSummary] = useState({});
    const [deductibleOption, setDeductibleOption] = useState({});

    useEffect(() => {
        getProposerData();
    }, []);

    let ages = (proposer.members === undefined ? [] : proposer.members);
    let ages_html = [];

    ages.map((item, index) => {
        ages_html.push(
            <>
                <span>{item.relationship}-{item.age} years, </span>
            </>
        );
    });


    const [key, setKey] = useState('About');

    const questions_obj = [
        {
            id: 1,
            ques: 'How can I buy the plan ?',
            ans: 'Ans  '
        },
        {
            id: 2,
            ques: 'How can I connect to Insurance Agent again ?',
            ans: 'ans...'
        },
        {
            id: 3,
            ques: 'How can I connect to Insurance Agent again ?',
            ans: 'ans...'
        },
        {
            id: 4,
            ques: 'Is good credit score necessary for this insurance ?',
            ans: 'ans...'
        }


    ];

    const package_img = (pakage_name) => {
        if (pakage_name === 'Bronze') {
            return img.warning_yellow.src;
        }
        if (pakage_name === 'Silver') {
            return img.warning_gray.src;
        }
        if (pakage_name === 'Gold') {
            return img.warning_red.src;
        }
    }


    const updateClientData = () => {

        let serialize = require('form-serialize');
        let form = document.querySelector('#client_form');
        let form_data = serialize(form, { hash: true });

        http.post('/client-other-details/add', form_data,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                getProposerData();
                calSummary();
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                )
            }
        });

    }

    const [gTotal_amounts, setGTotalAmounts] = useState(0.00);
    const [deductible_amount, setdeDuctibleAmounts] = useState(0.00);
    const [deductible_percentage, setdeDuctiblePercentage] = useState(0.00);
    const [family_discount_amounts, setFamilyDiscountAmounts] = useState(0.00);

    const calSummary = () => {
        let total = parseInt(summary.base_premium) + parseInt(summary.opd_premium_amounts)
        let deductible_amounts = 0;
        if (deductibleOption !== null) {
            setdeDuctiblePercentage(deductibleOption.percentage);
            deductible_amounts = 0 - (total * deductibleOption.percentage) / 100;
        }

        setdeDuctibleAmounts(deductible_amounts);

        let family_discount_amounts = 0 - ((total + deductible_amounts) * parseFloat(summary.family_discount)) / 100;
        setFamilyDiscountAmounts(family_discount_amounts);

        setGTotalAmounts(total + deductible_amounts + family_discount_amounts);
    }



    useEffect(() => {
        calSummary();
    }, [{ calSummary }]);

    const [display_flag_x, setDisplayFlagX] = useState(true);


    const [get_quote_button, setGetQuoteButton] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('my_get_quote_list').includes(get('id'))) {
            // setGetQuoteButton(true);
        } else {
            setGetQuoteButton(false);
        }

    }, []);

    return (
        <>

            <Row>
                <div className="back mb-20">
                    <Link onClick={() => navidate(-1)}> <img src={img.arrow_left.src} alt=""></img> Back</Link>
                </div>
                <Col md={8}>

                    <Card className="pakage-card">
                        <Row className="pakage-header">
                            <Col md={8}>
                                <img src={package_img(getPackage.name)} alt="" className="flot-left mr-20"></img>
                                <h4>{getPackage.name}</h4>
                                <span>Health insurance</span>
                            </Col>
                            <Col md={4}>
                                <h4>Rs. {_number_format(gTotal_amounts, display_flag_x)}</h4>
                                <span>Per Annum</span>
                            </Col>
                        </Row>

                        <Row className="p-3">
                            <Col md={12} className="font14 fw500">
                                <form id="client_form" name="client_form">
                                    <Row>
                                        <Col className='col-md-4'> <img src={img.dollar.src} alt=""></img> Cover Amounts</Col>
                                        <Col className="left">USD {_number_format(getPackage.amounts_ipd)}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={4} className=""> <img src={img.users.src} alt=""></img> Policy For</Col>
                                        <Col md={8} className="">{ages_html}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mb-12">
                                        <Col md={4} className=""> <img src={img.web_sm.src} alt=""></img>  Covergae Area</Col>
                                        <Col md={8} className="">{proposer.coverage_area}</Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col> <img src={img.stethoscope.src} alt=""></img>  Includes Dental and Vision </Col>
                                        <Col>
                                            <select className="btn btn-link border" id="vision_and_dental_covered" name="vision_and_dental_covered"
                                                onChange={updateClientData}>
                                                <option value="no" selected={proposer.vision_and_dental_covered === 'no' ? 'selected' : ''}>No</option>
                                                <option value="yes" selected={proposer.vision_and_dental_covered === 'yes' ? 'selected' : ''}>Yes</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col> <img src={img.rupee.src} alt=""></img>  OPD Covergae</Col>
                                        <Col>
                                            <select className="btn btn-link border" name="opd_covered_amount" id="opd_covered_amount" onChange={updateClientData}>
                                                <option value="">-- Select --</option>
                                                {
                                                    pakages_for_opd.map((row, index) => {
                                                        return (<option selected={proposer.opd_covered_amount === row.amounts_opd ? 'selected' : ''}
                                                            value={row.amounts_opd}>$ {_number_format(row.amounts_opd)}</option>)
                                                    })
                                                }
                                                <option value="0">$ 0.00</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col> <img src={img.rupee.src} alt=""></img>  Deductible</Col>
                                        <Col>
                                            <select className="btn btn-link border" id="deductible" name="deductible" defaultValue={proposer.deductible} onChange={updateClientData}>
                                                <option value="">--Select --</option>
                                                {
                                                    deductible_option_list.map((val) => {
                                                        return (<option selected={parseFloat(proposer.deductible) === parseFloat(val) ? 'selected' : ''}
                                                            value={val}>$ {_number_format(val,)}</option>)
                                                    })
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                    <hr />
                                </form>
                            </Col>

                            <Col md={12} className="nowrap__ right">
                                <Link to={"/client-requirements?action=edit"} className="btn btn-link border mr-20 mb-10">
                                    <img src={img.edit.src} alt="" ></img> Edit Requirement</Link>
                                <select className="btn btn-link border">
                                    <option value="">-- Select --</option>
                                    <option value={1}>Download Documents</option>
                                    <option value={2}>Download Documents 2</option>
                                    <option value={3}>Download Documents 3</option>
                                </select>

                            </Col>
                        </Row>
                    </Card>

                    <div className="mt-20 key-benefits">
                        <h5> <img src={img.cil_badge.src} alt=""></img> Key Benefits</h5>
                        <Row>
                            <div className="col-md-4 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.rupee.src} alt=""></img> <strong>Covers all In-Patient care</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>
                            <div className="col-md-4 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.stethoscope.src} alt=""></img>  <strong>Out-Patient Treatment (OPD) </strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date</div>
                                </Card>
                            </div>
                            <div className="col-md-4 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.person.src} alt=""></img>  <strong>Covered on Individual basis</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>
                            <div className="col-md-4 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.web_sm.src} alt=""></img>  <strong>International Emergency Services</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>
                            <div className="col-md-4 box">
                                <Card>
                                    <div> <img className="w30 flote" src={img.person2.src} alt=""></img>  <strong>Rehab & wellness</strong></div>
                                    <div className="text">Covered after an initial waiting period of 30 days from the policy start date.</div>
                                </Card>
                            </div>

                        </Row>
                    </div>
                    <div className='mt-30 mb-20'>
                        <h3><img src={img.info_green.src} alt=""></img> More Information</h3>
                    </div>
                    <Card className='mt-20 p-20'>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="About" title="About">
                                <h5>About</h5>
                                <p>Manipal in association with Finnovate is provide international health insurance which aims to provide you the best health insurance which is eligible to avail medical services worldwide. This insurance will enable you :</p>
                                <ul className='bullet-point-1'>
                                    <li>To get immediate hospitalization</li>
                                    <li>To avail air ambulance services</li>
                                    <li>To claim OPD expenses</li>
                                    <li>To claim dental expenses</li>
                                </ul>
                            </Tab>
                            <Tab eventKey="Coverage" title="Coverage">
                                <h5>Coverage</h5>
                            </Tab>
                            <Tab eventKey="Inclusions" title="Inclusions">
                                <h5>Inclusions</h5>
                            </Tab>
                            <Tab eventKey="Exclusions" title="Exclusions">
                                <h5>Exclusions</h5>
                            </Tab>
                            <Tab eventKey="ClaimProcess" title="Claim Process">
                                <h5>Claim Process</h5>
                            </Tab>

                        </Tabs>
                    </Card>

                    <div className='mt-30 mb-20'>
                        <h3><img src={img.question_green.src} alt=""></img> Commonly Asked Questions</h3>
                    </div>
                    <Card className='mt-20'>
                        <Accordion defaultActiveKey="0">

                            {
                                questions_obj.map((row) => {
                                    return (
                                        <Accordion.Item eventKey={row.id}>
                                            <Accordion.Header>Q.  {row.ques}</Accordion.Header>
                                            <Accordion.Body>
                                                {row.ans}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }

                        </Accordion>
                    </Card>


                </Col>
                <Col md={4} className="pakage-summary-form">
                    <Card >
                        Summary
                        <hr />
                        <Row>
                            <Col className="col-7"><label><small>Base premium</small> </label></Col>
                            <Col className="col-5 right"><label>{_number_format_with_icon(summary.base_premium, display_flag_x)}</label></Col>
                        </Row>
                        <Row>
                            <Col className="col-7"><label> OPD Coverage</label></Col>
                            <Col className="col-5 right"><label>  {_number_format_with_icon(summary.opd_premium_amounts, display_flag_x)} </label></Col>
                        </Row>
                        <Row>
                            <Col className="col-7"><label>Deductible ({deductible_percentage}%)</label></Col>
                            <Col className="col-5 right"><label> {_number_format_with_icon(deductible_amount, display_flag_x)}</label></Col>
                        </Row>
                        <Row className="mt-20">
                            <Col className="col-7"><label><small>Family Discount ({summary.family_discount} %)</small></label></Col>
                            <Col className="col-5 right"><label>  {_number_format_with_icon(family_discount_amounts, display_flag_x)}</label></Col>
                        </Row>
                        <div><hr /></div>
                        <Row className="mt-20 mb-20">
                            <Col className="col-6"><h5>Total</h5></Col>
                            <Col className="col-6 right"> <b>{_number_format_with_icon(gTotal_amounts, display_flag_x)}</b> </Col>
                        </Row>

                        <div>
                            <GetQuote package_id={get('id')} className="btn btn-primary btn-lg full-width">
                                Request Quote Now <img src={img.arrow_right.src} alt=""></img> 
                            </GetQuote>
                        </div>

                    </Card>
                    <br />
                    <GetQuotesList />
                    <CallBackComponents />

                </Col>

            </Row>
        </>
    )
}

export default PlanDetails;