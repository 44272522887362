import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'

import Home from './pages/Home';
import Login from './pages/Login';
import LoginExistingToken from './pages/LoginExistingToken';

import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import img from './components/Img';
import ClientRequirements from './pages/client-requirements/ClientRequirements';
import PreferedPlan from './pages/PreferedPlan';
import Dashboard from './pages/Dashboard';
import ComparePlans from './pages/prefered-plan/ComparePlans';
import PlanDetails from './pages/prefered-plan/PlanDetails';
import InsuranceApplicationStatus from './pages/InsuranceApplicationStatus';

import EnrollmentForm from './pages/EnrollmentForm';
import GetQuoteDetails from './pages/prefered-plan/GetQuoteDetails';
import Page404 from './pages/Page404';

function RouterPage(props) {
    return (

        <Routes>
            <Route path="/login" element={<Login />}>Login</Route>
            <Route path="/login-existing-token" element={<LoginExistingToken />}>Login1</Route>
            <Route path="/login-success" element={<Login />}>Login</Route>
            <Route path="/register" element={<Register />}>Register</Route>
            <Route path="/forgot-password" element={<ForgotPassword />}>Register</Route>
            <Route path="/reset-password" element={<ResetPassword />}>Register</Route>
            <Route path="/" element={<Home img={img} scollToExplorePlan={props.scollToExplorePlan} />}>Home</Route>
            <Route path="/client-requirements" element={<ClientRequirements />}>Client Requirements</Route>
            <Route path="/prefered-plan" element={<PreferedPlan />}>Prefered Plan</Route>
            <Route path="/dashboard" element={<Dashboard />}>Dashboard</Route>
            <Route path="/compare-plans" element={<ComparePlans />}>Compare Plans</Route>
            <Route path="/plan-details" element={<PlanDetails />}>Compare Plans</Route>
            <Route path="/insurance-application-status" element={<InsuranceApplicationStatus />}>Compare Plans</Route>
            <Route path="/enrollment-form" element={<EnrollmentForm />}>Enrollment Form</Route>
            <Route path="/get-quote-details" element={<GetQuoteDetails />}>Plan Get Quote Details</Route>
            <Route path="/*" element={<Page404 />}>404 Page</Route>
        </Routes>

    );
}

export default RouterPage;


