
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import http from "../../../http";
import SweetAlert from 'sweetalert2'

function SumInsured(props) {

  console.log(props.formData4);

  props.setShortTitle('Select the cover amount');
  props.setLongTitle('We have got you covered in 3 ranges ');

  const [coverAmount, setCoverAmount] = useState(props.covered_ranges);


  const [pakages, setPakages] = useState([]);
  useEffect(() => {
    http.get('/masters-data/get-packages', [],
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        setPakages(resp.data);
      } else {
        SweetAlert.fire(
          'Error!',
          resp.message,
          'error'
        );
      }
    });

  }, [])



  const handleSubmit = (event) => {
    event.preventDefault();


    let serialize = require('form-serialize');
    let form = document.querySelector('#my_form');
    let form_data = serialize(form, { hash: true });

    http.post('/client-other-details/add', form_data,
      { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
    ).then((response) => {
      const resp = response.data;
      if (resp.success) {
        props.setFormData4(
          { ...form_data }
        );
        props.setStep(5);
      } else {
        alert(resp.message);
      }


    });
  }
  return (
    <>
      <form onSubmit={handleSubmit} id="my_form">
        <Row className="coverage-area-radio-buttons">
          {
            pakages.map((item, index) => {
              return (

                <Col className="col-md-12">
                  <label className={(coverAmount === item.id ? 'active' : '')}>
                    <input type="radio" name="covered_ranges" value={item.id} onClick={() => { setCoverAmount(item.id) }} />
                     $ { item.amounts_ipd + ' - ' + item.name } 
                  </label>
                </Col>

              )
            })
          }
{/*
          <Col className="col-md-12">
            <label className={(coverAmount === 250000 ? 'active' : '')}>
              <input type="radio" name="covered_ranges" value="250000" onClick={() => { setCoverAmount(250000) }} />
              $ 250,000 - Bronze
            </label>
          </Col>

          <Col className="col-md-12">
            <label className={(coverAmount === 500000 ? 'active' : '')}>
              <input type="radio" name="covered_ranges" value="500000" onClick={() => { setCoverAmount(500000) }} />
              $ 500,000 - Silver
            </label>
          </Col>

          <Col className="col-md-12">
            <label className={(coverAmount === 1000000 ? 'active' : '')}>
              <input type="radio" name="covered_ranges" value="1000000" onClick={() => { setCoverAmount(1000000) }} />
              $ 1,000,000 - Gold
            </label>
          </Col>
*/ }
        </Row>

        <Row className="continue-btn-section">
          <Col className="col-md-4">
            <input
              onClick={() => props.setStep(3)}
              type="button" className="full-width btn btn-outline-primary btn-lg" value="Back" />
          </Col>
          <Col className="col-md-8">
            <input
              type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
          </Col>
        </Row>
      </form>
    </>
  );
}

export default SumInsured;

