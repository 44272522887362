import { Card, Col, Row, Form} from "react-bootstrap";
import { Link } from "react-router-dom";
import http from "../http";

import img from "../components/Img";
import './Register.css'
 
import { useForm } from "react-hook-form";
import { useState, useContext } from "react";
import NoteContext from "../context/notes/NoteContext";

function ForgotPassword (props) {
    document.title ="Forgot Password"
    const context = useContext(NoteContext);

    context.SetLogin(false);

    const { register, handleSubmit ,setError, formState:{errors} } = useForm(); // for register form
    const[email, setEmail] = useState();
    const onSubmitLogin = data => { 
        const payload = data;
        http.post('/forgot-password', payload).then((response) => { 
            const resp = response.data;
            if (resp.success) { 
                setEmail(data.email);
                set_login_card('hide'); 
                set_success_card('show');
            }else{  
                setError('validation', { message: resp.message }); 
            }
        });
       
        
    }
 


    const [login_card, set_login_card] = useState('show');
   // set_register_card('show'); 
    const [success_card, set_success_card] = useState('hide'); 
   

    

    return (
    <>
        <Row className="register-section">
            <Col className="col-md-6 register-section-left">    
               <h1>We are here with just the right insurance for you.</h1>
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pulvinar pellentesque sed purus adipiscing aenean potenti </p>
               <Row className="text-center family-image">
                    <img src={img.family_insured.src} alt={img.family_insured.alt}></img>
               </Row>
               
            </Col>
            <Col className="col-md-6 register-section-right">                
                <Col className="col-md-11">
                    <Card className={login_card}>
                       
                        <h2 className="center">Forgot Password</h2>  

                            <Form id="register_form"  onSubmit={handleSubmit(onSubmitLogin)} className="register-form">
                                <Row className="mb-8 mb-20">
                                    
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Enter Registered Email</Form.Label>
                                        <Form.Control 
                                            type="email"
                                            placeholder="abc@gmail.com" 
                                            name="email"
                                            className={errors.email ? 'error' : ''}
                                            {...register("email", { required: 'Email is required' } )} 

                                        /> 
                                        <span class={errors.email ? 'required' : ''}>
                                            {errors.email &&  errors.email.message}
                                        </span>
                                    </Form.Group> 


                                <p><Link to={'/register'}>I don’t have an account (Signup)</Link></p> 
                                <p><Link to={'/login'}>Login account ?</Link></p> 
                                <p>
                                <span class={errors.validation ? 'required' : ''}>
                                            {errors.validation &&  errors.validation.message}
                                </span> 
                                </p>
                                </Row>               
                                <input type="submit" className="full-width btn btn-primary btn-lg" value="Continue" />
                            </Form> 
                    </Card>

               

                    <Card className={success_card}>
                        <Col className="text-center">
                            <img className="fa_7x" src={img.success_fill.src} alt={img.success_fill.alt}></img>
                        </Col>
                        <Col className="mt-20 text-center mb-5">
                            <h2>Success</h2> 
                            <span>Reset password link sent on your email id  <a href="#">{email}</a> </span>  
                        </Col>               
                        <a href="/client-requirements" className="full-width btn btn-primary btn-lg" variant="primary" size="lg">Continue</a>
                        
                    </Card>


                </Col>
            </Col>
        </Row>
    </>
    );
}


export default ForgotPassword;