import { Card, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import http from "../http";

import img from "../components/Img";
import './Register.css'

import { useForm } from "react-hook-form";
import { useState, useContext } from "react";
import NoteContext from "../context/notes/NoteContext";

import { get } from './../funcations';

function ForgotPassword(props) {


    document.title = "Forgot Password"
    const context = useContext(NoteContext);

    context.SetLogin(false);

    const { register, handleSubmit, setError, formState: { errors } } = useForm(); // for register form

    const onSubmit = data => {
        
        console.log(data);
        const payload = data;

        http.post('/reset-password', payload).then((response) => {
            console.log(response.data);
            const resp = response.data;
            if (resp.success) {
                set_login_card('hide');
                set_success_card('show');
            } else {
                if (resp.message) {
                    setError('validation', { message: resp.message });
                }

                if (resp.errors.password) {
                    setError('password', { message: resp.errors.password[0] });
                }
                if (resp.errors.password_confirmation) {
                    setError('password_confirmation', { message: resp.errors.password_confirmation[0] });
                }
            }
        });


    }



    const [login_card, set_login_card] = useState('show');
    // set_register_card('show'); 
    const [success_card, set_success_card] = useState('hide');

    return (
        <>
            <Row className="register-section">
                <Col className="col-md-6 register-section-left">
                    <h1>We are here with just the right insurance for you.</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pulvinar pellentesque sed purus adipiscing aenean potenti </p>
                    <Row className="text-center family-image">
                        <img src={img.family_insured.src} alt={img.family_insured.alt}></img>
                    </Row>

                </Col>
                <Col className="col-md-6 register-section-right">
                    <Col className="col-md-11">
                        <Card className={login_card}>
                            <h2 className="center">Reset Password</h2>

                            <Form id="register_form" onSubmit={handleSubmit(onSubmit)} className="register-form">
                                <Row className="mb-8 mb-20">
                                    <input type="hidden" name="token" value={get('token')}
                                        {...register("token", { required: 'token is required' })} />
                                    <input type="hidden" name="email" value={get('email')}
                                        {...register("email", { required: 'email is required' })} />

                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Enter Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            className={errors.password ? 'error' : ''}
                                            {...register("password", { required: 'Password is required' })}

                                        />
                                        <span class={errors.password ? 'required' : ''}>
                                            {errors.password && errors.password.message}
                                        </span>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password_confirmation"
                                            className={errors.email ? 'error' : ''}
                                            {...register("password_confirmation", { required: 'Confirm Password is required' })}

                                        />
                                        <span class={errors.password_confirmation ? 'required' : ''}>
                                            {errors.password_confirmation && errors.password_confirmation.message}
                                        </span>
                                    </Form.Group>



                                    <p><Link to={'/register'}>I don’t have an account (Signup)</Link></p>
                                    <p><Link to={'/login'}>Login account ?</Link></p>
                                    <p>
                                        <span class={errors.validation ? 'required' : ''}>
                                            {errors.validation && errors.validation.message}
                                        </span>
                                    </p>
                                </Row>
                                <input type="submit" className="full-width btn btn-primary btn-lg" value="Reset Password" />
                            </Form>
                        </Card>


                        <Card className={success_card}>
                            <Col className="text-center">
                                <img className="fa_7x" src={img.success_fill.src} alt={img.success_fill.alt}></img>
                            </Col>
                            <Col className="mt-20 text-center mb-5">
                                <h2>Password Reset Successfully</h2>
                            </Col>
                            <a href="/login" className="full-width btn btn-primary btn-lg" variant="primary" size="lg">Continue to Login </a>

                        </Card>


                    </Col>
                </Col>
            </Row>
        </>
    );
}


export default ForgotPassword;