import './EnrollmentForm.css'
import { Card, Col, Row, Nav, Tab, DropdownButton, Dropdown } from "react-bootstrap";
import img from '../components/Img';

import ProposerDetails from './enrollment-form/ProposerDetails';
import InsuredDetails from './enrollment-form/InsuredDetails';
import PlanDetails from './enrollment-form/PlanDetails';
import HealthQuestionnaire from './enrollment-form/HealthQuestionnaire';
import AdditionalHealthDetails from './enrollment-form/AdditionalHealthDetails';
import Nominee from './enrollment-form/Nominee';
import Documents from './enrollment-form/Documents';
import SignAndConfirm from './enrollment-form/SignAndConfirm';
import { useState, useEffect, useContext } from 'react';
import http from '../http';
import noteContext from '../context/notes/NoteContext';
import { Link , useNavigate} from 'react-router-dom';


function EnrollmentForm() {

    const navigate = useNavigate();

    
    const context = useContext(noteContext);
    //let url_arr =  (window.location.href).split("#");
    //let tab = (url_arr.length === 2 ? url_arr[1] : '');
    // help url: https://react-bootstrap.netlify.app/components/tabs/#tab-content-props
    const [step, setStep] = useState('ProposerDetails'); 

    const done = <i class="fa fa-check-circle primary-color fa-2x mr-5" aria-hidden="true" />;



    const [modules, setModules] = useState({
        name: '',
        module_proposer_details: 0,
        module_insured_details: 0,
        module_plan_details: 0,
        module_health_questionnaires: 0,
        module_additional_health_details: 0,
        module_nominee: 0,
        module_documents: 0,
        module_sign_and_confirm: 0
    });


    useEffect(() => {
        check_done_pending_modules(step);
        console.log(step);
        // setStep('InsuredDetails');
    }, [step]);

    // console.log(step); 

    const check_done_pending_modules = (step) => {
        http.post('/enrollment-form/check-done-pending-modules', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                const m = resp.data;
                setModules(m);
            } else {
                // 
            }
        }).catch(err => {
            const resp = err.response.data;
            context.checkTokenStatus(resp);
        });;
    } 


    return (
        <>
            <Row> 
                <Col md={9}><Link onClick={() => navigate(-1)} className="flot-left"><i class="fa fa-arrow-left" aria-hidden="true"></i></Link> <h4> Enrollment Form</h4></Col>
                <Col md={3}>
                    <DropdownButton
                        variant="outline-primary"
                        title="Download Documents"
                        id="input-group-dropdown-4"
                        align="end"
                        className='absolute-right'
                    >
                        <Dropdown.Item href="#">Action</Dropdown.Item>
                        <Dropdown.Item href="#">Another action</Dropdown.Item>
                        <Dropdown.Item href="#">Something else here</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#">Separated link</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>

            <Row className='mt-20'>
                <Card>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={step}
                        activeKey={step}
                        onSelect={(k) => setStep(k)}>
                        <Row>
                            <Col sm={3} className="p-0">
                                <Nav variant="pills" className="flex-column" >

                                    <Nav.Item>
                                        <Nav.Link onClick={() => setStep('ProposerDetails')} eventKey="ProposerDetails">
                                            {(modules.module_proposer_details === 1 ? done : <span className='counter'>1</span>)}
                                            Proposer Details
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('InsuredDetails')} eventKey="InsuredDetails"
                                            disabled={(modules.module_proposer_details === 1 ? false : true)}>
                                            {(modules.module_insured_details === 1 ? done : <span className='counter'>2</span>)}
                                            Insured Details</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('PlanDetails')} eventKey="PlanDetails"
                                            disabled={(modules.module_insured_details === 1 ? false : true)} >
                                            {(modules.module_plan_details === 1 ? done : <span className='counter'>3</span>)}
                                            Plan Details</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('HealthQuestionnaire')} eventKey="HealthQuestionnaire"
                                            disabled={(modules.module_plan_details === 1 ? false : true)}>
                                            {(modules.module_health_questionnaires === 1 ? done : <span className='counter'>4</span>)}
                                            Health Questionnaire</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('AdditionalHealthDetails')} eventKey="AdditionalHealthDetails"
                                            disabled={(modules.module_health_questionnaires === 1 ? false : true)} >
                                            {(modules.module_additional_health_details === 1 ? done : <span className='counter'>5</span>)}
                                            Additional Health Details</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('Nominee')} eventKey="Nominee"
                                            disabled={(modules.module_additional_health_details === 1 ? false : true)}>
                                            {(modules.module_nominee === 1 ? done : <span className='counter'>6</span>)}
                                            Nominee</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('Documents')} eventKey="Documents"
                                            disabled={(modules.module_nominee === 1 ? false : true)} >
                                            {(modules.module_documents === 1 ? done : <span className='counter'>7</span>)}
                                            Documents</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={(modules.module_proposer_details === 1 ? '' : 'disabled')} >
                                        <Nav.Link onClick={() => setStep('SignAndConfirm')} eventKey="SignAndConfirm"
                                            disabled={(modules.module_documents === 1 ? false : true)} >
                                            {(modules.module_sign_and_confirm === 1 ? done : <span className='counter'>8</span>)}
                                            Sign and Confirm</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </Col>
                            <Col sm={9} className={localStorage.getItem('enrollment_form_verified_status') === 'approved' ? "p-0 disabled" : "p-0"}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="ProposerDetails">
                                        {step === 'ProposerDetails' && <ProposerDetails step={step} setStep={setStep} />}

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="InsuredDetails">
                                        {step === 'InsuredDetails' && <InsuredDetails step={step} setStep={setStep} />
                                        }
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="PlanDetails">
                                        {step === 'PlanDetails' && <PlanDetails step={step} setStep={setStep} />}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="HealthQuestionnaire">
                                        {step === 'HealthQuestionnaire' && <HealthQuestionnaire step={step} setStep={setStep} />}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="AdditionalHealthDetails">
                                        {step === 'AdditionalHealthDetails' && <AdditionalHealthDetails step={step} setStep={setStep} />}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="Nominee">
                                        {step === 'Nominee' && <Nominee step={step} setStep={setStep} />}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="Documents">
                                        {step === 'Documents' && <Documents step={step} setStep={setStep}  />}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="SignAndConfirm">
                                        {step === 'SignAndConfirm' && <SignAndConfirm step={step} setStep={setStep} />}
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Card>
            </Row>
        </>
    )

}

export default EnrollmentForm;