import { useState, useEffect } from "react";
import PricingCard from "../widgets/PricingCard";
import img from "../Img";
import http from './../../http'

function ExplorePlan(props) {



    const [getPackages, setpackages] = useState([]);


    useEffect(() => {
        getPackagesRequest();
    }, []);

    const getPackagesRequest = () => {
        const payload = {
            order_by: ['name', 'DESC']
        }
        http.post('/masters-data/get-package-list', payload,
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            const resp = response.data;
            if (resp.success) {
                setpackages(resp.data);
            } else {
                alert(resp.message);
            }
        });
    }

    return (
        <div className="section scheme-offer mt-50">
            <div className="title ">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src={img.search.src} alt={img.search.alt}></img>
                        <h1 className="title">Explore Plans</h1>
                        <p className='text-center sub-title'>Search and Explore health insurances with international coverage,
                            <br />also see it according to your needs and location preferences</p>
                        <a href="/#" className="btn btn-primary btn-lg">Premium Calculator <img src={img.arrow_right.src} alt=""></img></a>
                    </div>
                </div>
            </div>
            <div className="row">
               

                {
                    getPackages.map((item, index) => {
                        return (<div className="col-md-4">
                            <PricingCard
                                package={item}
                                name={item.name}
                                interval="PER MONTH"
                                save="Save 50%"
                                recommended={item.recommended}
                                package_points={item.package_points}
                            />
                        </div>)
                    })

                }
                {/*
                <div className="col-md-4">
                    <PricingCard
                        icon={img.warning_red}
                        name="Silver Package"
                        interval="PER MONTH"
                        save="Save 50%"
                        recommended={true}
                        className="active"
                        bullet_style_image={img.correct_red}
                    />
                </div>
                <div className="col-md-4">
                    <PricingCard
                        icon={img.warning_yellow}
                        name="Silver Package"
                        interval="PER MONTH"
                        save="Save 50%"
                        recommended={false}
                        bullet_style_image={img.correct_yellow}
                    />
                </div> */}
            </div>
        </div>
    );
}
export default ExplorePlan;

