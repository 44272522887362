import { useEffect, useState } from "react";
import FormHeaderSection from "./FormHeaderSection.jsx";
import { Row, Col, Card, Form } from "react-bootstrap";
import img from "../../components/Img.jsx";


import SweetAlert from 'sweetalert2'
import http from '../../http.jsx'

function HealthQuestionnaire(props) {

    //const { register, handleSubmit, formState: { errors } } = useForm();
    //  console.log(errors);
    /* 
      let questions = [
          {
              id: 1,
              question: 'Diabetes and other endocrine (glandular) disorders.',
          },
          {
              id: 2,
              question: 'Heart or circulatory disorders ',
          },
          {
              id: 3,
              question: 'cancer, tumors or growths ',
          },
          {
              id: 4,
              question: 'Muscle or skeletal problems ',
          },
          {
              id: 5,
              question: 'Gall bladder, stomach, Intestinal, gastric or liver ',
          },
          {
              id: 6,
              question: 'Brain or neurological disorders',
          },
          {
              id: 7,
              question: 'Skin problems',
          },
          {
              id: 8,
              question: 'Blood, infective or immune disorder',
          },
          {
              id: 9,
              question: 'Urinary or repoductive disorder',
          },
          {
              id: 10,
              question: 'Anxiety, depression, psychiatric or mental health',
          },
          {
              id: 11,
              question: 'Ear, nose, throat, eye or dental problems',
          },
  
      ]
  */


    const [rows, setRows] = useState([]);


    useEffect(() => {

        http.post('/enrollment-form/get-health-questionnaires', [],
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response) => {
            //alert("......");
            console.log('||');
            const resp = response.data;
            if (resp.success) {
                setRows(resp.data);
            } else {
                alert(resp.message);
            }
        });

    }, []);




    const handleFormSubmit = (event) => {
        event.preventDefault();

        let serialize = require('form-serialize');
        let form = document.querySelector('#form_health_questionners');
        let form_data = serialize(form, { hash: true });
        form_data = Object.values(form_data);
        let obj = Object.values(form_data[0]);
        let payload = [];
        obj.map((k,v)=>{
          //  console.log("kkkk: ", k , " vvvvvvv : ", v);
           payload.push(k);

        })
       
        console.log("form_health_questionners >> ", payload);


        http.post('/enrollment-form/save-health-questionnaires', {data:payload},
            { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } }
        ).then((response2) => {
            const resp = response2.data;
            if (resp.success) {
                SweetAlert.fire(
                    'Success',
                    resp.message,
                    'success'
                ); 
                props.setStep('AdditionalHealthDetails');
            } else {
                SweetAlert.fire(
                    'Error!',
                    resp.message,
                    'error'
                ); 
              
            }
        });
    }



    return (
        <>

            <Form id="form_health_questionners" onSubmit={handleFormSubmit}  >
                <FormHeaderSection title="Health Questionnaires" sub_title="Fill out the basic details for the people" step={props.step} setStep={props.setStep} />
                <div className="proposer-details form-section">
                    <Row>
                        <Col md={8}>

                            {

                                rows.map((item, index) => {

                                    var family_members_rows = item.family_members.sort((a, b) => a.member_id - b.member_id);

                                    let members = new Array();
                                    family_members_rows.map((member) => {
                                        let input_index = item.question_id + "_" + member.member_id;
                                        let answer = "data[" + input_index + "][answer]";
                                        let member_id = "data[" + input_index + "][member_id]";
                                        let question_name = "data[" + input_index + "][question_name]";
                                        let question_id = "data[" + input_index + "][question_id]";
                                        let answer_old = member.answer;

                                        members.push(
                                            <Col md={3} className="mb-15">
                                                <input type="hidden" name={question_name} value={item.question_name} ></input>
                                                <input type="hidden" name={question_id} value={item.question_id} ></input>
                                                <input type="hidden" name={member_id} value={member.member_id} ></input>
                                                <label title={member.relationship} > {member.name}</label>
                                                <div className="radio_button">
                                                    <label><input type="radio" name={answer} value="yes"
                                                     defaultChecked={ answer_old === 'yes' ? "checked" : "" } />Yes </label>
                                                    <label><input type="radio" name={answer} value="no"
                                                    defaultChecked={ answer_old === 'no' ? "checked" : "" }  />No </label>
                                                </div>
                                            </Col>
                                        )
                                    })

                                    return (
                                        <>
                                            <Row>
                                                <Col md={12} className="mt-30 mb-1">
                                                    <strong>{ item.question_id +". "+ item.question_name }</strong>
                                                </Col>

                                                {members}

                                            </Row>
                                        </>)
                                })
                            }


                            { /*
                                questions.map((item) => {

                                    let id = "data[" + item.id + "][id]";
                                    let question = "data[" + item.id + "][question]";
                                    let myself = "data[" + item.id + "][myself]";
                                    let wife = "data[" + item.id + "][wife]";
                                    let son = "data[" + item.id + "][son]";
                                    let daughter = "data[" + item.id + "][daughter]";


                                    return (
                                        <>
                                            <Row>
                                                <input type="hidden" value={item.id} name={id}
                                                    {...register(id, { required: true })} ></input>

                                                <input type="hidden" value={item.question} name={question}
                                                    {...register(question, { required: true })} ></input>


                                                <Col md={12} className="mt-30 mb-1">
                                                    <strong>{item.question}</strong>
                                                </Col>
                                                <Col md={3}>
                                                    <label>Myself</label>
                                                    <div className="radio_button">
                                                        <label><input type="radio" name={myself} value="yes"
                                                            {...register(myself, { required: false })}
                                                        />Yes </label>
                                                        <label><input type="radio" checked name={myself} value="no"
                                                            {...register(myself, { required: false })}
                                                        />No </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <label>Wife</label>
                                                    <div className="radio_button">
                                                        <label><input type="radio" name={wife} value="yes"
                                                            {...register(wife, { required: false })}
                                                        />Yes </label>
                                                        <label><input type="radio" checked name={wife} value="no"
                                                            {...register(wife, { required: false })}
                                                        />No </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <label>Son</label>
                                                    <div className="radio_button">
                                                        <label><input type="radio" name={son} value="yes"
                                                            {...register(son, { required: false })} />Yes </label>
                                                        <label><input type="radio" checked name={son} value="no"
                                                            {...register(son, { required: false })} />No </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <label>Daughter</label>
                                                    <div className="radio_button">
                                                        <label><input type="radio" name={daughter} value="yes"
                                                            {...register(daughter, { required: false })} />Yes </label>
                                                        <label><input type="radio" checked name={daughter} value="no"
                                                            {...register(daughter, { required: false })} />No </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>)
                                }) */
                            }

                        </Col>
                        <Col md={4}>
                            <Card className="guidelines-section">
                                <h4><img src={img.notebook.src} alt=""></img> Guidelines</h4>
                                <small>Aadhaar card number is a 16-digit number on your aadhaar card</small>
                            </Card>
                        </Col>
                    </Row>
                </div> 
            </Form>
        </>
    )
}


export default HealthQuestionnaire;