//import { useEffect, useState } from "react";
import img from "./components/Img"
import http from './http';

export const genders = () => {
    return {
        "male": "Male",
        "female": "Femail"
    }
}

const _packages = () => {
    return [
        {
            'id': 1,
            'name': 'Bronze',
            'price': '10000',
        },
        {
            'id': 2,
            'name': 'Silver',
            'price': '10000',
        },
        {
            'id': 3,
            'name': 'Gold',
            'price': '10000',
        }
    ]
}

export const get = (key) => {
    const url = new URL(window.location.href);
    const val = url.searchParams.get(key);
    return (val ? val : '');
}

export const packages = _packages();


export const packages_list =
    [
        {
            id: 1,
            package_name: 'Bronze Package',
            recommended: true,
            price: 1000000,
            icon: img.warning_yellow.src,
            base_premium: 83588,
            dental_vision: 990,
            opd_overage: 35050,
            deductible: 15000,
            family_discount: 5,
            package_features: [
                'AIDs/HIV covered',
                'Health Appliances cover',
                'Hospice and Palliative care',
                'International Wellness Cover (Optional)',
                'Complementary Treatments covered ',
                'Daily hospital cash',
                'Vision and dental cover Deductible for discount benefit',
            ]
        },
        {
            id: 2,
            package_name: 'Silver Package',
            recommended: false,
            price: 2000000,
            icon: img.warning_gray.src,
            base_premium: 83588,
            dental_vision: 990,
            opd_overage: 35050,
            deductible: 15000,
            family_discount: 5,
            package_features: [
                'AIDs/HIV covered',
                'Health Appliances cover',
                'Hospice and Palliative care ',
                'International Wellness Cover (Optional)',
                'Complementary Treatments covered ',
                'Daily hospital cash ',
                'Vision and dental cover Deductible for discount benefit',
            ]


        },
        {
            id: 3,
            package_name: 'Gold Package',
            recommended: false,
            price: 5000000,
            icon: img.warning_red.src,
            base_premium: 83588,
            dental_vision: 990,
            opd_overage: 35050,
            deductible: 15000,
            family_discount: 5,
            package_features: [
                'AIDs/HIV covered',
                'Health Appliances cover',
                'Hospice and Palliative care ',
                'International Wellness Cover (Optional)',
                'Complementary Treatments covered ',
                'Daily hospital cash ',
                'Vision and dental cover Deductible for discount benefit',
            ]


        }
    ];


function search(rows) {
    return Object.keys(this).every((key) => rows[key] === this[key]);
}

export const packages_list_filter = (query) => {
    return packages_list.filter(search, query)[0];
}


export const _number_format = (param, x = false) => {
    if (x === true) {
        return "XXXXX.XX";
    }

    if (!param) {
        return 0;
    }

    const number = parseFloat(param);
    return number.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        // style: 'currency'
        currency: 'INR'
    });
}




export const _number_format_with_icon = (param, x = false) => {
    if (x === true) {
        return "XXXXX.XX";
    }
    if (!param) {
        return 0;
    }

    const number = parseFloat(param);

    const amounts = number.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        //  style: 'currency',
        currency: 'INR'
    });

    return <><i class="fa fa-inr" aria-hidden="true"></i>  {amounts} </>;

}


export const _number_format_usd = (param, x = false) => {
    if (x === true) {
        return "XXXXX.XX";
    }

    if (!param) {
        return 0;
    }

    const number = parseFloat(param);
    return number.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        // style: 'currency'
        currency: 'USD'
    });
}


export const _number_format_usd_with_icon = (param, x = false) => {
    if (x === true) {
        return "XXXXX.XX";
    }
    if (!param) {
        return 0;
    }

    const number = parseFloat(param);

    const amounts = number.toLocaleString('en-US', {
        maximumFractionDigits: 3,
        //  style: 'currency',
        currency: 'USD'
    });

    return <><i class="fa fa-inr" aria-hidden="true"></i>  {amounts} </>;

}



export const deductible_option_list = [5000.00, 10000.00, 15000.00, 0.00];

export const package_icon = (package_name) => {

    if (package_name === 'Bronze') {
        return img.bronze.src;
    }
    if (package_name === 'Silver') {
        return img.silver.src;
    }
    if (package_name === 'Gold') {
        return img.gold.src;
    }
}




export const uc_first = (str) => {
    let str2 = "";
    if (str) {
        str2 = str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str2;
}

export const uc_word = (str) => {

    //split the above string into an array of strings 
    //whenever a blank space is encountered
    const arr = str.split(" ");
    //loop through each element of the array and capitalize the first letter.
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");
    return str2;

}